import { ListItem } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import ArrowRightIcon from "images/icons/ri/chevron-right.svg?react";
import { ComponentProps, FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  canDelete?: boolean | undefined;
  children?: never;
  signedIn?: boolean | undefined;
  version: string;
}

const SettingsScreen: FunctionComponent<Props> = ({ canDelete = false, navLeft, signedIn = false, version }) => {
  const { t } = useTranslation();

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  useEffect(() => {
    trackPageView(PAGE_VIEWED.SETTINGS_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      navLeft={navLeft}
      title={t("settingsPage.pageTitle")}
      bottom={<div className="font-feature-tnum mt-4 text-center text-sm font-normal text-neutral-300">{version}</div>}
      business
    >
      <div className="pb-8-safe flex flex-col">
        <ListItem
          href={t("settingsPage.cguBusinessLink")}
          target="_blank"
          label={t("settingsPage.other.cgu")}
          size="small"
          selected={false}
          trailingIcon={ArrowRightIcon}
          position="none"
          horizontalPadding={false}
        />
        <ListItem
          href={t("gdprConsentField.privacyLink", { ns: "common" })}
          target="_blank"
          label={t("settingsPage.other.privacyPolicy")}
          size="small"
          trailingIcon={ArrowRightIcon}
          position="none"
          horizontalPadding={false}
        />
        {signedIn && (
          <ListItem
            href="/sign-out"
            label={t("settingsPage.other.signOut")}
            size="small"
            trailingIcon={ArrowRightIcon}
            position="none"
            horizontalPadding={false}
          />
        )}
        {signedIn && canDelete && (
          <ListItem
            href="/user-deletion-intents/new"
            label={t("settingsPage.other.deleteAccount")}
            size="small"
            trailingIcon={ArrowRightIcon}
            position="none"
            horizontalPadding={false}
          />
        )}
      </div>
    </Page>
  );
};

export default SettingsScreen;
