import { AccountUserRole, AccountFragment } from "operations";
import { orderBy } from "utils";

const getBusinessAccounts = (accounts: (AccountFragment | null)[]) => {
  return accounts
    .filter(
      (account): account is AccountFragment =>
        account !== null && account?.pro && account.accountUser?.role === AccountUserRole.Admin
    )
    .sort(orderBy("label"));
};

export default getBusinessAccounts;
