import { OnboardingStatusFragment, useLayoutQuery } from "@webapps/shared/operations";

import useSignedIn from "./useSignedIn";

export const useOnboardingStatus = () => {
  const signedIn = useSignedIn();

  const { data } = useLayoutQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !signedIn,
  });

  return data?.me?.onboardingStatus as OnboardingStatusFragment | undefined;
};
