import { Button, Step } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { useLibPhoneNumber } from "@webapps/shared/libs";
import { Country, InvitationDataType } from "@webapps/shared/operations";
import { FieldArray, Formik, FormikHelpers } from "formik";
import MailIcon from "images/icons/ri/mail.svg?react";
import { ComponentProps, FormEvent, FunctionComponent, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useBulkInvitationFileUrl } from "../../../hooks/useBulkInvitationFileUrl";
import BackInviteModal from "../components/BackInviteModal";
import InvitationsForm from "../components/InvitationsForm";
import WelcomeModal from "../components/WelcomeModal";
import { getCompleteInvitations, hasInvitationsIncompleteLines } from "../helpers";

export type InvitationFormData = InvitationDataType & { countryCode: Country };
export type FormValues = { invitations: InvitationFormData[] };

export const EmptyInvitation = { countryCode: Country.Fr, firstname: "", lastname: "", phoneNumber: "" };

const initialValues = { invitations: new Array(3).fill(EmptyInvitation) };

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  children?: never;
  error?: string | undefined;
  onSubmit: (values: InvitationDataType[]) => Promise<void>;
  resetError: () => void;
  trackButtonClicked?: () => void;
  trackPage?: () => void;
}

const Screen: FunctionComponent<Props> = ({ error, onSubmit, trackPage, resetError, trackButtonClicked, navLeft }) => {
  const { t } = useTranslation();
  const ensureLibPhoneNumber = useLibPhoneNumber();
  const bulkInvitationFileUrl = useBulkInvitationFileUrl();
  const submit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const { parsePhoneNumber } = await ensureLibPhoneNumber();
    const completeInvitations = getCompleteInvitations(values.invitations);
    const formattedInvitations = completeInvitations.map((invitation) => {
      const phonelib = parsePhoneNumber(invitation.phoneNumber, invitation.countryCode);
      return {
        firstname: invitation.firstname,
        lastname: invitation.lastname,
        phoneNumber: phonelib.formatInternational(),
      };
    });
    await onSubmit(formattedInvitations);
    actions.resetForm();
  };

  const headerChildren = bulkInvitationFileUrl ? (
    <a
      href={bulkInvitationFileUrl}
      title={t("invitationsPage.invitationsScreen.bulkLabel")}
      className="text-label-sm flex items-center justify-center gap-1 self-end rounded-full border border-neutral-100 px-4 py-1 font-normal text-neutral-600"
      onClick={trackButtonClicked}
    >
      {t("invitationsPage.invitationsScreen.bulkLabel")}
      <MailIcon className="h-5 w-5 text-neutral-600" />
    </a>
  ) : null;

  useEffect(() => {
    trackPage?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      {({ handleSubmit, isSubmitting, isValidating, values, touched, setFieldValue }) => {
        const completeInvitations = getCompleteInvitations(values.invitations);

        // we can't rely on Formik errors here, it is desynchronized from real values
        const hasError = values.invitations.find((invitation, index) =>
          Object.entries(invitation).some(
            ([key, value]) => !value && touched?.invitations?.[index]?.[key as keyof InvitationDataType]
          )
        );

        const cleanFormAndSubmit = async (e?: FormEvent<HTMLFormElement>) => {
          // setFieldValue is asynchronous, we need to wait for it to complete before submitting https://github.com/jaredpalmer/formik/issues/529
          await setFieldValue("invitations", completeInvitations);
          handleSubmit(e);
        };

        const invitationsCount = completeInvitations.length === 0 ? 1 : completeInvitations.length;
        const hasIncompleteLines = hasInvitationsIncompleteLines(values.invitations);
        const disableButton =
          hasIncompleteLines || completeInvitations.length === 0 || isValidating || isSubmitting || !!hasError;

        const bottom = (
          <>
            {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

            {error && !hasError ? <div className="whitespace-pre-line text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full"
              disabled={disableButton}
              label={t("invitationsPage.invitationsScreen.cta", { count: invitationsCount })}
              loading={isValidating || isSubmitting}
              onClick={cleanFormAndSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <Page
            bottom={bottom}
            navLeft={navLeft}
            subTitle={t("invitationsPage.invitationsScreen.subtitle")}
            title={t("invitationsPage.invitationsScreen.pageTitle")}
            headerChildren={headerChildren}
            business
          >
            <WelcomeModal />
            <BackInviteModal />
            <div className="flex gap-2">
              {[...Array(4)].map((_: undefined, index: number) => (
                <Step
                  key={`onboardingCard-${index}`}
                  number={index + 1}
                  content={
                    <Trans
                      i18nKey={`invitationsPage.invitationsScreen.onboardingCards.${index + 1}`}
                      components={{
                        1: <span className="font-bold italic" />,
                      }}
                    />
                  }
                />
              ))}
            </div>
            <FieldArray name="invitations">
              {({ push, remove }) => (
                <InvitationsForm push={push} remove={remove} resetServerError={resetError} hasServerError={!!error} />
              )}
            </FieldArray>
          </Page>
        );
      }}
    </Formik>
  );
};

export default Screen;
