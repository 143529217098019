import Page from "@webapps/shared/components/templates/Page";
import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: never;
  navLeft?: ReactNode;
  title?: string;
}

const NoAccountSelectedScreen: FunctionComponent<Props> = ({ navLeft, title }) => {
  const { t } = useTranslation();
  return (
    <Page navLeft={navLeft} title={title}>
      <div className="">{t("noAccountSelectedScreen.info")}</div>
    </Page>
  );
};

export default NoAccountSelectedScreen;
