import { useErrorHandling } from "@webapps/shared/hooks";
import { ErrorScreen } from "components";

const ErrorPage = ({ error }: { error?: Error }) => {
  const { title, errorMessage } = useErrorHandling(error);

  return <ErrorScreen error={errorMessage} navLeft={null} canReloadPage centered title={title} />;
};

export default ErrorPage;
