import { BottomSheet, ListItemRadioButtonField } from "components";
import { Formik } from "formik";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import UserFollowIcon from "../../../../images/icons/ri/user-follow-fill.svg?react";
import UserUnfollowIcon from "../../../../images/icons/ri/user-unfollow-fill.svg?react";

interface FormValues {
  active: string;
}

interface Props {
  active: boolean;
  onClose: () => void;
  onConfirm: (active: boolean) => Promise<boolean>;
  subTitle?: string | undefined;
  title?: string | undefined;
}

const Sheet: FunctionComponent<Props> = ({ active, onClose, onConfirm, subTitle, title }) => {
  const { t } = useTranslation();
  const submit = useCallback(
    async (values: FormValues) => {
      const result = await onConfirm(Boolean(JSON.parse(values.active)));
      if (result) {
        onClose();
      }
    },
    [onClose, onConfirm]
  );

  return (
    <Formik
      initialValues={{
        active: active.toString(),
      }}
      onSubmit={submit}
    >
      {({ handleSubmit, isSubmitting, isValidating, values }) => {
        return (
          <BottomSheet
            onClose={onClose}
            title={title ?? t("changeStatusBottomSheet.title")}
            subTitle={subTitle}
            rawContent
          >
            <div className="py-4-safe">
              <ListItemRadioButtonField
                name="active"
                label={t("changeStatusBottomSheet.active.label")}
                loading={(isSubmitting || isValidating) && values.active === "true"}
                onClick={handleSubmit}
                value={"true"}
                listItemProps={{
                  leadingIcon: UserFollowIcon,
                  position: "none",
                  subLabel: t("changeStatusBottomSheet.active.sublabel"),
                }}
              />

              <ListItemRadioButtonField
                name="active"
                label={t("changeStatusBottomSheet.disabled.label")}
                loading={(isSubmitting || isValidating) && values.active === "false"}
                onClick={handleSubmit}
                value={"false"}
                listItemProps={{
                  leadingIcon: UserUnfollowIcon,
                  position: "none",
                  subLabel: t("changeStatusBottomSheet.disabled.sublabel"),
                }}
              />
            </div>
          </BottomSheet>
        );
      }}
    </Formik>
  );
};

export default Sheet;
