import { usePhoneNumberScreenQuery } from "@webapps/shared/operations";
import { getCountryFormObject } from "@webapps/shared/utils";

export const useCountriesWithCallingCode = () => {
  const { data, error } = usePhoneNumberScreenQuery({
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const countryPhoneNumbers = getCountryFormObject(true, data?.countries);

  return countryPhoneNumbers ?? error;
};
