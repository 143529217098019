import { InputField, PhoneNumberField } from "@webapps/shared/components";
import { useFormikContext } from "formik";
// eslint-disable-next-line import/no-named-as-default
import parsePhoneNumber, { CountryCode, AsYouType } from "libphonenumber-js/mobile";
import { ChangeEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { getCallingCodeFromCountryCode } from "utils";

import { useCountriesWithCallingCode } from "../../../hooks/useCountriesWithCallingCode";
import DeleteIcon from "../../../images/icons/ri/trash-delete.svg?react";
import { FormValues } from "../screens/Screen";

import InvitationRowLabel from "./InvitationRowLabel";

type Props = {
  children?: never;
  handleRemove: (index: number) => void;
  index: number;
  resetServerError: () => void;
  serverError?: boolean;
};

const InvitationRow: FunctionComponent<Props> = ({ index, handleRemove, resetServerError, serverError }) => {
  const { t } = useTranslation();
  const countries = useCountriesWithCallingCode();
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const invitation = values.invitations[index];
  const selectedCallingCode = getCallingCodeFromCountryCode(invitation.countryCode, countries);
  const phoneInput = `invitations[${index}].phoneNumber`;

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (serverError) {
      resetServerError();
    }
    setFieldValue(name, value);
  };

  const handleChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (!invitation.countryCode) {
      setFieldValue(phoneInput, value);
    }

    const asYouType = new AsYouType(invitation.countryCode as CountryCode);
    const newPhoneNumber = asYouType.input(value);
    setFieldValue(name, newPhoneNumber);
  };

  const handleClearPhoneInput = () => {
    setFieldValue(phoneInput, "");
    if (serverError) {
      resetServerError();
    }
  };

  const validateInput = (value: string) => {
    const hasRowSomeValue = Object.entries(invitation).some(([key, value]) => key !== "countryCode" && !!value);
    return !value && hasRowSomeValue ? t("form.validate.required", { ns: "common" }) : undefined;
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) {
      return validateInput(phoneNumber);
    }
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, invitation.countryCode as CountryCode);

    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      return t("form.validate.phoneNumber", { ns: "common" });
    }

    return undefined;
  };

  return (
    <div className="grid-cols-invitation-row mb-2 grid gap-4">
      <div>
        {index === 0 && <InvitationRowLabel label={t("invitationsPage.invitationsScreen.firstname")} />}

        <InputField
          name={`invitations[${index}].firstname`}
          validate={validateInput}
          inputProps={{
            autoComplete: "firstname",
            enterKeyHint: "send",
            onChange: handleChangeInput,
            placeholder: t("invitationsPage.invitationsScreen.firstnamePlaceholder"),
          }}
          variant="small"
        />
      </div>
      <div>
        {index === 0 && <InvitationRowLabel label={t("invitationsPage.invitationsScreen.lastname")} />}

        <InputField
          name={`invitations[${index}].lastname`}
          validate={validateInput}
          inputProps={{
            autoComplete: "lastname",
            enterKeyHint: "send",
            onChange: handleChangeInput,
            placeholder: t("invitationsPage.invitationsScreen.lastnamePlaceholder"),
          }}
          variant="small"
        />
      </div>
      <div>
        {index === 0 && <InvitationRowLabel label={t("invitationsPage.invitationsScreen.phoneNumber")} />}
        <PhoneNumberField
          name={`invitations[${index}].phoneNumber`}
          prefixName={`invitations[${index}].countryCode`}
          validate={validatePhoneNumber}
          inputProps={{
            autoComplete: "tel",
            inputMode: "tel",
            onChange: handleChangeNumber,
            placeholder: t("invitationsPage.invitationsScreen.phoneNumberPlaceholder"),
            type: "tel",
          }}
          items={countries}
          selectProps={{ autoComplete: "tel-country-code" }}
          onClear={handleClearPhoneInput}
          callingCode={selectedCallingCode}
          variant="small"
        />
      </div>
      <div className="flex items-center">
        {index !== 0 && (
          <DeleteIcon
            onClick={() => handleRemove(index)}
            className="mt-2 h-4 w-4 flex-none cursor-pointer self-start text-neutral-700"
          />
        )}
      </div>
    </div>
  );
};

export default InvitationRow;
