import { FunctionComponent } from "react";
import { MarkerProps } from "react-map-gl";

import { MapLocation } from "../../organisms/Map";
import MapHourPin, { MapHourPinProps } from "../MapHourPin";
import MapPin, { MapPinProps } from "../MapPin";

interface PinPointBaseProps {
  Marker: FunctionComponent<MarkerProps>;
  coordinates: MapLocation;
  key: string;
}

type PropsWithHourPin = {
  isHour: true;
  selected?: never;
  status?: never;
} & MapHourPinProps;

type PropsWithPin = {
  confirmed?: never;
  hour?: never;
  isHour?: boolean;
} & MapPinProps;

export type PinPointProps = (PropsWithHourPin | PropsWithPin) & PinPointBaseProps;

const PinPoint = ({ isHour, confirmed, coordinates, hour, selected, status, Marker, ...rest }: PinPointProps) => (
  <Marker anchor="bottom" style={{ zIndex: 4 }} longitude={coordinates[0]} latitude={coordinates[1]} {...rest}>
    {isHour ? <MapHourPin hour={hour} confirmed={!!confirmed} /> : <MapPin selected={selected} status={status} />}
  </Marker>
);

export default PinPoint;
