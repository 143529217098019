import { ActionModal, Button, List, Panel } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import WarningIcon from "@webapps/shared/images/icons/ri/warning-triangle.svg?react";
import ChevronRightIcon from "images/icons/ri/chevron-right.svg?react";
import { AccountUserRole, InvitationStatusEnum, UserPageScreen_AccountUserFragment } from "operations";
import { ComponentProps, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { castDateOrString } from "utils";

import ChangeRoleBottomSheet from "../../components/organisms/BottomSheets/ChangeRoleBottomSheet";
import ChangeStatusBottomSheet from "../../components/organisms/BottomSheets/ChangeStatusBottomSheet";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft" | "navRight"> {
  accountUser: UserPageScreen_AccountUserFragment;
  children?: never;
  deleting?: boolean;
  invitationStatus?: InvitationStatusEnum | undefined;
  onChangeRole?: (role: AccountUserRole) => Promise<boolean>;
  onChangeStatus?: (active: boolean) => Promise<boolean>;
  onClickFullname?: () => void;
  onDelete?: () => void;
  onReinvite?: () => void;
  reinvitationErrorMessage?: string;
  reinviting?: boolean;
  showAccountDetails?: boolean | undefined;
  showStatus?: boolean | undefined;
  showUserTitleSection?: boolean | undefined;
  title?: string | undefined;
  trackPage?: () => void;
}

const UserScreen: FunctionComponent<Props> = ({
  accountUser,
  invitationStatus,
  navLeft,
  navRight,
  onChangeRole,
  onChangeStatus,
  onClickFullname,
  onReinvite,
  reinviting,
  showAccountDetails = true,
  showStatus = true,
  showUserTitleSection = true,
  title,
  trackPage,
  reinvitationErrorMessage,
  onDelete,
  deleting,
}) => {
  const { t } = useTranslation();
  const { active, fullname, invitedBy, joinedAt, lastname, role, user } = accountUser;
  const navigate = useNavigate();
  const { phoneNumber } = user;

  const [changeRoleSheetVisible, setChangeRoleSheetVisible] = useState(false);
  const [changeStatusSheetVisible, setChangeStatusSheetVisible] = useState(false);
  const [isModalDisplayed, setModalDisplayed] = useState(false);

  const changeRoleBottomSheet = useMemo(() => {
    if (!changeRoleSheetVisible || !onChangeRole) return null;

    return (
      <ChangeRoleBottomSheet onClose={() => setChangeRoleSheetVisible(false)} onConfirm={onChangeRole} role={role} />
    );
  }, [changeRoleSheetVisible, onChangeRole, role]);

  const changeStatusBottomSheet = useMemo(() => {
    if (!changeStatusSheetVisible || !onChangeStatus) return null;

    return (
      <ChangeStatusBottomSheet
        active={active}
        onClose={() => setChangeStatusSheetVisible(false)}
        onConfirm={onChangeStatus}
      />
    );
  }, [active, changeStatusSheetVisible, onChangeStatus]);

  const handleCloseBottomSheet = useCallback(() => {
    setChangeRoleSheetVisible(false);
    setChangeStatusSheetVisible(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalDisplayed(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    onDelete?.();
    setModalDisplayed(false);
    navigate("/users");
  }, [onDelete, navigate]);

  const infoBlock = useMemo(() => {
    if (!invitationStatus || invitationStatus === InvitationStatusEnum.Confirmed) return undefined;

    if (invitationStatus === InvitationStatusEnum.Refused) {
      return (
        <Panel
          color="orange"
          title={t("userScreen.infoBlock.titleRefused")}
          label={t("userScreen.infoBlock.label")}
          disabled={reinviting}
          loading={reinviting}
          onClick={onReinvite}
        >
          {t("userScreen.infoBlock.textRefused")}
        </Panel>
      );
    }

    return (
      <Panel
        color="orange"
        title={t("userScreen.infoBlock.titlePending")}
        label={reinvitationErrorMessage ? undefined : t("userScreen.infoBlock.label")}
        disabled={reinviting}
        loading={reinviting}
        onClick={onReinvite}
      >
        <span className="preline">
          {t("userScreen.infoBlock.textPending")}
          {!!reinvitationErrorMessage && (
            <>
              <br />
              {reinvitationErrorMessage}
            </>
          )}
        </span>
      </Panel>
    );
  }, [invitationStatus, onReinvite, reinviting, t, reinvitationErrorMessage]);

  const userInfoListItems = useMemo(() => {
    return [
      {
        label: t("userScreen.name"),
        onClick: onClickFullname,
        subLabel: fullname,
        trailingDotHint: !lastname,
        trailingHint: !lastname ? t("userScreen.incompleteName") : undefined,
        trailingIcon: onClickFullname ? ChevronRightIcon : undefined,
      },
      {
        label: t("userScreen.phone"),
        subLabel: phoneNumber,
      },
    ];
  }, [fullname, lastname, onClickFullname, phoneNumber, t]);

  const userAccountInfoListItems = useMemo(() => {
    const listItems = [];

    if (role) {
      listItems.push({
        label: t("userScreen.role"),
        onClick: onChangeRole ? () => setChangeRoleSheetVisible(true) : undefined,
        subLabel: role === AccountUserRole.Admin ? t("userScreen.admin") : t("userScreen.member"),
        trailingIcon: onChangeRole ? ChevronRightIcon : undefined,
      });
    }

    if (showStatus) {
      listItems.push({
        label: t("userScreen.statusSectionTitle"),
        onClick: onChangeStatus ? () => setChangeStatusSheetVisible(true) : undefined,
        subLabel: active ? t("userScreen.active") : t("userScreen.inactive"),
        trailingIcon: onChangeStatus ? ChevronRightIcon : undefined,
      });
    }

    listItems.push({
      label: t("userScreen.joinedOn"),
      subLabel: joinedAt
        ? t("format.time", {
            formatParams: {
              val: { dateStyle: "long" },
            },
            ns: "common",
            val: castDateOrString(joinedAt),
          })
        : t("unknown.doubleDash", { ns: "common" }),
    });

    if (invitedBy) {
      listItems.push({
        label: t("userScreen.invitedBy"),
        subLabel: invitedBy.fullname,
      });
    }

    return listItems;
  }, [active, invitedBy, joinedAt, onChangeRole, onChangeStatus, role, showStatus, t]);

  useEffect(() => {
    trackPage?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      navLeft={navLeft}
      navRight={navRight}
      title={title ?? t("userScreen.pageTitle")}
      onCloseBottomSheet={handleCloseBottomSheet}
      bottomSheet={changeRoleBottomSheet || changeStatusBottomSheet}
      business
    >
      {onDelete && (
        <ActionModal
          onClose={handleCloseModal}
          isOpen={isModalDisplayed}
          title={t(`invitationPage.modal.title`)}
          cta={t(`invitationPage.modal.cta`)}
          cancelCta={t(`invitationPage.modal.back`)}
          onCancel={handleCloseModal}
          onConfirm={handleConfirmDelete}
          icon={WarningIcon}
        />
      )}
      <div className="py-4-safe flex flex-col gap-6">
        {infoBlock}

        <List
          baseKey="user-info"
          listItems={userInfoListItems}
          sectionHeading={showUserTitleSection ? t("userScreen.userSectionTitle") : undefined}
        />

        {showAccountDetails && (
          <List
            baseKey="user-account-info"
            listItems={userAccountInfoListItems}
            sectionHeading={t("userScreen.accountSectionTitle")}
          />
        )}
        {onDelete && (
          <Button
            onClick={() => setModalDisplayed(true)}
            kind="primary"
            size="small"
            label={t("invitationPage.cta")}
            loading={deleting}
            fullWidth={false}
            className="self-start"
          />
        )}
      </div>
    </Page>
  );
};

export default UserScreen;
