import { useCallback, useRef } from "react";

export function loadLibPhoneNumber() {
  return import(/* webpackChunkName: "libphonenumber" */ "libphonenumber-js");
}

export function useLibPhoneNumber() {
  const importer = useRef(loadLibPhoneNumber());

  return useCallback(() => importer.current, [importer]);
}
