import { ProAccountLabelType, ProAccountType } from "operations";
import { useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

import { useOnboardingStatus } from "./useOnboardingStatus";

export const useProType = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isRegister = location.pathname.includes("register");
  const proTypeLabelParam = searchParams.get("account_type") as ProAccountLabelType | undefined;
  const onboardingStatus = useOnboardingStatus();
  const proTypeLabelServer = onboardingStatus?.proTypeLabel;
  // we first rely on the server data, then on the url param
  const proTypeLabel = proTypeLabelServer ?? proTypeLabelParam;
  const proType = onboardingStatus?.proType
    ? onboardingStatus?.proType
    : proTypeLabelParam === "fleet"
      ? ProAccountType.Smb
      : proTypeLabelParam === "independant"
        ? ProAccountType.Independant
        : undefined;

  // sync the url params with the server data
  useEffect(() => {
    if (
      proTypeLabelServer &&
      ((proTypeLabelParam && proTypeLabelServer !== proTypeLabelParam) || (isRegister && !proTypeLabelParam))
    ) {
      searchParams.set("account_type", proTypeLabelServer);
      setSearchParams(searchParams);
      return;
    }
  }, [proTypeLabelParam, proTypeLabelServer, searchParams, setSearchParams, isRegister]);

  return {
    isFleet: proType === ProAccountType.Smb,
    proType,
    proTypeLabel,
  };
};
