import classNames from "classnames";
import { FunctionComponent, ReactNode, SVGProps } from "react";

import InfoIcon from "../../../images/icons/ri/information.svg?react";
import Alert from "../Alert";
import Button from "../Button";

export type InfoModalProps = {
  buttonClassName?: string;
  children?: ReactNode;
  closeModal: () => void;
  cta?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  isOpen: boolean;
  showCloseButton?: boolean;
  subtitle?: string;
  title: string;
};

const InfoModal: FunctionComponent<InfoModalProps> = ({
  buttonClassName,
  title,
  subtitle,
  cta,
  children,
  icon: Icon,
  isOpen,
  closeModal,
  showCloseButton = false,
}) => {
  return isOpen ? (
    <Alert showCloseButton={showCloseButton} onClose={closeModal} centered>
      <div className="flex flex-col gap-4 px-1">
        <div className="self-center rounded-full bg-neutral-50 p-2">
          {!!Icon ? (
            <Icon className="h-8 w-8 text-neutral-700" />
          ) : (
            <InfoIcon className="h-6 w-6 flex-none text-neutral-900" />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-center text-xl font-light text-neutral-900">{title}</div>
          {!!subtitle && (
            <div className="min-h-12 text-medium text-center font-normal text-neutral-600">{subtitle}</div>
          )}
        </div>
        {children}
        {!!cta && (
          <Button
            className={classNames("w-full", buttonClassName)}
            label={cta}
            onClick={closeModal}
            size="large"
            type="button"
            kind="primary"
          />
        )}
      </div>
    </Alert>
  ) : null;
};

export default InfoModal;
