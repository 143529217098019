import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import NotFoundScreen from "../../screens/NotFoundScreen";

interface Props {
  children?: never;
}

const NotFoundPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  //
  // Props
  //

  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={goBack} />;
  }, [goBack]);

  return <NotFoundScreen title={t("notFoundPage.pageTitle", { ns: "common" })} navLeft={navLeft} />;
};

export default NotFoundPage;
