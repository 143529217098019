import FilterSelect from "@webapps/apps/business/src/components/molecules/FilterSelect";
import { useTranslation } from "react-i18next";

export type SortingKey = "date" | "amount";
export const sortingKeys = ["date", "amount"] as SortingKey[];
export type Sorting = { desc: boolean; value: SortingKey };

interface Props {
  children?: never;
  onClick: () => void;
  onItemClick: (value: SortingKey) => void;
  sorting: Sorting;
}

const SortBySelect = ({ sorting, ...rest }: Props) => {
  const { t } = useTranslation();

  const items = sortingKeys.map((key) => ({ label: t(`chargesPage.sortBySelect.${key}`), value: key }));
  const selectedItem = items.find((item) => item.value === sorting.value) || items[0];

  return (
    <FilterSelect
      items={items}
      kind="sort"
      selectedItems={[selectedItem]}
      label={t("chargesPage.sortBySelect.label")}
      desc={sorting.desc}
      size="medium"
      {...rest}
    />
  );
};

export default SortBySelect;
