import classNames from "classnames";
import { Navbar } from "components";
import { FunctionComponent, ReactNode, SVGProps, useMemo } from "react";

export type LandingIllustration = "both" | "man" | "woman";
export type ContentSize = "small" | "large";
export type Alignment = "center" | "end" | "start";

interface Props {
  alignment?: Alignment;
  button?: ReactNode;
  children?: never;
  content: ReactNode;
  contentSize?: ContentSize;
  fullHeight?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  illustration: LandingIllustration;
  navbar?: ReactNode;
  title: string;
  titleClassName?: string;
}

const Screen: FunctionComponent<Props> = ({
  alignment = "center",
  button,
  content,
  icon: Icon,
  illustration,
  navbar,
  title,
  contentSize = "small",
  fullHeight = false,
  titleClassName,
}) => {
  const illustrationBackground = useMemo(() => {
    if (illustration === "man") {
      return "bg-man-charging-model";
    }

    if (illustration === "woman") {
      return "bg-woman-charging-model";
    }

    return "bg-woman-and-man-charging-model";
  }, [illustration]);

  return (
    <section className="flex h-full flex-col">
      {navbar ?? <Navbar />}
      <div className="mb-4 flex flex-1 flex-row px-4 md:mb-0">
        <div
          className={classNames("bg-brand-50 w-full flex-1 self-center rounded-lg md:self-auto md:rounded-r-none", {
            "h-full": fullHeight,
          })}
        >
          <div
            className={classNames("relative flex h-full flex-col gap-6 px-6 py-8 md:justify-center md:px-20", {
              "items-center": alignment === "center",
              "items-end": alignment === "end",
              "items-start": alignment === "start",
            })}
          >
            {Icon && <Icon className="h-[110px] text-neutral-600 md:h-[130px]" />}
            <h1
              className={classNames(`my-0 text-2xl font-light text-neutral-800 ${titleClassName}`, {
                "md:text-4xl": contentSize === "large",
                "md:text-5xl": contentSize === "small",
              })}
            >
              {title}
            </h1>
            <div className="w-full space-y-4 font-light">{content}</div>
            <div className="space-y-4">{button}</div>
          </div>
        </div>

        <div className={classNames("hidden basis-1/3 rounded-r-lg bg-cover md:block", illustrationBackground)} />
      </div>
    </section>
  );
};

export default Screen;
