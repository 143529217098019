declare global {
  interface Window {
    Intercom: unknown | undefined;
  }
}

export const isIntercomDefined = () => {
  return window.Intercom;
};

export const prepaymentReassuranceUrl = {
  de: "https://intercom.help/go-electra/en/articles/6028369-the-bank-imprint",
  en: "https://intercom.help/go-electra/en/articles/6028369-the-bank-imprint",
  es: "https://intercom.help/go-electra/en/articles/6028369-the-bank-imprint",
  fr: "https://intercom.help/go-electra/fr/articles/6028369-empreinte-bancaire-de-29-euros",
  it: "https://intercom.help/go-electra/en/articles/6028369-the-bank-imprint",
  nl: "https://intercom.help/go-electra/en/articles/6028369-the-bank-imprint",
};
