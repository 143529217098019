import { SupportAction, ErrorScreen, LoadingScreen } from "@webapps/shared/components";
import { useNotification } from "hooks";
import join from "lodash/join";
import { useConfirmUserDeletionIntentMutation, useUserDeletionIntentPageQuery } from "operations";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import useSignOut from "../../hooks/useSignOut";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";
import NotFoundScreen from "../../screens/NotFoundScreen";

import Screen from "./screens/Screen";

const UserDeletionIntentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDeletionIntentId } = useParams();
  const signOut = useSignOut();

  const pageTitle = t("userDeletionIntentPage.pageTitle");
  const currentAccountId = useUser((state) => state.currentAccountId);
  const setNotification = useNotification((state) => state.setNotification);

  const [confirmError, setConfirmError] = useState<string>();

  const { data, loading, error } = useUserDeletionIntentPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId || !userDeletionIntentId,
    variables: {
      accountId: currentAccountId ?? "none",
      userDeletionIntentId: userDeletionIntentId ?? "none",
    },
  });

  const userDeletionIntent = useMemo(() => data?.me?.account?.userDeletionIntent ?? undefined, [data]);

  const [confirmMutation] = useConfirmUserDeletionIntentMutation({
    onCompleted: ({ confirmUserDeletionIntent: result }) => {
      if (result?.errors && result?.errors.length > 0) {
        setConfirmError(join(result.errors, " "));
        return;
      }

      if (result?.deleted) {
        setNotification({ message: t("notification.user.deleteSuccess") });
        setConfirmError(undefined);
        signOut();
        navigate(`/sign-in`);
        return;
      }

      setConfirmError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setConfirmError(error.message);
    },
  });

  const onSubmit = useCallback(
    async (comment: string | undefined) => {
      await confirmMutation({
        variables: {
          input: {
            comment,
            userDeletionIntentId: userDeletionIntentId ?? "none",
          },
        },
      });
    },
    [confirmMutation, userDeletionIntentId]
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  //
  // Props
  //

  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={goBack} />;
  }, [goBack]);

  const navRight = useMemo(() => <SupportAction />, []);

  const errorMessage = useMemo(() => error?.message ?? undefined, [error]);

  const screenProps = useMemo(() => {
    if (!currentAccountId) return;

    return {
      error: confirmError,
      message: userDeletionIntent?.message,
      navLeft,
      navRight,
      onKeepAccount: goBack,
      onSubmit,
      userDeletionIntentId: userDeletionIntentId ?? "none",
    };
  }, [
    currentAccountId,
    confirmError,
    userDeletionIntent?.message,
    navLeft,
    navRight,
    goBack,
    onSubmit,
    userDeletionIntentId,
  ]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen title={pageTitle} navLeft={navLeft} error={errorMessage} canReloadPage business />;
  }

  if (!currentAccountId) {
    return <NoAccountSelectedScreen title={pageTitle} navLeft={navLeft} />;
  }

  if (!userDeletionIntent) {
    if (loading) return <LoadingScreen title={pageTitle} navLeft={navLeft} business />;

    return <NotFoundScreen title={t("userDeletionIntentPage.notFound")} navLeft={navLeft} />;
  }

  if (screenProps) {
    return <Screen {...screenProps} />;
  }

  throw new Error("Invalid state");
};

export default UserDeletionIntentPage;
