import { MutableRefObject, useEffect, useRef } from "react";

interface Props {
  enabled?: boolean | undefined;
}

export const useInputFocus = ({ enabled = true }: Props = {}): MutableRefObject<HTMLInputElement | null> => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!enabled) return;

    inputRef.current?.focus();
  }, [enabled]);

  return inputRef;
};
