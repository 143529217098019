import { useApolloClient } from "@apollo/client";
import { AccountUser, AccountUserFragmentDoc } from "@webapps/shared/operations";

import useSignedIn from "./useSignedIn";
import { useUser } from "./useUser";

export const useCurrentAccountUser = () => {
  const currentAccountUserId = useUser((state) => state.currentAccountUserId);
  const client = useApolloClient();
  const signedIn = useSignedIn();
  if (!signedIn) return undefined;
  const accountUser = client.readFragment<AccountUser>({
    fragment: AccountUserFragmentDoc,
    fragmentName: "AccountUserFragment",
    id: `AccountUser:${currentAccountUserId}`,
  });
  return accountUser;
};
