import { useMediaQuery } from "@react-hook/media-query";
import { Page } from "@webapps/shared/components";
import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

import { PAGE_LENGTH } from "../..";
import { renderData, TableData } from "../../utils";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  data: TableData[] | {}[];
}

const ChargeTable: FunctionComponent<Props> = ({ data }) => {
  const xlScreen = useMediaQuery("only screen and (min-width: 1280px)");
  const renderHeaders = renderData(data[0]).map(({ header, headerSize }) => ({
    header,
    headerSize,
  }));
  const isFull = data.length >= PAGE_LENGTH;
  const isLoading = Object.keys(data[0]).length === 0;

  return (
    <table
      className={classNames("text-primary-default w-full table-fixed text-sm font-normal", {
        "h-auto": !isFull,
        "h-full": isFull,
      })}
    >
      <thead>
        <tr>
          {renderHeaders.map(({ header, headerSize }, index) => {
            const style = xlScreen ? { width: `${headerSize}px` } : { maxWidth: `${headerSize}px` };
            return (
              <th key={`header-${index}`} className={classNames("text-left align-top font-bold")} style={style}>
                {header()}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          return (
            <tr
              key={`row-${index}`}
              className={classNames("border-b-charges-table-border h-[2.625rem] border-b odd:bg-white", {
                "even:bg-charges-table-background": !isLoading,
              })}
            >
              {renderData(row).map((cell, index) => {
                return (
                  <td key={`cell-${index}`} className="pr-[0.625rem]">
                    {cell.cell()}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ChargeTable;
