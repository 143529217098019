import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";

interface Props {
  bgColor?: string;
  borderRadius?: "large" | "medium" | "normal";
  children: ReactNode;
  className?: string;
  padding?: string;
}

const Banner: FunctionComponent<Props> = ({
  className,
  children,
  bgColor,
  borderRadius = "large",
  padding = "p-4",
}) => {
  return (
    <div
      className={classNames(`round flex ${padding} ${bgColor ?? "bg-neutral-100"}`, className, {
        rounded: borderRadius === "normal",
        "rounded-lg": borderRadius === "large",
        "rounded-md": borderRadius === "medium",
      })}
    >
      {children}
    </div>
  );
};

export default Banner;
