import { StationPublicStatus, StationStatus } from "operations";
import { useTranslation } from "react-i18next";

export const useParkingLabel = (
  status?: StationPublicStatus,
  availableSpotsCount?: number,
  stationStatus?: StationStatus,
  nextOpening?: string | null | undefined,
  comingSoonLabel?: string | null
) => {
  const { t } = useTranslation();

  const parkingLabel =
    status === StationPublicStatus.ComingSoon
      ? comingSoonLabel ?? t("stationSummary.comingSoon")
      : status === StationPublicStatus.OutOfService
        ? t("stationSummary.outOfService")
        : t("stationSummary.parkingLabel", { count: availableSpotsCount });

  return parkingLabel;
};
