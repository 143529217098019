import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";

import { CloseAction } from "../Action";

interface Props {
  centered?: boolean;
  children?: ReactNode;
  closeOnOverlay?: boolean;
  desktopModeEnabled?: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  wide?: boolean;
}

const Alert: FunctionComponent<Props> = ({
  centered = false,
  children,
  closeOnOverlay = true,
  desktopModeEnabled = true,
  onClose,
  showCloseButton = true,
  wide = false,
}) => {
  return (
    <div
      className={classNames("fixed inset-0 z-50 flex items-center justify-center", {
        "md:ml-72 md:max-w-screen-lg": desktopModeEnabled && !centered,
        "md:w-full": centered,
      })}
    >
      <div
        className={classNames("absolute inset-0 bg-black", { "bg-opacity-20": !wide, "bg-opacity-50": wide })}
        onClick={closeOnOverlay ? onClose : undefined}
      />
      <div
        className={classNames("z-20 mx-4 flex w-full flex-col rounded-md bg-white", {
          "p-4 md:max-w-sm": !wide,
          "px-4 pb-6 pt-8 md:w-1/2 md:px-10 md:py-8": wide,
        })}
      >
        <div className="h-6 self-end">{showCloseButton ? <CloseAction onClick={onClose} /> : null}</div>
        {children}
      </div>
    </div>
  );
};

export default Alert;
