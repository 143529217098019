import { LoadingScreen, ErrorScreen } from "components";
import { useSettingsPageQuery } from "operations";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { version } from "utils";

import { ResponsiveMenuAction } from "../../components/molecules/BusinessAction";
import useSignedIn from "../../hooks/useSignedIn";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";

import Screen from "./screens/Screen";

interface Props {
  children?: never;
}

const Settings: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const pageTitle = t("settingsPage.pageTitle");
  const currentAccountId = useUser((state) => state.currentAccountId);
  const signedIn = useSignedIn();

  const { data, loading, error } = useSettingsPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId,

    variables: {
      accountId: currentAccountId ?? "none",
    },
  });

  /*
   * Props
   */
  const account = useMemo(() => data?.me?.account ?? undefined, [data]);

  const errorMessage = useMemo(() => error?.message ?? undefined, [error]);

  const screenProps = useMemo(() => {
    if (signedIn && !currentAccountId) return;

    return {
      canDelete: account?.accountUser?.canDelete,
      navLeft: <ResponsiveMenuAction />,
      signedIn,
      version,
    };
  }, [account, currentAccountId, signedIn]);

  const commonProps = useMemo(() => {
    return {
      navLeft: <ResponsiveMenuAction />,
      title: pageTitle,
    };
  }, [pageTitle]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen {...commonProps} error={errorMessage} canReloadPage business />;
  }

  if (signedIn && !currentAccountId) {
    return <NoAccountSelectedScreen {...commonProps} />;
  }

  if (!account) {
    if (loading) {
      return <LoadingScreen {...commonProps} business />;
    }
  }

  if (screenProps) {
    return <Screen {...screenProps} />;
  }

  throw new Error("Invalid state");
};

export default Settings;
