import { LAST_SWITCHED_ACCOUND_ID_KEY } from "constants/storage";

import { useLocalStorage } from "hooks";
import { useCallback } from "react";

const useLastSwitchedAccountId = () => {
  const [lastSwitchedAccountId, set] = useLocalStorage(LAST_SWITCHED_ACCOUND_ID_KEY);

  const setLastSwitchedAccountId = useCallback(
    (accountId: string | undefined) => {
      set(accountId);
    },
    [set]
  );

  return {
    lastSwitchedAccountId,
    setLastSwitchedAccountId,
  };
};

export default useLastSwitchedAccountId;
