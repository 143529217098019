import { useEffect, useReducer } from "react";

interface State<T> {
  data: T | null;
  error: string | null;
  pending: boolean;
}

type Action<T> =
  | {
      type: "START";
    }
  | {
      data: T;
      type: "SUCCESS";
    }
  | {
      error: string;
      type: "ERROR";
    };

const initialState = {
  data: null,
  error: null,
  pending: false,
};

function reducer<T>(prevState: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case "START": {
      return { ...prevState, pending: true };
    }
    case "SUCCESS": {
      return { ...prevState, data: action.data, error: null, pending: false };
    }
    case "ERROR":
    default: {
      return { ...prevState, error: action.error, pending: false };
    }
  }
}

/**
 * Asynchronous version of `useMemo`.
 */
export function useAsyncMemo<T>(callback: () => Promise<T>, deps: unknown[]): [T | null, State<T>] {
  const [state, dispatch] = useReducer<(prevState: State<T>, action: Action<T>) => State<T>>(reducer, initialState);

  useEffect(
    () => {
      let canceled = false;

      async function doWork() {
        dispatch({ type: "START" });

        try {
          const data = await callback();
          if (!canceled) {
            dispatch({ data, type: "SUCCESS" });
          }
        } catch (error) {
          if (!canceled) {
            if (error instanceof Error) {
              dispatch({ error: error.message, type: "ERROR" });
            } else {
              dispatch({ error: "Unknown error", type: "ERROR" });
            }
          }
        }
      }

      doWork();

      return () => {
        canceled = true;
      };
    },
    // We don't add `dispatch` and `callback` to deps to let the caller manage them himself.
    // This is _ok_ as `dispatch` will never change and the latest `callback` will only be used if `deps` changes,
    // which is the behaviour of `useMemo`.
    deps // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [state.data, state];
}
