import classNames from "classnames";
import { StationPublicStatus, StationStatus } from "operations";
import { FunctionComponent, ReactNode, SVGProps } from "react";

interface ItemProps {
  children?: never;
  className?: string;
  gap?: "medium" | "small";
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label?: string | ReactNode;
  size?: "medium" | "small";
  status?: StationPublicStatus | StationStatus | undefined;
}

const StationSummaryItem: FunctionComponent<ItemProps> = ({
  gap = "small",
  icon: Icon,
  label,
  size = "medium",
  status,
  className,
}) => {
  return (
    <div
      className={classNames("flex flex-row items-center", {
        "gap-1": gap === "small",
        "gap-2": gap === "medium",
        "text-green-600": status === StationPublicStatus.Available || status === StationStatus.Opened,
        "text-neutral-600": status === StationPublicStatus.ComingSoon,
        "text-orange-700":
          status === StationPublicStatus.Unavailable ||
          status === StationStatus.ClosingSoon ||
          status === StationStatus.OpeningSoon,
        "text-red-600": status === StationPublicStatus.OutOfService || status === StationStatus.Closed,
      })}
    >
      <Icon className={classNames("h-6 w-6", { "text-neutral-700": !status })} />
      <div
        className={classNames(
          "font-normal",
          {
            "text-business-sm": size === "small",
            "text-medium": size === "medium",
            "text-neutral-600": !status,
          },
          className
        )}
      >
        {label ?? ""}
      </div>
    </div>
  );
};

export default StationSummaryItem;
