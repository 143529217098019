import { CheckboxField } from "@webapps/shared/components";
import { FunctionComponent, MouseEventHandler, useCallback } from "react";
import { Trans } from "react-i18next";
import { validateGdpr } from "utils";

type Props = {
  onClick: () => void;
};

const CgvConsentField: FunctionComponent<Props> = ({ onClick }) => {
  const handleClick: MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      event.preventDefault();
      onClick();
    },
    [onClick]
  );

  return (
    <CheckboxField
      checkboxProps={{ className: "border-neutral-200 rounded" }}
      name="cgvAccepted"
      label={
        <div>
          <Trans
            i18nKey="registerPage.invoiceInfoScreen.cgv.label"
            components={{
              1: <span className="cursor-pointer underline" onClick={handleClick} />,
            }}
          />
        </div>
      }
      validate={validateGdpr}
    />
  );
};

export default CgvConsentField;
