import { StationPublicStatus } from "operations";
import { FunctionComponent } from "react";
import { MarkerProps } from "react-map-gl";

import MapPin from "../../molecules/MapPin";
import { Mapbox } from "../Map";
import { getStationPointZIndex } from "../Map/utils";

export type Location = {
  id: string;
  latitude: number;
  longitude: number;
  publicStatus: StationPublicStatus;
};

export type StationPointsProps = {
  Marker: FunctionComponent<MarkerProps>;
  locations: Location[];
  onMarkerClick?: (id: string, map?: Mapbox) => void;
  selectedId?: string | undefined;
};

const StationPoints = ({ Marker, locations, selectedId, onMarkerClick }: StationPointsProps) => {
  const renderMarkers = () =>
    locations.map(({ id, latitude, longitude, publicStatus }) => {
      const selected = selectedId === id;
      const zIndex = getStationPointZIndex(selected, publicStatus);

      return (
        <Marker
          key={id}
          longitude={longitude}
          latitude={latitude}
          anchor="bottom"
          onClick={
            onMarkerClick
              ? (event) => {
                  // Stop propagation to avoid twice click on map and marker at the same time
                  event.originalEvent.stopPropagation();
                  onMarkerClick(id);
                }
              : undefined
          }
          style={{ zIndex }}
        >
          <MapPin selected={selected} status={publicStatus} />
        </Marker>
      );
    });

  return <>{renderMarkers()}</>;
};

export default StationPoints;
