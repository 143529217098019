import { FormikState, useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import isEqual from "react-fast-compare";

const FormikPersist = <T,>({ name }: { name: string }) => {
  const { values, setValues } = useFormikContext();
  const prefValuesRef = useRef<FormikState<T>>();

  const onSave = (values: FormikState<T>) => {
    window.localStorage.setItem(name, JSON.stringify(values));
  };

  useEffect(() => {
    const savedForm = window.localStorage.getItem(name);

    if (savedForm) {
      const parsedForm = JSON.parse(savedForm);

      prefValuesRef.current = parsedForm;
      setValues(parsedForm);
    }
  }, [name, setValues]);

  useEffect(() => {
    if (!isEqual(prefValuesRef.current, values)) {
      onSave({ ...(values as FormikState<T>), errors: {} });
    }
  });

  useEffect(() => {
    prefValuesRef.current = values as FormikState<T>;
  });

  return null;
};

export default FormikPersist;
