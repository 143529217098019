import { useMediaQuery } from "@react-hook/media-query";
import { BackAction, Action, ActionProps } from "@webapps/shared/components";
import classNames from "classnames";
import CloseIcon from "images/icons/ri/close.svg?react";
import MenuIcon from "images/icons/ri/menu.svg?react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useMobileMenu } from "../../../hooks/useMobileMenu";

type Props = {
  onClick: () => void;
};

export const ResponsiveBackAction: FunctionComponent<Props> = ({ onClick }: Props) => {
  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");
  return (
    <BackAction
      onClick={onClick}
      inheritedTextColor={!mdScreen}
      withBackground={mdScreen}
      className={classNames({
        "-m-2": !mdScreen,
      })}
    />
  );
};

export const ResponsiveMenuAction: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const openMenu = useMobileMenu((state) => state.openMenu);
  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");
  return mdScreen ? null : (
    <Action
      icon={MenuIcon}
      title={t("action.menu", { ns: "common" })}
      inheritedTextColor
      withBackground={false}
      onClick={openMenu}
      className="-m-2"
    />
  );
};

export const ResponsiveCloseAction: FunctionComponent<Omit<ActionProps, "icon" | "title">> = ({
  className,
  ...props
}) => {
  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");
  const { t } = useTranslation();
  return (
    <Action
      icon={CloseIcon}
      title={t("action.close", { ns: "common" })}
      {...props}
      inheritedTextColor={!mdScreen}
      withBackground={mdScreen}
      className={classNames(className, {
        "-m-2": !mdScreen,
      })}
    />
  );
};
