import { t } from "i18next";
import { Country, CountryObject } from "operations";

export type CountryFormObject = {
  callingCode: string;
  label: string;
  value: string;
};

export const getCountryFormObject = (
  withPhone: boolean,
  countries?: CountryObject[] | Pick<CountryObject, "id" | "key" | "name">[]
): CountryFormObject[] => {
  return countries
    ? countries.map((country) => {
        const label =
          withPhone && "callingCode" in country ? `${country.name} (+${country.callingCode})` : country.name;
        return {
          callingCode: withPhone && "callingCode" in country ? country.callingCode : "",
          label,
          value: country.key,
        };
      })
    : [
        {
          callingCode: "+33",
          label: withPhone
            ? t("form.defaultCountryLabel.phone", { ns: "common" })
            : t("form.defaultCountryLabel.other", { ns: "common" }),
          value: "FR",
        },
      ];
};

export const getCallingCodeFromCountryCode = (
  country?: Country,
  countries?: Array<Partial<CountryObject> | CountryFormObject>
) => {
  const selectedCountryObject = countries?.find((c) =>
    "key" in c ? c.key === country : "value" in c ? c.value === country : false
  );
  return selectedCountryObject ? `+${selectedCountryObject.callingCode}` : "+33";
};
