import { useReducer, useEffect, useRef, useCallback } from "react";

interface State {
  remainingTime: number;
  status: "paused" | "running" | "stopped";
  timerStartedAt: number;
}

type Action =
  | {
      type: "PAUSE";
    }
  | {
      totalDurationInMilliseconds: number;
      type: "START";
    }
  | {
      type: "STOP";
    }
  | {
      type: "RESUME";
    };

function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case "PAUSE": {
      return {
        ...prevState,
        remainingTime: prevState.remainingTime - (Date.now() - prevState.timerStartedAt),
        status: "paused",
      };
    }
    case "START": {
      return {
        ...prevState,
        remainingTime: action.totalDurationInMilliseconds,
        status: "running",
        timerStartedAt: Date.now(),
      };
    }
    case "STOP": {
      return { ...prevState, status: "stopped" };
    }
    case "RESUME": {
      return {
        ...prevState,
        status: "running",
        timerStartedAt: Date.now(),
      };
    }
    default: {
      return prevState;
    }
  }
}

interface TimerProps {
  onElapsed: () => void;
  totalDurationInMilliseconds: number;
}

interface ReturnValue {
  isRunning: boolean;
  pauseTimer: () => void;
  remainingTime: number;
  restartTimer: () => void;
  resumeTimer: () => void;
  startTimer: () => void;
  stopTimer: () => void;
}

export const useTimer = ({ totalDurationInMilliseconds, onElapsed }: TimerProps): ReturnValue => {
  const timeoutReference = useRef<NodeJS.Timeout>();

  const [state, dispatch] = useReducer<(prevState: State, action: Action) => State>(reducer, {
    remainingTime: totalDurationInMilliseconds,
    status: "stopped",
    timerStartedAt: 0,
  });

  useEffect(() => {
    if (state.status === "running" && !timeoutReference.current) {
      timeoutReference.current = setTimeout(() => {
        dispatch({
          type: "STOP",
        });
        onElapsed();
      }, state.remainingTime);
    }

    if (["paused", "stopped"].includes(state.status) && timeoutReference.current) {
      clearTimeout(timeoutReference.current);
      timeoutReference.current = undefined;
    }
  }, [onElapsed, state]);

  useEffect(() => {
    return () => {
      if (timeoutReference.current) {
        clearTimeout(timeoutReference.current);
      }
    };
  }, []);

  const pauseTimer = useCallback(() => {
    dispatch({
      type: "PAUSE",
    });
  }, []);

  const restartTimer = useCallback(() => {
    dispatch({
      type: "STOP",
    });
    dispatch({
      totalDurationInMilliseconds,
      type: "START",
    });
  }, [totalDurationInMilliseconds]);

  const resumeTimer = useCallback(() => {
    dispatch({
      type: "RESUME",
    });
  }, []);

  const startTimer = useCallback(() => {
    dispatch({
      totalDurationInMilliseconds,
      type: "START",
    });
  }, [totalDurationInMilliseconds]);

  const stopTimer = useCallback(() => {
    dispatch({
      type: "STOP",
    });
  }, []);

  return {
    isRunning: state.status === "running" ? true : false,
    pauseTimer,
    remainingTime: state.remainingTime,
    restartTimer,
    resumeTimer,
    startTimer,
    stopTimer,
  };
};
