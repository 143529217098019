import { Button, InputField } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { Form, Formik } from "formik";
import { useInputFocus } from "hooks";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import { ComponentProps, FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { required } from "utils";

interface FormValues {
  firstname: string;
  lastname: string | undefined;
}

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  actionLabel?: string | undefined;
  actionNext?: boolean | undefined;
  children?: never;
  error?: string | undefined;
  focusFirstField?: boolean | undefined;
  hideLastname?: boolean | undefined;
  initialValues?: FormValues | undefined;
  isMandatoryLastname?: boolean | undefined;
  onSubmit: (firstname: string, lastname?: string) => Promise<void>;
  showMandatoryFieldIndication?: boolean | undefined;
  subTitle?: string | undefined;
  title?: string | undefined;
  trackPage?: () => void;
}

const UserNamesScreen: FunctionComponent<Props> = ({
  actionLabel,
  actionNext = false,
  error,
  focusFirstField = true,
  hideLastname = true,
  initialValues,
  isMandatoryLastname = true,
  navLeft,
  onSubmit,
  showMandatoryFieldIndication = false,
  subTitle,
  title,
  trackPage,
}) => {
  const { t } = useTranslation();
  const focusInputRef = useInputFocus({ enabled: focusFirstField });

  const submit = async (values: FormValues) => {
    await onSubmit(values.firstname, values.lastname);
  };

  useEffect(() => {
    trackPage?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{
        firstname: initialValues?.firstname ?? "",
        lastname: initialValues?.lastname ?? "",
      }}
      onSubmit={submit}
    >
      {({ errors, handleSubmit, isSubmitting, isValidating, touched }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;

        const bottom = (
          <>
            {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

            {error && !hasError ? <div className="text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full"
              disabled={isValidating || isSubmitting || hasError}
              label={actionLabel ?? t("userNamesScreen.cta.default")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              next={actionNext}
              size="large"
              type="submit"
            />
          </>
        );

        const firstNameLabel = showMandatoryFieldIndication
          ? `${t("userNamesScreen.firstNameLabel")} *`
          : t("userNamesScreen.firstNameLabel");

        const lastnameLabel =
          showMandatoryFieldIndication && isMandatoryLastname
            ? `${t("userNamesScreen.lastNameLabel")} *`
            : t("userNamesScreen.lastNameLabel");

        return (
          <Page
            bottom={bottom}
            navLeft={navLeft}
            subTitle={subTitle}
            title={title ?? t("userNamesScreen.pageTitle")}
            business
          >
            <Form className="grid grid-cols-1 gap-8 pt-2 md:grid-cols-2">
              <InputField
                name="firstname"
                label={firstNameLabel}
                validate={required}
                inputProps={{
                  autoComplete: "firstname",
                  enterKeyHint: hideLastname ? "send" : "next",
                  initialValue: "",
                }}
                ref={focusInputRef}
              />

              {!hideLastname && (
                <InputField
                  name="lastname"
                  label={lastnameLabel}
                  validate={isMandatoryLastname ? required : undefined}
                  inputProps={{ autoComplete: "lastname", enterKeyHint: "send", initialValue: "" }}
                />
              )}

              {showMandatoryFieldIndication && (
                <div className="text-sm">{t("form.mandatoryField", { ns: "common" })}</div>
              )}
            </Form>
          </Page>
        );
      }}
    </Formik>
  );
};

export default UserNamesScreen;
