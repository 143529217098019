import { PAGE_VIEWED } from "@webapps/shared/libs/src/mixpanel";
import { List, Page } from "components";
import { MixpanelProperties, useMixpanel } from "hooks";
import ChatIcon from "images/icons/ri/chat-message.svg?react";
import ArrowRightIcon from "images/icons/ri/chevron-right.svg?react";
import FaqIcon from "images/icons/ri/help-question-circle.svg?react";
import MailIcon from "images/icons/ri/mail.svg?react";
import PhoneIcon from "images/icons/ri/phone.svg?react";
import WhatsappIcon from "images/icons/ri/whatsapp.svg?react";
import { SupportMethod } from "operations";
import { ComponentProps, FunctionComponent, SVGProps, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getHrefAndTarget } from "utils";

export interface SupportSectionItem {
  children: Array<SupportMethod>;
  label: string;
}

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  children?: never;
  mixpanelDynamicProps?: MixpanelProperties | undefined;
  pageProps?: ComponentProps<typeof Page>;
  supportSectionItems?: Array<SupportSectionItem>;
}

const SupportScreen: FunctionComponent<Props> = ({
  supportSectionItems = [],
  mixpanelDynamicProps,
  navLeft,
  pageProps,
}) => {
  const { t } = useTranslation();
  const { mixpanel, trackPageView } = useMixpanel();

  useEffect(() => {
    if (!mixpanel) return;

    trackPageView(PAGE_VIEWED.SUPPORT_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  return (
    <Page navLeft={navLeft} title={t("supportPage.pageTitle", { ns: "common" })} testId="support" {...pageProps}>
      <div className="flex flex-col gap-6 pt-2">
        {supportSectionItems.map((supportSection, index) => {
          return <SupportSection key={`supportSection_${index}`} supportSection={supportSection} />;
        })}
      </div>
    </Page>
  );
};

interface SupportSectionProps {
  children?: never;
  supportSection: SupportSectionItem;
}

const SupportSection: FunctionComponent<SupportSectionProps> = ({ supportSection }) => {
  const { children, label } = supportSection;
  const listItems = useMemo(() => {
    return children.map(({ deeplink, type, ...listItemsProps }) => {
      let leadingIcon: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
      if (type === "chat") {
        leadingIcon = ChatIcon;
      } else if (type === "faq") {
        leadingIcon = FaqIcon;
      } else if (type === "mail") {
        leadingIcon = MailIcon;
      } else if (type === "phone") {
        leadingIcon = PhoneIcon;
      } else if (type === "whatsapp") {
        leadingIcon = WhatsappIcon;
      }

      const { href, target } = getHrefAndTarget(deeplink);

      return {
        href,
        leadingIcon,
        target,
        trailingIcon: ArrowRightIcon,
        ...listItemsProps,
      };
    });
  }, [children]);

  return <List baseKey="supportMethod" listItems={listItems} sectionHeading={label} />;
};

export default SupportScreen;
