import { IconColor } from "components";
import { ProAccountLabelType } from "operations";
import { useTranslation } from "react-i18next";

type StepKeyWithHint = "2" | "3";
type StepKey = "1" | "2" | "3" | "4";
const STEP_WITH_HINT = ["2", "3"];

type FleetPerkKey = "1" | "2" | "3" | "4";
type IndpendantPerkKey = "1" | "2" | "3";

export const useSteps = (proTypeLabel: ProAccountLabelType | undefined) => {
  const { t } = useTranslation();
  const proTypeKey = proTypeLabel as "fleet" | "independant";
  if (!proTypeLabel) {
    return [];
  }

  return [...Array(4).keys()].map((x) => ({
    hint: STEP_WITH_HINT.includes((x + 1).toString())
      ? t(`registerPage.onboardingScreen.${proTypeKey}.steps.${(x + 1).toString() as StepKeyWithHint}.hint`)
      : undefined,
    text: t(`registerPage.onboardingScreen.${proTypeKey}.steps.${(x + 1).toString() as StepKey}.text`),
  }));
};

export const usePerks = (proTypeLabel: ProAccountLabelType | undefined) => {
  const { t } = useTranslation();
  const proTypeKey = proTypeLabel as "fleet" | "independant";
  if (!proTypeLabel) {
    return [];
  }

  return proTypeKey === "fleet"
    ? [...Array(4).keys()].map((x) => ({
        iconColor: "dark" as IconColor,
        text: t(`registerPage.confirmationScreen.fleet.perks.${(x + 1).toString() as FleetPerkKey}`),
      }))
    : [...Array(3).keys()].map((x) => ({
        iconColor: "dark" as IconColor,
        text: t(`registerPage.confirmationScreen.independant.perks.${(x + 1).toString() as IndpendantPerkKey}`),
      }));
};

export const useNoProTypeInfos = () => {
  const { t } = useTranslation("business", { keyPrefix: "registerPage.onboardingScreen.noCompanyType" });

  return Object.values(ProAccountLabelType).map((proTypeLabel) => {
    const perks = Object.keys(t(`${proTypeLabel}.perks`, { returnObjects: true })).map((perkIndex) => ({
      text: t(`${proTypeLabel}.perks.${perkIndex}`),
    }));

    return {
      callToAction: t(`${proTypeLabel}.callToAction`),
      perks,
      proTypeLabel,
      title: t(`${proTypeLabel}.title`),
    };
  });
};
