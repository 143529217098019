import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { ErrorScreen } from "components";
import compact from "lodash/compact";
import { SortDirectionEnum, useChargesPageLazyQuery } from "operations";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ResponsiveMenuAction } from "../../components/molecules/BusinessAction";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";

import { Sorting, SortingKey } from "./components/SortBySelect";
import Screen from "./screens/Screen";
import { TableData, sanitizeData, sortingVariablesByState } from "./utils";

export interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

export type PaginationDirection = "left" | "right";

export const PAGE_LENGTH = 15;

const ChargesPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const pageTitle = t("chargesPage.pageTitle");
  const currentAccountId = useUser((state) => state.currentAccountId);
  const [sorting, setSorting] = useState<Sorting>({ desc: true, value: "date" });
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: PAGE_LENGTH });
  const [tableData, setTableData] = useState<TableData[] | null>(null);
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const [getCharges, { loading, error, data }] = useChargesPageLazyQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const charges = data?.me?.account?.charges?.nodes ? compact(data.me.account.charges.nodes) : [];
      setTableData(sanitizeData(charges));
    },
  });

  const chargesCount = data?.me?.account?.chargesCount ?? 0;

  const onPaginationClick = async (direction: PaginationDirection) => {
    const hasPreviousPage = data?.me?.account?.charges.pageInfo.hasPreviousPage;
    const hasNextPage = data?.me?.account?.charges.pageInfo.hasNextPage;
    if ((direction === "left" && !hasPreviousPage) || (direction === "right" && !hasNextPage)) {
      return;
    }
    const before = data?.me?.account?.charges.pageInfo.startCursor ?? undefined;
    const after = data?.me?.account?.charges.pageInfo.endCursor ?? undefined;
    const sortingVariables = {
      sort: {
        sortBy: sortingVariablesByState[sorting.value],
        sortDirection: sorting.desc ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
      },
    };
    const paginationVariables =
      direction === "left"
        ? { before, last: PAGE_LENGTH }
        : ({ after, first: PAGE_LENGTH } as Partial<{ after: undefined; before: string }>);
    await getCharges({
      variables: { ...sortingVariables, ...paginationVariables, accountId: currentAccountId ?? "none" },
    });
    trackButtonClicked(BUTTON_CLICKED.CHARGES_PAGINATION, {
      ...mixpanelDynamicProps,
      pagination_direction: direction,
    });
    setPagination((prev) => ({ ...prev, pageIndex: direction === "left" ? prev.pageIndex - 1 : prev.pageIndex + 1 }));
  };

  const onSortClick = async () => {
    const { value, desc } = sorting;
    await getCharges({
      variables: {
        accountId: currentAccountId ?? "none",
        first: PAGE_LENGTH,
        sort: {
          sortBy: sortingVariablesByState[value],
          sortDirection: desc ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
        },
      },
    });
    trackButtonClicked(BUTTON_CLICKED.SORT_CHARGES, {
      ...mixpanelDynamicProps,
      sorting_key: value,
    });
    setPagination({ pageIndex: 0, pageSize: PAGE_LENGTH });
  };

  const onSortItemClick = (value: SortingKey) => {
    setSorting((prev) => ({ desc: !prev.desc, value }));
  };

  useEffect(() => {
    const setChargeData = async () => {
      if (currentAccountId) {
        await getCharges({
          // default order options are date and desc on server
          variables: {
            accountId: currentAccountId ?? "none",
            first: PAGE_LENGTH,
          },
        });
      }
      if (pagination.pageIndex !== 0) {
        setPagination({ pageIndex: 0, pageSize: PAGE_LENGTH });
      }
    };
    setChargeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Props
   */

  const errorMessage = error?.message ?? undefined;

  const commonProps = useMemo(() => {
    return {
      navLeft: <ResponsiveMenuAction />,
      title: pageTitle,
    };
  }, [pageTitle]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen {...commonProps} error={errorMessage} canReloadPage business />;
  }

  if (!currentAccountId) {
    return <NoAccountSelectedScreen {...commonProps} />;
  }

  return (
    <Screen
      {...commonProps}
      onPaginationClick={onPaginationClick}
      chargesCount={chargesCount}
      onSortClick={onSortClick}
      onSortItemClick={onSortItemClick}
      data={tableData ?? undefined}
      sorting={sorting}
      pagination={pagination}
      loading={loading}
    />
  );
};

export default ChargesPage;
