import { Checkbox, RadioButton } from "@webapps/shared/components";
import classNames from "classnames";
import ArrowUpIcon from "images/icons/ri/arrow-up.svg?react";

interface Props<T> {
  desc?: boolean;
  isLast?: boolean;
  kind: "checkbox" | "radio" | "sort";
  label: string;
  onClick?: (value: T) => void;
  selected: boolean;
  value: T;
}

const FilterSelectItem = <T,>({ selected, onClick, label, value, kind, desc, isLast }: Props<T>) => (
  <div
    className={classNames("flex w-full cursor-pointer items-center justify-around py-1", {
      "border-b border-b-neutral-100": !isLast && kind === "sort",
      "gap-2": kind !== "sort",
    })}
    onClick={() => onClick?.(value)}
  >
    {kind === "checkbox" && <Checkbox checked={selected} size="small" />}
    {kind === "radio" && <RadioButton checked={selected} size="medium" />}
    <div className="flex-1 text-sm font-normal">{label}</div>
    {kind === "sort" && selected && (
      <ArrowUpIcon
        className={classNames("text-primary-default h-4", {
          "rotate-180": desc && selected,
        })}
      />
    )}
  </div>
);

export default FilterSelectItem;
