import { Button } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { ComponentProps, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  children?: never;
  onSignOutClick: () => Promise<void>;
  signOutError?: string | undefined;
  signOutLoading?: boolean;
}

const SignOutScreen: FunctionComponent<Props> = ({ navLeft, onSignOutClick, signOutError, signOutLoading = false }) => {
  const { t } = useTranslation();
  const bottom = (
    <>
      {signOutError ? <div className="text-base text-red-600">{signOutError}</div> : null}

      <Button
        className="w-full"
        disabled={signOutLoading}
        label={t("signOutPage.cta")}
        loading={signOutLoading}
        onClick={onSignOutClick}
        size="large"
        type="button"
      />
    </>
  );
  return (
    <Page bottom={bottom} navLeft={navLeft} title={t("signOutPage.pageTitle")} business>
      <div className="font-normal">{t("signOutPage.infoOne")}</div>
      <div className="font-normal">{t("signOutPage.infoTwo")}</div>
    </Page>
  );
};

export default SignOutScreen;
