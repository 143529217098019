import { MapLocation } from "../components/organisms/Map";

export function calcDistance(from: MapLocation, to: MapLocation) {
  const R = 6371000;
  const dLat = deg2rad(to[1] - from[1]);
  const dLon = deg2rad(to[0] - from[0]);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(from[1])) * Math.cos(deg2rad(to[1])) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

export const getTimePortion = (duration: number) => {
  let seconds = duration;
  let minutes = Math.floor(seconds / 60);
  if (duration >= 3600) {
    seconds -= minutes * 60;
    const hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
    return { hours, minutes, seconds };
  }
  seconds -= minutes * 60;
  return { hours: 0, minutes, seconds };
};
