import { BUSINESS_TOKEN_STORAGE_KEY, BUSINESS_APOLLO_STORAGE_KEY } from "constants/storage";

import { ApolloClient, ApolloLink, Operation, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createConsumer, logger } from "@rails/actioncable";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { useApolloErrorLinks, useGraphqlUris, useLocalStorage } from "hooks";
import typePolicies from "operations/src/type-policies";
import { useCallback, useEffect, useMemo } from "react";
import { version, getCurrentLocale } from "utils";

if (import.meta.env.DEV) {
  logger.enabled = true;
}

const useClient = () => {
  const isStorybook = import.meta.env.STORYBOOK;
  const cache = useMemo(
    () =>
      new InMemoryCache({
        typePolicies,
      }),
    []
  );
  const apolloErrorLinks = useApolloErrorLinks(BUSINESS_TOKEN_STORAGE_KEY);
  const { cableUri, graphqlUri } = useGraphqlUris(BUSINESS_TOKEN_STORAGE_KEY);
  const cable = createConsumer(cableUri);
  const [sessionToken, setSessionToken] = useLocalStorage(BUSINESS_TOKEN_STORAGE_KEY);
  const middleware = new ApolloLink((operation, forward) => {
    const locale = getCurrentLocale();
    const baseHeaders = { "X-Electra-App-Name": "business", "X-Electra-Locale": locale };
    const middlewareHeaders = sessionToken ? { ...baseHeaders, "X-Session-Token": sessionToken } : baseHeaders;

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...middlewareHeaders,
      },
    }));

    return forward(operation);
  });

  const cableLink = new ActionCableLink({
    cable,
    channelName: "ApiChannel",
  });

  const storage = useMemo(() => new LocalStorageWrapper(window.localStorage), []);

  const persistApolloCache = useCallback(
    async () =>
      await persistCache({
        cache,
        debug: import.meta.env.DEV,
        key: BUSINESS_APOLLO_STORAGE_KEY,
        storage,
        trigger: "write",
      }),
    [storage, cache]
  );

  useEffect(() => {
    persistApolloCache();
  });

  const hasSubscriptionOperation: (op: Operation) => boolean = ({ query: { definitions } }) => {
    return definitions.some((definition) => {
      if (definition.kind === "OperationDefinition") {
        return definition.operation === "subscription";
      }

      return false;
    });
  };

  const uploadLink = createUploadLink({
    headers: {
      "X-Electra-App-Platform": "web",
      "X-Electra-App-Version": version,
    },
    uri: graphqlUri,
  }) as unknown as ApolloLink;

  const connectionLink = isStorybook ? uploadLink : ApolloLink.split(hasSubscriptionOperation, cableLink, uploadLink);

  const signOutLink = onError(({ networkError }) => {
    if (networkError && "response" in networkError && networkError.response.status === 401) {
      setSessionToken(undefined);
      client.clearStore();
    }
  });

  const client = new ApolloClient({
    cache,
    link: from([...apolloErrorLinks, signOutLink, middleware, connectionLink, uploadLink]),
  });

  return client;
};

export default useClient;
