import { List } from "@webapps/shared/components";
import { ReactNode } from "react";

import { InvitationItem, LabelledItem, UserItem } from "../screens/Screen";

type Props = {
  children?: ReactNode;
  items: Array<UserItem | InvitationItem | LabelledItem>;
  sectionHeading?: ReactNode;
  title: string;
};

const UserSection = ({ items, title, sectionHeading }: Props) => (
  <List baseKey="user" listItems={items} sectionHeading={title} sectionTrailing={sectionHeading} />
);

export default UserSection;
