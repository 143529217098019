import classNames from "classnames";
import ClockIcon from "images/icons/ri/clock.svg?react";
import { StationStatus } from "operations";
import { FunctionComponent, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { castDateOrString, isAtLeastOneEntireDayFromNow } from "utils";

import StationSummaryItem from "../StationSummaryItem";

interface ItemProps {
  children?: never;
  gap?: "medium" | "small";
  nextClosing?: string | null | undefined;
  nextHourClassName?: string;
  nextOpening?: string | null | undefined;
  size?: "medium" | "small";
  status: StationStatus;
}

const StationStatusSummaryItem: FunctionComponent<ItemProps> = ({
  gap = "small",
  nextClosing,
  nextHourClassName = "text-neutral-600",
  nextOpening,
  size = "medium",
  status,
}) => {
  const { t } = useTranslation();

  const stationStatusLabel = useMemo(() => {
    let dateLabel: ReactNode;
    let nextClosingLabel: ReactNode;
    let nextOpeningLabel: ReactNode;
    if (nextClosing) {
      const nextClosingTime = t("format.time", {
        formatParams: {
          val: { hour: "2-digit", minute: "numeric" },
        },
        ns: "common",
        val: castDateOrString(nextClosing),
      });
      nextClosingLabel = (
        <span className={nextHourClassName}>
          {" "}
          -{" "}
          {t("stationSummary.closingAt", {
            time: nextClosingTime,
          })}
        </span>
      );
    }
    if (nextOpening) {
      if (isAtLeastOneEntireDayFromNow(nextOpening)) {
        const nextOpeningDay = t("format.time", {
          formatParams: {
            val: { weekday: "long" },
          },
          ns: "common",
          val: castDateOrString(nextOpening),
        });
        nextOpeningLabel = (
          <span className={nextHourClassName}>
            {" "}
            -{" "}
            {t("stationSummary.opening.day", {
              day: nextOpeningDay,
            })}
          </span>
        );
      } else {
        const nextOpeningTime = t("format.time", {
          formatParams: {
            val: { hour: "2-digit", minute: "numeric" },
          },
          ns: "common",
          val: castDateOrString(nextOpening),
        });
        nextOpeningLabel = (
          <span className={nextHourClassName}>
            {" "}
            -{" "}
            {t("stationSummary.opening.at", {
              time: nextOpeningTime,
            })}
          </span>
        );
      }
    }

    let statusLabel: ReactNode;
    if (status === StationStatus.ClosingSoon) {
      statusLabel = t("stationSummary.status.closingSoon");
      dateLabel = nextClosingLabel;
    } else if (status === StationStatus.OpeningSoon) {
      statusLabel = t("stationSummary.status.openingSoon");
      dateLabel = nextOpeningLabel;
    } else if (status === StationStatus.Closed) {
      statusLabel = t("stationSummary.status.closed");
      dateLabel = nextOpeningLabel;
    } else if (status === StationStatus.Opened) {
      statusLabel = t("stationSummary.status.opened");
      if (nextClosingLabel) {
        dateLabel = nextClosingLabel;
      } else {
        dateLabel = <span className={nextHourClassName}> - {t("stationSummary.allDay")}</span>;
      }
    }

    if (statusLabel) {
      return (
        <div
          className={classNames("", {
            "text-business-sm": size === "small",
            "text-medium": size === "medium",
          })}
        >
          {statusLabel}
          {dateLabel}
        </div>
      );
    }
  }, [nextClosing, nextHourClassName, nextOpening, size, status, t]);

  return <StationSummaryItem icon={ClockIcon} gap={gap} label={stationStatusLabel} status={status} size={size} />;
};

export default StationStatusSummaryItem;
