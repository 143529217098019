import { Map as Mapbox, PaddingOptions } from "mapbox-gl";
import { StationPublicStatus, StationsPageStationSheet_StationFragment } from "operations";
import { getCurrentLanguage } from "utils";

import { Location } from "../StationPoints";

import { DEFAULT_FIT_BOUNDS_OPTIONS } from "./constants";

export const customizeMap = (map: Mapbox, paddingOptions: PaddingOptions = DEFAULT_FIT_BOUNDS_OPTIONS.padding) => {
  if (!map) {
    return;
  }

  map.setPadding(paddingOptions);
  map.touchZoomRotate.disableRotation();
  changeMapLanguage(map);
};

export const changeMapLanguage = (map: Mapbox) => {
  const currentLanguage = getCurrentLanguage();
  (map.getStyle().layers ?? []).forEach((layer) => {
    if (layer.id.endsWith("-label")) {
      map.setLayoutProperty(layer.id, "text-field", ["coalesce", ["get", `name_${currentLanguage}`], ["get", "name"]]);
    }
  });
};

const isLocation = (item: Location | undefined): item is Location => {
  return !!item;
};

export const getFormattedLocations = (locations: StationsPageStationSheet_StationFragment[]) =>
  locations
    .map((location) => {
      if ("longitude" in location) {
        const { id, latitude, longitude } = location;
        return {
          id,
          latitude,
          longitude,
          publicStatus: "publicStatus" in location ? location.publicStatus : StationPublicStatus.Unknown,
        };
      } else if ("station" in location) {
        const {
          station: { latitude, longitude },
          available,
          id,
        } = location;
        return {
          id,
          latitude,
          longitude,
          publicStatus: available ? StationPublicStatus.Available : StationPublicStatus.Unavailable,
        };
      } else return undefined;
    })
    .filter(isLocation);

export const getStationPointZIndex = (selected: boolean, status: StationPublicStatus) => {
  let zIndex = 3;
  if (selected) {
    zIndex = 6;
  } else {
    switch (status) {
      case StationPublicStatus.Available:
        zIndex = 5;
        break;
      case StationPublicStatus.OutOfService:
      case StationPublicStatus.Unavailable:
        zIndex = 4;
        break;
      case StationPublicStatus.ComingSoon:
        zIndex = 3;
        break;
      default:
        zIndex = 2;
    }
  }
  return zIndex;
};
