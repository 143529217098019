import { useLocation } from "react-router-dom";

import { FORM_LOCALSTORAGE_KEY } from "../pages/UsersInvitePage/components/InvitationsForm";
import { hasInvitationsFormValues } from "../pages/UsersInvitePage/helpers";
import { FormValues } from "../pages/UsersInvitePage/screens/Screen";

import { useHasNoMembers } from "./useHasNoMembers";

type ModalType = "noInvite" | "savedInvites";

export const useBackInviteModalType = (): ModalType | undefined => {
  const location = useLocation();
  const isInvitationPage = location.pathname.includes("/users/invite");
  const hasNoMembers = useHasNoMembers();
  const savedForm = localStorage.getItem(FORM_LOCALSTORAGE_KEY);
  const parsedForm: FormValues | undefined = savedForm ? JSON.parse(savedForm) : undefined;
  const hasFormValues = parsedForm && parsedForm.invitations ? hasInvitationsFormValues(parsedForm.invitations) : false;
  if (isInvitationPage && hasNoMembers) {
    if (hasFormValues) {
      return "savedInvites";
    } else return "noInvite";
  }
  return undefined;
};
