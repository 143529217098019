import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";

interface FloatingButtonProps {
  children?: ReactNode;
  className?: string;
  onClick: () => void;
}

const FloatingButton: FunctionComponent<FloatingButtonProps> = ({
  children,
  className = "w-12 h-12 overflow-hidden rounded-lg tracking-tighter bg-white group shadow-booking-card justify-center cursor-pointer transition-opacity hover:opacity-80",
  onClick,
}) => {
  return (
    <div className={classNames(className)}>
      <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-white" onClick={onClick}>
        <span className="z-10">{children}</span>
      </div>
    </div>
  );
};

export default FloatingButton;
