import { DrawerLinkRoute, useMixpanel, useRoutedSelected } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import classNames from "classnames";
import { ComponentProps, FunctionComponent, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useBackInviteModal } from "../../../hooks/useBackInviteModal";
import { useBackInviteModalType } from "../../../hooks/useBackInviteModalType";
import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import { useMobileMenu } from "../../../hooks/useMobileMenu";

interface DrawerLinkProps extends Pick<ComponentProps<typeof Link>, "children" | "className"> {
  route: DrawerLinkRoute;
}

const DrawerLink: FunctionComponent<DrawerLinkProps> = ({ children, className, route }) => {
  const navigate = useNavigate();
  const closeMenu = useMobileMenu((state) => state.closeMenu);
  const selected = useRoutedSelected(route);
  const { setBackModalDisplayed, setTo } = useBackInviteModal();
  const modalType = useBackInviteModalType();
  const { trackPageView } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const handleClick = useCallback(() => {
    if (modalType) {
      setBackModalDisplayed(true);
      trackPageView(PAGE_VIEWED.ALERT_MODAL, { ...mixpanelDynamicProps, context: modalType });
      setTo(route.to);
      return;
    }
    navigate(route.to);
    closeMenu();
  }, [closeMenu, navigate, route.to, modalType, setBackModalDisplayed, setTo, mixpanelDynamicProps, trackPageView]);

  return (
    <div
      className={classNames(
        "hover:text-brand-500 text-base-light flex cursor-pointer items-center py-3 pl-3 pr-1 text-white",
        className,
        {
          "font-bold": selected,
          "font-normal": !selected,
        }
      )}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default DrawerLink;
