import { Alert, Banner, Button, IconColor, CheckedList } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED, ButtonName, PAGE_VIEWED } from "@webapps/shared/libs";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useCurrentUserName } from "../../../hooks/useCurrentUserName";
import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../../hooks/useUser";
import homeImg from "../../../images/sweet-home.png";

const WelcomeModal: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const welcomeParam = searchParams.get("welcome");
  const [showModal, setShowModal] = useState(!!welcomeParam);
  const ref = useRef<HTMLDivElement>(null);
  const i18nKey = "invitationsPage.welcomeModal";
  const perks = [...Array(4).keys()].map((x, i) => ({
    iconColor: i === 0 ? ("dark" as IconColor) : ("light" as IconColor),
    text: t(`${i18nKey}.perks.${(x + 1).toString()}`),
  }));
  const userName = useCurrentUserName();
  const { trackButtonClicked, trackPageView } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const currentAccountId = useUser((state) => state.currentAccountId);

  const handleCloseModal = useCallback(
    (button: ButtonName) => {
      trackButtonClicked(button, {
        ...mixpanelDynamicProps,
        account_id: currentAccountId,
      });
      setShowModal(false);
    },
    [currentAccountId, mixpanelDynamicProps, trackButtonClicked]
  );

  const trackPage = useCallback(() => {
    trackPageView(PAGE_VIEWED.WELCOME_MODAl, mixpanelDynamicProps);
  }, [mixpanelDynamicProps, trackPageView]);

  useEffect(() => {
    if (showModal) {
      trackPage?.();
    }
  }, [showModal, trackPage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowModal(false);
        handleCloseModal(BUTTON_CLICKED.CLOSE_WELCOME_MODAL);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return showModal ? (
    <Alert
      showCloseButton={true}
      centered={true}
      onClose={() => handleCloseModal(BUTTON_CLICKED.CLOSE_WELCOME_MODAL)}
      wide
    >
      <div className="flex flex-col items-center gap-4 text-neutral-700 md:gap-8">
        <img src={homeImg} className="inline h-[78px] w-[124px] self-center" alt="Sweet-home" />
        <div className="flex flex-col items-center gap-4">
          <div className="text-3xl font-light">{t(`${i18nKey}.welcome`, { name: userName })}</div>
          <p className="text-center text-xl font-light text-neutral-700">
            <Trans i18nKey={`${i18nKey}.subtitle`} components={{ 1: <span className="font-bold italic" /> }} />
          </p>
        </div>
        <Banner className="flex w-full flex-col gap-6" bgColor="bg-neutral-50" borderRadius="normal">
          <p className="text-xl-light hidden font-light md:block">{t(`${i18nKey}.perkTitle`)}</p>
          <CheckedList list={perks} reverse />
        </Banner>
        <div className="flex flex-col items-center gap-2">
          <Button
            label={t(`${i18nKey}.cta`)}
            onClick={() => handleCloseModal(BUTTON_CLICKED.CTA_WELCOME_MODAL)}
            type="button"
            kind="primary"
            fullWidth={false}
            size="medium"
          />
          <Button
            label={t(`${i18nKey}.later`)}
            onClick={() => handleCloseModal(BUTTON_CLICKED.SECONDARY_BUTTON_WELCOME_MODAL)}
            type="button"
            kind="tertiary"
            fullWidth={false}
            size="small"
          />
        </div>
      </div>
    </Alert>
  ) : null;
};

export default WelcomeModal;
