import pick from "lodash/pick";
import { StationsPageStationSheet_StationFragment } from "operations";

const stationKeys = [
  "address",
  "comingSoonLabel",
  "fastSpeedPricingDetail",
  "nextClosing",
  "nextOpening",
  "publicAvailablePower",
  "parkingTypeLabel",
  "availableSpotsCount",
  "publicStatus",
  "status",
];

export const getStationSummary = (station: StationsPageStationSheet_StationFragment) => pick(station, stationKeys);
