import { BUSINESS_TOKEN_STORAGE_KEY } from "constants/storage";

import { useLocalStorage } from "hooks";

const useSignedIn = () => {
  const [sessionToken] = useLocalStorage(BUSINESS_TOKEN_STORAGE_KEY);
  return !!sessionToken;
};

export default useSignedIn;
