import { InvitationFormData } from "./screens/Screen";

export const getCompleteInvitations = (invitations: InvitationFormData[]) =>
  invitations.filter((invitation) => Object.values(invitation).every((value) => value));

export const hasInvitationsIncompleteLines = (invitations: InvitationFormData[]) =>
  invitations.some(
    (invitation) =>
      Object.values(invitation).some((value) => !value) && Object.values(invitation).every((value) => !value)
  );

export const hasInvitationsFormValues = (invitations: InvitationFormData[]) =>
  invitations.some((invitation) => Object.entries(invitation).some(([key, value]) => value && key !== "countryCode"));
