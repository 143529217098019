import { BusinessCompanyIdentificationType } from "operations";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export interface FormValues {
  address?: string;
  city?: string;
  companyIdentificationNumber: string;
  companyIdentificationType: BusinessCompanyIdentificationType;
  countryId?: string;
  infosManuallyProvided?: boolean;
  name?: string;
  postCode?: string;
}

type Props = {
  address: string | undefined;
  name: string | undefined;
};

const SearchResult: FunctionComponent<Props> = ({ name, address }) => {
  const { t } = useTranslation();
  const hasCompany = name && address;
  const i18nKey = "registerPage.companyScreen.company";
  return hasCompany ? (
    <div className="mt-4">
      <h4 className="text-xl-light font-light text-neutral-900">{t(`${i18nKey}.title`)}</h4>
      <div className="mt-2 rounded bg-neutral-50 p-4">
        <div className="grid grid-flow-row auto-rows-max grid-cols-6 gap-4">
          <span className="text-sm font-bold text-neutral-700">{t(`${i18nKey}.name`)}</span>
          <div className="col-span-5 p-0 text-sm text-neutral-700">{name}</div>
          <span className="text-sm font-bold text-neutral-700">{t(`${i18nKey}.address`)}</span>
          <div className="col-span-5 p-0 text-sm text-neutral-700">{address}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default SearchResult;
