import { useMediaQuery } from "@react-hook/media-query";
import { LINK_TO_DOWNLOAD_APP } from "@webapps/constants";
import { Banner, Button, Step, CheckedList, CenteredPage } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import DownloadQrcode from "images/download-qrcode-without-corners.svg?react";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { useTranslation, Trans } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

import { useProType } from "../../../hooks/useProType";
import useSignOut from "../../../hooks/useSignOut";
import { useUser } from "../../../hooks/useUser";
import niceImg from "../../../images/nice.png";
import successDriverImg from "../../../images/success-driver.png";
import { usePerks } from "../hooks";

type Props = { trackPage: () => void };

const ConfirmationScreen: FunctionComponent<Props> = ({ trackPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mixpanel = useMixpanel((state) => state.mixpanel);
  const { userId, currentAccountUserId } = useUser();
  const { isFleet, proTypeLabel } = useProType();
  const perks = usePerks(proTypeLabel);
  const isStorybook = import.meta.env.STORYBOOK;
  const signOut = useSignOut();

  const smScreen = useMediaQuery("only screen and (min-width: 640px)");
  const showBusinessLogo = smScreen;

  const pushToGtm = useCallback(() => {
    if (window.dataLayer && userId && proTypeLabel && !isStorybook && currentAccountUserId) {
      window.dataLayer.push({
        account_type: proTypeLabel,
        account_user_id: currentAccountUserId,
        event: "register_complete",
        leanplum_user_id: userId,
      });
    }
  }, [userId, proTypeLabel, isStorybook, currentAccountUserId]);

  // if user is fleet, we want him to use the app
  // if user is independant on mobile, we redirect to store
  // otherwise, we show the download message
  const handleClick = useMemo(() => {
    return isFleet
      ? () => {
          navigate(
            {
              pathname: "/users/invite",
              search: createSearchParams({
                welcome: "true",
              }).toString(),
            },
            { replace: true }
          );
        }
      : isIOS || isAndroid
        ? () => {
            window.location.href = LINK_TO_DOWNLOAD_APP;
          }
        : undefined;
  }, [isFleet, navigate]);

  const ctaToAccessApp = useMemo(
    () => (
      <Button
        className="w-full md:max-w-xs md:self-center"
        label={t(`registerPage.confirmationScreen.${proTypeLabel}.cta`)}
        onClick={handleClick}
        type="button"
        size="large"
      />
    ),
    [handleClick, proTypeLabel, t]
  );

  const bottom = useMemo(() => {
    if (!handleClick || smScreen) return undefined;

    return ctaToAccessApp;
  }, [ctaToAccessApp, handleClick, smScreen]);

  const title = useMemo(() => {
    return (
      <h1 className="my-0 text-2xl font-light text-neutral-900 sm:text-3xl">
        {t(`registerPage.confirmationScreen.${proTypeLabel}.title`)}
      </h1>
    );
  }, [proTypeLabel, t]);

  const successDriverImage = useMemo(
    () => <img src={successDriverImg} className="hidden h-[150px] w-full rounded object-cover sm:flex" alt="Pro" />,
    []
  );

  const fleetContent = useMemo(() => {
    if (!isFleet) return undefined;

    return (
      <>
        <Banner className="hidden flex-col gap-4 px-4 py-6 sm:flex" bgColor="bg-neutral-50" borderRadius="normal">
          {title}

          {successDriverImage}

          <div className="grid grid-cols-2 gap-2 px-2 py-2">
            {perks.map((perk, index) => {
              return (
                <Step
                  key={`step_${index}`}
                  number={index + 1}
                  content={
                    <Trans
                      i18nKey={perk.text}
                      components={{
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        1: <span className="font-bold italic" />,
                      }}
                    />
                  }
                />
              );
            })}
          </div>

          {ctaToAccessApp}
        </Banner>

        <div className="flex flex-col gap-2 sm:hidden">
          {perks.map((perk, index) => {
            return (
              <Step
                key={`step-container_${index}`}
                number={index + 1}
                content={
                  <Trans
                    i18nKey={perk.text}
                    components={{
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      1: <span className="font-bold italic" />,
                    }}
                  />
                }
              />
            );
          })}
        </div>
      </>
    );
  }, [ctaToAccessApp, isFleet, perks, successDriverImage, title]);

  const independantContent = useMemo(() => {
    if (isFleet) return undefined;

    return (
      <Banner className="flex flex-col gap-4 px-4 py-6" bgColor="bg-neutral-50" borderRadius="normal">
        <div className="hidden sm:flex">{title}</div>

        {successDriverImage}

        <span className="text-xl font-light text-neutral-700">
          {t(`registerPage.confirmationScreen.${proTypeLabel}.subtitle`)}
        </span>

        <CheckedList list={perks} />

        <div className="hidden flex-row items-center justify-between gap-6 rounded-3xl border-[1px] border-neutral-300 bg-white px-6 py-4 md:flex">
          <div className="text-label-sm text-left font-normal text-neutral-600">
            Scannez le QR code pour ouvrir l’application mobile Electra
          </div>
          <DownloadQrcode className="h-[60px] w-[60px] flex-none" />
        </div>
      </Banner>
    );
  }, [isFleet, perks, proTypeLabel, successDriverImage, t, title]);

  useEffect(() => {
    if (!isFleet) {
      // independant drivers must be signed out if their registration is completed
      signOut();
    }
  }, [isFleet, signOut]);

  useEffect(() => {
    pushToGtm();
  }, [pushToGtm]);

  useEffect(() => {
    if (!mixpanel) return;

    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  return (
    <CenteredPage size="large" bottom={bottom} showHeader={showBusinessLogo} showNavbar={showBusinessLogo}>
      <div className="flex flex-col gap-4">
        {!showBusinessLogo && <img src={niceImg} className="inline h-[78px] w-[124px] self-center" alt="Success" />}

        <div className="flex flex-col gap-6">
          <div className="flex sm:hidden">{title}</div>

          {fleetContent}

          {independantContent}
        </div>
      </div>
    </CenteredPage>
  );
};

export default ConfirmationScreen;
