import { BUSINESS_TOKEN_STORAGE_KEY } from "constants/storage";

import { LoadingScreen } from "components";
import { useMixpanel, useModal, useLocalStorage } from "hooks";
import { CreationSource, PAGE_VIEWED } from "libs";
import join from "lodash/join";
import {
  useRegisterPageCreateProAccountMutation,
  useRegisterPageCompletePhoneChallengeMutation,
  useRegisterPageStartPhoneChallengeMutation,
  AccountValidationStatus,
  AccountMissingInfos,
  BusinessCompanyIdentificationType,
  useRegisterPageUpdateBusinessCompanyMutation,
  useRegisterPageSearchBusinessCompanyMutation,
  LayoutDocument,
  useRegisterPageUploadBusinessDocumentsMutation,
  Country,
  DocumentFileDataType,
} from "operations";
import { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useOnboardingStatus } from "../../hooks/useOnboardingStatus";
import { useProType } from "../../hooks/useProType";
import useSignedIn from "../../hooks/useSignedIn";
import { useUser } from "../../hooks/useUser";
import canUserUseApp from "../../utils/canUserUseApp";
import hasToRedirectUserToSelfServe from "../../utils/hasToRedirectUserToSelfServe";
import redirectUserToMobile from "../../utils/redirectUserToMobile";

import { getScreenByMissingInfo } from "./helpers";
import CompanyScreen, { FormValues } from "./screens/CompanyScreen";
import ConfirmationScreen from "./screens/ConfirmationScreen";
import DocumentsScreen from "./screens/DocumentsScreen";
import InvoiceInfoScreen from "./screens/InvoiceInfoScreen";
import OnboardingScreen from "./screens/OnboardingScreen";
import PhoneChallengeScreen from "./screens/PhoneChallengeScreen";
import PhoneNumberScreen from "./screens/PhoneNumberScreen";
import UserNamesScreen from "./screens/UserNamesScreen";

interface Props {
  children?: never;
}

type ScreenState =
  | "ONBOARDING"
  | "PHONE_NUMBER"
  | "PHONE_CHALLENGE"
  | "USER_NAMES"
  | "COMPANY"
  | "BILLING_INFOS"
  | "DOCUMENTS"
  | "CONFIRMATION";

const stepByScreen = {
  BILLING_INFOS: 4,
  COMPANY: 2,
  CONFIRMATION: 5,
  DOCUMENTS: 3,
  ONBOARDING: 1,
  PHONE_CHALLENGE: 1,
  PHONE_NUMBER: 1,
  USER_NAMES: 1,
};

const RegisterPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const { userId, currentAccountId, currentAccountUserId } = useUser();
  const signedIn = useSignedIn();
  const navigate = useNavigate();
  const onboardingStatus = useOnboardingStatus();
  const missingInfos = onboardingStatus?.missingInfos;
  const status = onboardingStatus?.status;
  const pageTitle = t("registerPage.pageTitle");
  const { isFleet, proType } = useProType();
  const [searchParams] = useSearchParams();
  const creationSourceParam = searchParams.get("creation_source") as CreationSource;
  const { isModalDisplayed, setModalDisplayed } = useModal();
  const totalSteps = isFleet ? 4 : 3;
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [screen, setScreen] = useState<ScreenState>("ONBOARDING");
  const step = stepByScreen[screen];
  const subTitle = t("registerPage.subtitle", { currentStep: step.toString(), totalSteps });
  const [error, setError] = useState<string | ReactElement | undefined>();
  const trackPageView = useMixpanel((state) => state.trackPageView);
  const isStorybook = import.meta.env.STORYBOOK;
  const [, setSessionToken] = useLocalStorage(BUSINESS_TOKEN_STORAGE_KEY);

  const [displayRedirectToApp, setDisplayRedirectToApp] = useState(false);

  const handleMissingInfosNavigation = (missingInfos: AccountMissingInfos[]) => {
    const newScreen = getScreenByMissingInfo(missingInfos);
    setScreen(newScreen);
  };

  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const mixpanelProps = useMemo(
    () => ({
      account_user_id: currentAccountUserId,
      funnel_type: proType,
      ...mixpanelDynamicProps,
    }),
    [mixpanelDynamicProps, currentAccountUserId, proType]
  );

  // handle redirected users and newly created users
  useEffect(() => {
    if (!missingInfos) return;
    if (missingInfos.length) {
      handleMissingInfosNavigation(missingInfos);
      return;
    }
  }, [missingInfos, t]);

  useEffect(() => {
    if (status === AccountValidationStatus.Rejected) {
      setError(t("registerPage.error.rejected"));
      return;
    }
  }, [status, t]);

  // Tracking plan
  const trackPage = useCallback(() => {
    if (screen === "ONBOARDING" && !missingInfos) {
      if (proType) {
        trackPageView(PAGE_VIEWED.SMB_ONBOARDING_STEP_0, { user_id: userId, ...mixpanelProps });
      } else {
        trackPageView(PAGE_VIEWED.SMB_ONBOARDING_DISPATCH, {
          creation_source: creationSourceParam,
          user_id: userId,
          ...mixpanelProps,
        });
      }
      return;
    }

    if (!proType) return;

    if (screen === "PHONE_NUMBER") {
      trackPageView(PAGE_VIEWED.SMB_ONBOARDING_SIGN_UP, { user_id: userId, ...mixpanelProps });
      return;
    }
    if (screen === "COMPANY") {
      trackPageView(PAGE_VIEWED.SMB_ONBOARDING_COMPANY, { user_id: userId, ...mixpanelProps });
      return;
    }
    if (screen === "DOCUMENTS") {
      trackPageView(PAGE_VIEWED.SMB_ONBOARDING_VALIDATION, mixpanelProps);
      return;
    }
    if (screen === "BILLING_INFOS") {
      trackPageView(PAGE_VIEWED.SMB_ONBOARDING_PAYMENT, mixpanelProps);
      return;
    }
    if (screen === "CONFIRMATION") {
      trackPageView(PAGE_VIEWED.SMB_ONBOARDING_SUCCESS, { user_id: userId, ...mixpanelProps });
      return;
    }
  }, [screen, proType, trackPageView, userId, mixpanelProps, creationSourceParam, missingInfos]);

  /*
   * Mutations
   */
  const [
    startPhoneChallengeMutation,
    { data: startPhoneChallengeResult, error: startPhoneChallengeError, reset: resetStartPhoneChallenge },
  ] = useRegisterPageStartPhoneChallengeMutation({
    onCompleted: ({ startPhoneChallenge: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setPhoneNumber(undefined);
        return;
      }

      if (result?.challengeToken) {
        setScreen("PHONE_CHALLENGE");
        return;
      }
    },
    onError: () => {
      setPhoneNumber(undefined);
    },
  });

  const [
    completePhoneChallengeMutation,
    { data: completePhoneChallengeResult, error: completePhoneChallengeError, reset: resetCompletePhoneChallenge },
  ] = useRegisterPageCompletePhoneChallengeMutation({
    onCompleted: ({ completePhoneChallenge: result }) => {
      if (result?.completionToken) {
        setScreen("USER_NAMES");
      }
    },
  });

  const [createAccountMutation, { data: createAccountResult, error: createAccountError }] =
    useRegisterPageCreateProAccountMutation({
      onCompleted: ({ createProAccount: result }) => {
        if (!result || !result?.onboardingStatus || (result?.errors && result.errors.length > 0)) {
          setError(t("api.occuredError", { ns: "common" }));
          return;
        }

        const { onboardingStatus, sessionToken, account } = result;

        const mustUseSelfServe = hasToRedirectUserToSelfServe(onboardingStatus.missingInfos);
        const canUseApp = canUserUseApp(onboardingStatus);
        const redirectToMobile = redirectUserToMobile(onboardingStatus);

        // we check first that there is no missing info, SMB users can use app with a missing email
        if (mustUseSelfServe) {
          setSessionToken(sessionToken);
          return;
        }

        if (canUseApp) {
          setSessionToken(sessionToken);
          const hasNoMembers = account?.members?.nodes?.length === 0 && account?.invitations?.nodes?.length === 0;
          if (hasNoMembers) {
            navigate(
              {
                pathname: "/users/invite",
                search: createSearchParams({
                  welcome: "true",
                }).toString(),
              },
              { replace: true }
            );
          } else {
            navigate("/users", { replace: true });
          }

          return;
        }

        if (redirectToMobile) {
          setDisplayRedirectToApp(true);
          return;
        }

        // rejected users
        setError(t("registerPage.error.rejected"));
        return;
      },
      onError: (error) => {
        setError(error.message);
      },
      refetchQueries: [{ fetchPolicy: "network-only", query: LayoutDocument }],
    });

  const [searchCompanyMutation, { data: searchResult, error: searchError, reset: searchCompanyReset }] =
    useRegisterPageSearchBusinessCompanyMutation();

  const [updateCompanyMutation, { data: updateCompanyResult, error: updateCompanyError }] =
    useRegisterPageUpdateBusinessCompanyMutation({
      onCompleted: ({ updateBusinessCompany: result }) => {
        const missingInfos = result?.onboardingStatus?.missingInfos;
        if (!missingInfos) {
          setError(t("api.occuredError", { ns: "common" }));
        } else if (missingInfos.length === 0) {
          setModalDisplayed(true);
        } else {
          handleMissingInfosNavigation(missingInfos);
        }
      },
      refetchQueries: [{ fetchPolicy: "network-only", query: LayoutDocument }],
    });

  const [
    uploadDocumentsMutation,
    { data: uploadDocumentsResult, error: uploadDocumentsError, reset: uploadDocumentsReset },
  ] = useRegisterPageUploadBusinessDocumentsMutation({
    onCompleted: ({ uploadBusinessDocuments: result }) => {
      const missingInfos = result?.onboardingStatus?.missingInfos;
      if (!missingInfos) {
        setError(t("api.occuredError", { ns: "common" }));
      } else if (missingInfos.length === 0) {
        setModalDisplayed(true);
      } else {
        handleMissingInfosNavigation(missingInfos);
      }
    },
    refetchQueries: [{ fetchPolicy: "network-only", query: LayoutDocument }],
  });

  const handleCloseModal = useCallback(() => {
    setModalDisplayed(false);
    setScreen("CONFIRMATION");
  }, [setModalDisplayed]);

  /*
   * Variables from Apollo State
   */

  const phoneChallengeCompletionToken =
    completePhoneChallengeResult?.completePhoneChallenge?.completionToken ?? undefined;

  const challengeToken = startPhoneChallengeResult?.startPhoneChallenge?.challengeToken ?? undefined;

  // accountId might be the freshly created account or the existing pro account
  const accountId = currentAccountId || createAccountResult?.createProAccount?.account?.id || undefined;

  const completePhoneChallengeErrorMessage =
    join(completePhoneChallengeResult?.completePhoneChallenge?.errors, " ") ||
    completePhoneChallengeError?.message ||
    error;

  const startPhoneChallengeErrorMessage =
    join(startPhoneChallengeResult?.startPhoneChallenge?.errors, " ") || startPhoneChallengeError?.message;

  const createAccountErrorMessage =
    join(createAccountResult?.createProAccount?.errors, " ") || createAccountError?.message || error;

  const companyErrorMessage =
    searchError?.message ||
    error ||
    join(updateCompanyResult?.updateBusinessCompany?.errors, " ") ||
    updateCompanyError?.message;

  const updateCompanyErrorMessage =
    error || join(updateCompanyResult?.updateBusinessCompany?.errors, " ") || updateCompanyError?.message;

  const uploadDocumentsErrorMessage =
    error || join(uploadDocumentsResult?.uploadBusinessDocuments?.errors, " ") || uploadDocumentsError?.message;

  const company = searchResult?.searchBusinessCompany ?? undefined;

  /*
   * Props
   */

  const onboardingScreenProps = useMemo(() => {
    if (screen !== "ONBOARDING") return;
    return {
      handleClick: () => {
        if (window.dataLayer && !isStorybook) {
          window.dataLayer.push({
            account_type: proType,
            account_user_id: currentAccountUserId,
            event: "register_start",
            leanplum_user_id: userId,
          });
        }
        setScreen("PHONE_NUMBER");
      },
      trackPage,
    };
  }, [screen, trackPage, isStorybook, proType, currentAccountUserId, userId]);

  const phoneNumberScreenProps = useMemo(() => {
    if (screen !== "PHONE_NUMBER") return;

    return {
      error: startPhoneChallengeErrorMessage,
      gdprCheck: true,
      onSubmit: async (phoneNumber: string) => {
        if (error) setError(undefined);
        setPhoneNumber(phoneNumber);
        await startPhoneChallengeMutation({
          variables: {
            input: {
              phoneNumber,
            },
          },
        });
      },
      resetError: resetStartPhoneChallenge,
      subTitle,
      title: pageTitle,
      trackPage,
    };
  }, [
    startPhoneChallengeMutation,
    screen,
    pageTitle,
    subTitle,
    resetStartPhoneChallenge,
    startPhoneChallengeErrorMessage,
    error,
    trackPage,
  ]);

  const phoneChallengeScreenProps = useMemo(() => {
    if (screen !== "PHONE_CHALLENGE" || !phoneNumber) return;

    return {
      error: completePhoneChallengeErrorMessage,
      onSubmit: async (code: string) => {
        if (error) setError(undefined);
        if (!challengeToken) {
          setError(t("signInPage.error.timeout"));
          return;
        }

        await completePhoneChallengeMutation({
          variables: {
            input: {
              challengeCode: parseInt(code, 10),
              challengeToken,
            },
          },
        });
      },
      phoneNumber,
      resetError: resetCompletePhoneChallenge,
      subTitle,
      title: pageTitle,
    };
  }, [
    challengeToken,
    completePhoneChallengeMutation,
    phoneNumber,
    pageTitle,
    subTitle,
    screen,
    t,
    completePhoneChallengeErrorMessage,
    resetCompletePhoneChallenge,
    error,
  ]);

  const userNamesScreenProps = useMemo(() => {
    if (screen !== "USER_NAMES") return;

    return {
      displayRedirectToApp,
      error: createAccountErrorMessage,
      onSubmit: async (firstname: string, lastname: string) => {
        if (error) setError(undefined);
        if (!phoneChallengeCompletionToken || !proType) {
          setError(t("signInPage.error.timeout"));
          return;
        }
        await createAccountMutation({
          variables: {
            input: {
              firstname,
              lastname,
              phoneChallengeCompletionToken,
              proType,
            },
          },
        });
        if (window.dataLayer && !isStorybook) {
          window.dataLayer.push({
            account_type: proType,
            account_user_id: currentAccountUserId,
            event: "register_step-1",
            leanplum_user_id: userId,
          });
        }
      },
      subTitle,
      title: pageTitle,
    };
  }, [
    phoneChallengeCompletionToken,
    createAccountMutation,
    screen,
    subTitle,
    pageTitle,
    createAccountErrorMessage,
    t,
    error,
    displayRedirectToApp,
    proType,
    userId,
    isStorybook,
    currentAccountUserId,
  ]);

  const companyScreenProps = useMemo(() => {
    if (screen !== "COMPANY") return;
    return {
      company,
      error: companyErrorMessage,
      onSubmit: async (companyForm: FormValues) => {
        if (error) setError(undefined);
        if (!companyForm.companyIdentificationNumber) {
          return;
        }
        if (!accountId) {
          setError(t("api.occuredError", { ns: "common" }));
          return;
        }

        const isFormSubmittedManually = companyForm.infosManuallyProvided;
        const isSearchingQuery = !company && !isFormSubmittedManually;

        if (isSearchingQuery) {
          await searchCompanyMutation({
            variables: {
              input: {
                companyIdentificationNumber: companyForm.companyIdentificationNumber,
                companyIdentificationType: companyForm.companyIdentificationType,
              },
            },
          });
        } else {
          const { name, address, companyIdentificationType, companyIdentificationNumber, city, postCode } =
            company || companyForm;
          const countryId = company?.country?.id || companyForm.countryId;

          const vatId =
            companyIdentificationType === BusinessCompanyIdentificationType.Vat ? companyIdentificationNumber : null;

          const siretNumber =
            companyIdentificationType === BusinessCompanyIdentificationType.Siret ? companyIdentificationNumber : null;

          await updateCompanyMutation({
            variables: {
              input: {
                accountId,
                address,
                city,
                countryId,
                infosManuallyProvided: !!isFormSubmittedManually,
                name,
                postCode,
                siretNumber,
                vatId,
              },
            },
          });
        }
      },
      resetError: searchCompanyReset,
      subTitle,
      trackPage,
    };
  }, [
    screen,
    subTitle,
    updateCompanyMutation,
    searchCompanyMutation,
    searchCompanyReset,
    companyErrorMessage,
    accountId,
    company,
    error,
    t,
    trackPage,
  ]);

  const documentsScreenProps = useMemo(() => {
    if (screen !== "DOCUMENTS" || !accountId) {
      return;
    }
    return {
      closeModal: handleCloseModal,
      error: uploadDocumentsErrorMessage,
      isModalOpen: isModalDisplayed,
      onSubmit: async (documentFiles: DocumentFileDataType[]) => {
        await uploadDocumentsMutation({
          variables: {
            input: {
              accountId,
              countryKey: onboardingStatus?.country?.key ?? Country.Fr,
              documentFiles,
            },
          },
        });
      },
      resetError: uploadDocumentsReset,
      subTitle,
      trackPage,
    };
  }, [
    screen,
    accountId,
    handleCloseModal,
    isModalDisplayed,
    uploadDocumentsReset,
    subTitle,
    uploadDocumentsMutation,
    trackPage,
    onboardingStatus?.country,
    uploadDocumentsErrorMessage,
  ]);

  const confirmationScreenProps = useMemo(() => {
    if (screen !== "CONFIRMATION") {
      return;
    }
    return { trackPage };
  }, [screen, trackPage]);

  const invoiceInfoScreenProps = useMemo(() => {
    if (screen !== "BILLING_INFOS" || !accountId) return;
    return {
      closeModal: handleCloseModal,
      error: updateCompanyErrorMessage,
      isModalOpen: isModalDisplayed,
      onSubmit: async (email: string) => {
        await updateCompanyMutation({
          variables: {
            input: {
              accountId,
              email,
            },
          },
        });
      },
      subTitle,
      trackPage,
    };
  }, [
    screen,
    subTitle,
    updateCompanyMutation,
    accountId,
    updateCompanyErrorMessage,
    handleCloseModal,
    isModalDisplayed,
    trackPage,
  ]);

  if (signedIn) {
    if (!missingInfos) {
      return <LoadingScreen centered />;
    } else if (missingInfos.length === 0 && status === AccountValidationStatus.NotRequired) {
      return <Navigate to="/" replace={true} />;
    }
  }

  if (onboardingScreenProps) {
    return <OnboardingScreen {...onboardingScreenProps} />;
  }

  if (phoneNumberScreenProps) {
    return <PhoneNumberScreen {...phoneNumberScreenProps} />;
  }

  if (phoneChallengeScreenProps) {
    return <PhoneChallengeScreen {...phoneChallengeScreenProps} />;
  }

  if (userNamesScreenProps) {
    return <UserNamesScreen {...userNamesScreenProps} />;
  }

  if (companyScreenProps) {
    return <CompanyScreen {...companyScreenProps} />;
  }

  if (documentsScreenProps) {
    return <DocumentsScreen {...documentsScreenProps} />;
  }

  if (confirmationScreenProps) {
    return <ConfirmationScreen {...confirmationScreenProps} />;
  }

  if (invoiceInfoScreenProps) {
    return <InvoiceInfoScreen {...invoiceInfoScreenProps} />;
  }

  throw new Error("Invalid state");
};

export default RegisterPage;
