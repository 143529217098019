import { useApolloClient } from "@apollo/client";
import { LayoutFragmentDoc } from "@webapps/shared/operations";

import useSignedIn from "./useSignedIn";
import { useUser } from "./useUser";

export const useUserPhoneNumber = () => {
  const userId = useUser((state) => state.userId);
  const signedIn = useSignedIn();
  const client = useApolloClient();
  if (!signedIn) return undefined;
  const user = client.readFragment({
    fragment: LayoutFragmentDoc,
    fragmentName: "LayoutFragment",
    id: `User:${userId}`,
  });
  return user?.phoneNumber ?? "";
};
