const DEEPLINK_REGEX = /^https?:\/\/app(-.*)?\.go-electra\.com(\/.*)?/;

function isExternalUrl(url: string) {
  return !url.startsWith("/") && !DEEPLINK_REGEX.test(url);
}

export function getHrefAndTarget(deeplink: string | undefined | null): {
  href: string | undefined;
  target: "_blank" | "_self" | undefined;
} {
  if (!deeplink) {
    return {
      href: undefined,
      target: undefined,
    };
  }

  const external = isExternalUrl(deeplink);

  let href: string | undefined = deeplink;
  if (!external) {
    const parts = DEEPLINK_REGEX.exec(deeplink);
    if (parts) {
      const lastPart = parts[parts.length - 1];
      href = !lastPart ? "/" : lastPart;
    }
  }

  return {
    href,
    target: external ? "_blank" : "_self",
  };
}
