import { FunctionComponent, ReactNode } from "react";
interface Props {
  content: string | ReactNode;
  number: number;
}

const Step: FunctionComponent<Props> = ({ content, number }) => {
  return (
    <div className="flex w-full items-center gap-2 rounded bg-neutral-50 p-4">
      <div className="text-5xl font-light text-neutral-300">{number}</div>
      <div className="text-sm font-normal text-neutral-700">{content}</div>
    </div>
  );
};

export default Step;
