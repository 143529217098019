import { InputField, SelectField } from "@webapps/shared/components";
import { useFormikContext } from "formik";
import { useInputFocus } from "hooks";
import { ChangeEvent, FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { required } from "utils";

import { useSelfServeAvailableCountries } from "../../../hooks/useSelfServeAvailableCountries";
import { FormValues } from "../screens/CompanyScreen";

export interface ServiceProps {
  handleCompanyChange: (fieldName: string, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const ServiceUnavailableForm: FunctionComponent<ServiceProps> = ({ handleCompanyChange }) => {
  const focusInputRef = useInputFocus();
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const countries = useSelfServeAvailableCountries();
  const countryFromIdNumber = values.companyIdentificationNumber.substring(0, 2).toUpperCase();

  const displayedCountries = countryFromIdNumber
    ? countries.filter((country) => country.name.toUpperCase().startsWith(countryFromIdNumber))
    : countries;

  const items = useMemo(
    () => displayedCountries.map((country) => ({ label: country.name, value: country.id })),
    [displayedCountries]
  );

  return (
    <div className="mt-6 flex flex-col gap-6">
      <InputField
        name="name"
        label={t("registerPage.companyScreen.form.name.label")}
        validate={required}
        ref={focusInputRef}
        inputProps={{
          initialValue: "",
          onChange: (event) => handleCompanyChange("name", event),
          placeholder: `${t("registerPage.companyScreen.form.name.placeHolder")}`,
        }}
      />

      <InputField
        name="address"
        label={t("registerPage.companyScreen.form.address.label")}
        validate={required}
        inputProps={{
          initialValue: "",
          onChange: (event) => handleCompanyChange("address", event),
          placeholder: `${t("registerPage.companyScreen.form.address.placeHolder")}`,
        }}
      />

      <div className="flex flex-row gap-4">
        <div className="w-2/3">
          <InputField
            name="city"
            label={t("registerPage.companyScreen.form.city.label")}
            validate={required}
            inputProps={{
              initialValue: "",
              onChange: (event) => handleCompanyChange("city", event),
              placeholder: `${t("registerPage.companyScreen.form.city.placeHolder")}`,
            }}
          />
        </div>
        <div className="w-1/3">
          <InputField
            name="postCode"
            label={t("registerPage.companyScreen.form.postCode.label")}
            validate={required}
            inputProps={{
              initialValue: "",
              inputMode: "numeric",
              onChange: (event) => handleCompanyChange("postCode", event),
              placeholder: `${t("registerPage.companyScreen.form.postCode.placeHolder")}`,
              type: "number",
            }}
          />
        </div>
      </div>
      <SelectField
        name="countryId"
        items={items}
        validate={required}
        label={t("registerPage.companyScreen.form.country.label")}
        selectProps={{
          includeBlank: true,
          placeholder: t("registerPage.companyScreen.form.country.placeHolder"),
        }}
      />
    </div>
  );
};

export default ServiceUnavailableForm;
