import initI18next from "intl";
import { initBugsnag } from "libs";
import noop from "lodash/noop";
import "normalize.css";
import { createRoot } from "react-dom/client";
import { electraEnv, enableMocking } from "utils";

import App from "./components/App";
import "tailwind/common.css";
import { worker } from "./mocks/browser";
import ErrorPage from "./pages/ErrorPage";

const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY;

const ErrorBoundary = initBugsnag(bugsnagApiKey, electraEnv);

initI18next(["business", "common"], import.meta.env.DEV);

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  enableMocking(worker).then(() =>
    root.render(
      ErrorBoundary ? (
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <App />
        </ErrorBoundary>
      ) : (
        <App />
      )
    )
  );
}

// To activate :active pseudo class in Safari
document.addEventListener("touchstart", noop, false);
