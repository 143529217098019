import { Banner, Button, InputField, PhoneNumberField } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { useLibPhoneNumber } from "@webapps/shared/libs";
import { Country, InvitationDataType } from "@webapps/shared/operations";
import { getCallingCodeFromCountryCode, required } from "@webapps/shared/utils";
import { Formik } from "formik";
import { intersection } from "lodash";
import { ChangeEvent, ComponentProps, FunctionComponent, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import { useCountriesWithCallingCode } from "../../../hooks/useCountriesWithCallingCode";
import PhoneIcon from "../../../images/icons/ri/phone-home.svg?react";
import BackInviteModal from "../components/BackInviteModal";
import WelcomeModal from "../components/WelcomeModal";

export type FormValues = InvitationDataType & { countryCode: Country };

export const initialValues = { countryCode: Country.Fr, firstname: "", lastname: "", phoneNumber: "" };

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  children?: never;
  error?: string | undefined;
  onSubmit: (values: InvitationDataType) => Promise<void>;
  resetError: () => void;
  trackPage?: () => void;
}

const SmallScreen: FunctionComponent<Props> = ({ error, navLeft, onSubmit, trackPage, resetError }) => {
  const { t } = useTranslation();
  const ensureLibPhoneNumber = useLibPhoneNumber();
  const countries = useCountriesWithCallingCode();

  const submit = async (values: FormValues) => {
    const { parsePhoneNumber } = await ensureLibPhoneNumber();

    const phonelib = parsePhoneNumber(values.phoneNumber, values.countryCode);
    await onSubmit({
      firstname: values.firstname,
      lastname: values.lastname,
      phoneNumber: phonelib.formatInternational(),
    });
  };

  useEffect(() => {
    trackPage?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      {({ errors, handleSubmit, isSubmitting, isValidating, touched, values, setFieldValue }) => {
        const hasError = intersection(Object.keys(errors), Object.keys(touched)).length > 0;

        const selectedCallingCode = getCallingCodeFromCountryCode(values.countryCode, countries);

        const handleClearPhoneInput = () => {
          setFieldValue("phoneNumber", "");
          resetError();
        };

        const handleChangeNumber = async (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;

          if (!values.countryCode) {
            setFieldValue("phoneNumber", value);
          }

          const { AsYouType } = await ensureLibPhoneNumber();

          const asYouType = new AsYouType(values.countryCode);
          const newPhoneNumber = asYouType.input(value);
          setFieldValue("phoneNumber", newPhoneNumber);
        };

        const bottom = (
          <>
            {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

            {error && !hasError ? <div className="text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full"
              disabled={isValidating || isSubmitting || hasError}
              label={t("invitationsPage.invitationScreen.cta")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <Page
            bottom={bottom}
            navLeft={navLeft}
            subTitle={t("invitationsPage.invitationScreen.subtitle")}
            title={t("invitationsPage.invitationScreen.pageTitle")}
            business
          >
            <WelcomeModal />
            <BackInviteModal />
            <div className="flex flex-col gap-4">
              <Banner bgColor="bg-neutral-50" borderRadius="normal">
                <p className="text-medium font-normal text-neutral-600">
                  <Trans
                    i18nKey="invitationsPage.invitationScreen.info"
                    components={{
                      1: <span className="font-bold" />,
                    }}
                  />
                </p>
              </Banner>
              <div className="flex flex-col gap-2">
                <InputField
                  label={t("invitationsPage.invitationScreen.firstname")}
                  name={"firstname"}
                  validate={required}
                  inputProps={{
                    autoComplete: "firstname",
                    enterKeyHint: "send",
                  }}
                />

                <InputField
                  label={t("invitationsPage.invitationScreen.lastname")}
                  name="lastname"
                  validate={required}
                  inputProps={{
                    autoComplete: "lastname",
                    enterKeyHint: "send",
                  }}
                />

                <PhoneNumberField
                  label={t("invitationsPage.invitationScreen.phoneNumber")}
                  name="phoneNumber"
                  prefixName="countryCode"
                  validate={required}
                  inputProps={{
                    autoComplete: "tel",
                    icon: PhoneIcon,
                    inputMode: "tel",
                    onChange: handleChangeNumber,
                    type: "tel",
                  }}
                  items={countries}
                  selectProps={{ autoComplete: "tel-country-code" }}
                  onClear={handleClearPhoneInput}
                  callingCode={selectedCallingCode}
                />
              </div>
            </div>
          </Page>
        );
      }}
    </Formik>
  );
};

export default SmallScreen;
