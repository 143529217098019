import { useMediaQuery } from "@react-hook/media-query";
import { Button, Page } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import classNames from "classnames";
import DownloadIcon from "images/icons/ri/download.svg?react";
import { ComponentProps, FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { PAGE_LENGTH, PaginationDirection, PaginationState } from "..";
import Pagination from "../../../components/molecules/Pagination";
import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import ChargeCards from "../components/ChargeCards";
import ChargeTable from "../components/ChargeTable";
import EmptyCharges from "../components/EmptyCharges";
import ExportModal from "../components/ExportModal";
import SortBySelect, { Sorting, SortingKey } from "../components/SortBySelect";
import { TableData } from "../utils";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  chargesCount: number;
  children?: never;
  data: TableData[] | undefined;
  loading: boolean;
  onPaginationClick: (direction: PaginationDirection) => void;
  onSortClick: () => void;
  onSortItemClick: (value: SortingKey) => void;
  pagination: PaginationState;
  sorting: Sorting;
}

const Screen: FunctionComponent<Props> = ({
  navLeft,
  onPaginationClick,
  chargesCount,
  onSortClick,
  data,
  sorting,
  pagination,
  loading,
  onSortItemClick,
}) => {
  const { t } = useTranslation();

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const isTableEmpty = data && data.length === 0;
  const firstRowIndex = pagination.pageIndex * PAGE_LENGTH + 1;
  const lastRowIndex = Math.min(firstRowIndex + PAGE_LENGTH - 1, chargesCount);
  const smScreen = useMediaQuery("only screen and (min-width: 640px)");
  const rootElementRef = useRef<HTMLDivElement>(null);
  const dataToRender = loading || !data ? Array(15).fill({}) : data;
  const [isModalOpen, setModalOpen] = useState(false);

  const paginationProps = {
    firstRowIndex,
    lastRowIndex,
    onLeftClick: () => onPaginationClick("left"),
    onRightClick: () => onPaginationClick("right"),
    rowCount: chargesCount,
  };

  //
  // Tracking plan
  //

  useEffect(() => {
    trackPageView(PAGE_VIEWED.CHARGES_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page navLeft={navLeft} title={t("chargesPage.pageTitle")} rawContent business stretchContentOnLargeScreen>
      <ExportModal isOpen={isModalOpen} closeModal={() => setModalOpen(false)} sorting={sorting} />
      <div className="border-primary-default mx-4 mb-2 mt-4 border-t opacity-10 md:mx-8" ref={rootElementRef} />
      {!isTableEmpty || loading ? (
        <div className="py-4-safe px-4-safe md:px-8-safe flex flex-col gap-0 sm:h-full sm:gap-5">
          <div
            className={classNames(
              "sticky top-0 z-30 flex h-20 w-full items-center justify-between bg-white shadow-none sm:static sm:h-auto"
            )}
          >
            <SortBySelect onClick={onSortClick} sorting={sorting} onItemClick={onSortItemClick} />
            <div className="flex justify-between gap-2">
              <Button
                size="small"
                type="button"
                onClick={() => setModalOpen(true)}
                label={t("chargesPage.exportData")}
                className="hidden"
                trailingIcon={DownloadIcon}
              />
              {chargesCount > PAGE_LENGTH && <Pagination {...paginationProps} />}
            </div>
          </div>

          {smScreen ? <ChargeTable data={dataToRender} /> : <ChargeCards data={dataToRender} />}
          {chargesCount > PAGE_LENGTH && (
            <div className="flex justify-between sm:justify-end sm:px-0">
              <Button
                size="small"
                kind="secondary"
                type="button"
                onClick={() => {
                  rootElementRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }}
                label={t("chargesPage.table.backToTop")}
                className="sm:hidden"
              />
              <Pagination {...paginationProps} />
            </div>
          )}
        </div>
      ) : (
        <EmptyCharges />
      )}
    </Page>
  );
};

export default Screen;
