import { FunctionComponent } from "react";

import StepNumber from "../../atoms/StepNumber";

interface Props {
  hint: string | undefined;
  index: number;
  text: string;
}

const StepItem: FunctionComponent<Props> = ({ hint, index, text }) => (
  <div className="flex items-center gap-4">
    <StepNumber value={index} />
    <div className="flex flex-col">
      <h5 className="text-medium font-normal text-neutral-900">{text}</h5>
      {!!hint && <span className="text-sm font-medium text-neutral-600">{hint}</span>}
    </div>
  </div>
);

export default StepItem;
