import { round } from "utils";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { MapLocation } from "../components/organisms/Map";

interface CurrentLocationState {
  currentLocation: MapLocation | undefined;
  setCurrentLocation: (position: GeolocationPosition) => void;
}

export const useCurrentLocation = create<CurrentLocationState>()(
  devtools((set, get) => ({
    currentLocation: undefined,
    setCurrentLocation: (position) => {
      const { coords } = position;
      const { longitude, latitude } = coords;

      const longitudeRounded = round(longitude, 3);
      const latitudeRounded = round(latitude, 3);
      const { currentLocation } = get();
      if (currentLocation?.[0] !== longitudeRounded || currentLocation?.[1] === latitudeRounded) {
        set(() => ({ currentLocation: [longitudeRounded, latitudeRounded] }));
      }
    },
  }))
);
