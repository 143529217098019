import { useMediaQuery } from "@react-hook/media-query";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import { BottomSheetWrapper, FloatingButton, Page } from "components";
import find from "lodash/find";
import noop from "lodash/noop";
import sortBy from "lodash/sortBy";
import { StationsPageStationSheet_StationFragment } from "operations";
import { ComponentProps, FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { ViewStateChangeEvent } from "react-map-gl";

import StationSheet from "../../../components/organisms/BottomSheets/StationBottomSheet";
import Map, { MapLocation, Mapbox } from "../../../components/organisms/Map";
import { getFormattedLocations } from "../../../components/organisms/Map/utils";
import { useCurrentLocation } from "../../../hooks/useCurrentLocation";
import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import NavigationIcon from "../../../images/icons/ri/location.svg?react";
import * as stationPageConstClass from "../constants";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft" | "navRight"> {
  children?: never;
  mapCenter: MapLocation | undefined;
  mapZoom: number | undefined;
  onLocalize?: () => void;
  onMapLoaded?: (map: Mapbox) => void;
  onMapMoveEnd?: (center: MapLocation, zoom: number) => void;
  onStationSelect?: (stationId: string | undefined, map?: Mapbox | null) => void;
  onStationZoom?: (stationLocation: MapLocation, map?: Mapbox | null) => void;
  selectedStationId?: string | undefined;
  stations?: StationsPageStationSheet_StationFragment[];
}

const Screen: FunctionComponent<Props> = ({
  mapCenter,
  mapZoom,
  navLeft,
  navRight,
  onLocalize,
  onMapLoaded,
  onMapMoveEnd,
  onStationSelect,
  onStationZoom,
  selectedStationId,
  stations = [],
}) => {
  const currentLocation = useCurrentLocation((state) => state.currentLocation);
  const sortedStations = useMemo(
    () => getFormattedLocations(sortBy(stations, ({ latitude }) => latitude * -1)),
    [stations]
  );

  const selectedStation = useMemo(() => find(stations, ["id", selectedStationId]), [selectedStationId, stations]);

  const onMoveEnd = useCallback(
    (event: ViewStateChangeEvent) => {
      const map = event.target;
      const center = map.getCenter();
      onMapMoveEnd?.([center.lng, center.lat], map.getZoom());
    },
    [onMapMoveEnd]
  );

  const onMarkerClick = useCallback((id: string) => onStationSelect?.(id), [onStationSelect]);

  const onCloseStationSheet = useCallback(() => {
    onStationSelect?.(undefined);
  }, [onStationSelect]);

  const stationSheet = useMemo(() => {
    if (!selectedStation) return null;

    return (
      <StationSheet onClose={onCloseStationSheet} onStationZoom={onStationZoom ?? noop} station={selectedStation} />
    );
  }, [onCloseStationSheet, onStationZoom, selectedStation]);

  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  useEffect(() => {
    trackPageView(PAGE_VIEWED.STATIONS_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      navLeft={!mdScreen ? navLeft : undefined}
      navRight={navRight}
      rawContent
      stretchContentOnLargeScreen
      variant="illustrated"
      verticalScroll={false}
      navClassName="top-2"
      business
    >
      <Map
        location={currentLocation}
        initialViewState={{
          fitBoundsOptions: stationPageConstClass.FIT_BOUNDS_OPTIONS,
          latitude: mapCenter?.[1],
          longitude: mapCenter?.[0],
          zoom: mapZoom,
        }}
        logoPosition={stationPageConstClass.MAP_LOGO_POSITION}
        onLoad={(event) => onMapLoaded?.(event.target)} // useMap not worked, even with MapProvider so pass map here
        onMapClick={() => onStationSelect?.(undefined)}
        onMarkerClick={onMarkerClick}
        onMoveEnd={onMoveEnd}
        locations={sortedStations}
        selectedId={selectedStationId}
      />

      <div className="pb-4-safe absolute bottom-0 grid w-full grid-cols-12 gap-y-3 px-4">
        <div className="z-10 col-end-13 place-self-end">
          <FloatingButton onClick={() => onLocalize?.()}>
            <NavigationIcon className="h-6 w-6" />
          </FloatingButton>
        </div>
      </div>
      <BottomSheetWrapper sheet={stationSheet} onClose={onCloseStationSheet} considersMenuWidth />
    </Page>
  );
};

export default Screen;
