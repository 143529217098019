import { Button } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: never;
  navLeft: ReactNode;
  onAuthorize: () => void;
  title?: string;
}

const GeolocationAuthorizeScreen: FunctionComponent<Props> = ({ navLeft, onAuthorize, title }) => {
  const { t } = useTranslation();
  const bottom = (
    <Button
      className="w-full"
      label={t("geolocationAuthorizeScreen.buttonLabel")}
      onClick={onAuthorize}
      size="large"
      type="button"
    />
  );

  return (
    <Page bottom={bottom} navLeft={navLeft} title={title} business>
      <div>{t("geolocationAuthorizeScreen.info")}</div>
    </Page>
  );
};

export default GeolocationAuthorizeScreen;
