import { LINK_TO_DOWNLOAD_APP } from "@webapps/constants";
import { Button, InputField, CenteredPage } from "@webapps/shared/components";
import { Form, Formik } from "formik";
import { useAsyncMemo, useInputFocus } from "hooks";
import { useLibPhoneNumber } from "libs";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import { ComponentProps, FunctionComponent, useEffect } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import { validateCodePhoneChallenge } from "utils";

import RedirectToAppBottom from "../../../components/organisms/RedirectToAppBottom";

interface FormValues {
  code: string;
}

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "navLeft"> {
  children?: never;
  displayRedirectToApp: boolean;
  error?: string | undefined;
  onSubmit: (code: string) => Promise<void>;
  phoneNumber: string;
  trackPage?: () => void;
}

const PhoneChallengeScreen: FunctionComponent<Props> = ({
  error,
  navLeft,
  onSubmit,
  phoneNumber,
  displayRedirectToApp,
  trackPage,
}) => {
  const { t } = useTranslation();
  const ensureLibPhoneNumber = useLibPhoneNumber();

  const focusInputRef = useInputFocus();

  const submit = async (values: FormValues) => {
    await onSubmit(values.code);
  };

  const [formattedPhoneNumber] = useAsyncMemo(async () => {
    const { parsePhoneNumber } = await ensureLibPhoneNumber();

    const phonelib = parsePhoneNumber(phoneNumber);

    return phonelib.formatNational();
  }, [phoneNumber]);

  // redirect to app is displayed if the user has no pro account
  // if he is on mobile, he will be redirected to the store
  // otherwise, we show the download message
  const handleRedirectButton = isIOS || isAndroid ? () => (window.location.href = LINK_TO_DOWNLOAD_APP) : undefined;

  useEffect(() => {
    trackPage?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{
        code: "",
      }}
      onSubmit={submit}
      validateOnChange={false}
    >
      {({ errors, handleSubmit, isSubmitting, isValidating, touched }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;

        const bottom = displayRedirectToApp ? (
          <RedirectToAppBottom handleClick={handleRedirectButton} />
        ) : (
          <>
            {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

            {error && !hasError ? <div className="text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full"
              disabled={isValidating || isSubmitting || hasError}
              label={t("signInPage.phoneChallengeScreen.cta")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <CenteredPage
            bottom={bottom}
            bottomWidthLimitation={!displayRedirectToApp}
            navLeft={navLeft}
            subTitle={t("signInPage.phoneChallengeScreen.subtitle")}
            title={t("signInPage.phoneChallengeScreen.title")}
          >
            <Form className="flex flex-col gap-8">
              <div>
                <Trans
                  i18nKey="signInPage.phoneChallengeScreen.hint"
                  components={{
                    1: <b />,
                  }}
                  values={{ phoneNumber: formattedPhoneNumber ?? phoneNumber }}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2">
                <InputField
                  name="code"
                  label={t("signInPage.phoneChallengeScreen.codeLabel")}
                  validate={validateCodePhoneChallenge}
                  inputProps={{ autoComplete: "one-time-code", inputMode: "numeric" }}
                  ref={focusInputRef}
                />
              </div>
            </Form>
          </CenteredPage>
        );
      }}
    </Formik>
  );
};

export default PhoneChallengeScreen;
