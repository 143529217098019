import { usePhoneNumberScreenQuery } from "@webapps/shared/operations";

export const useSelfServeAvailableCountries = () => {
  const { data } = usePhoneNumberScreenQuery({
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  return (
    data?.countries
      .filter((country) => country.selfServeAvailable)
      .map((country) => ({ id: country.id, key: country.key, name: country.name })) ?? []
  );
};
