import { useMixpanel, useModal, useNotification } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { LoadingScreen, ErrorScreen } from "components";
import compact from "lodash/compact";
import debounce from "lodash/debounce";
import map from "lodash/map";
import { NotificationCategoryEnum, useBulkReinviteUsersMutation, useUsersPageQuery } from "operations";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { orderBy } from "utils";

import { ResponsiveMenuAction } from "../../components/molecules/BusinessAction";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";

import Screen from "./screens/Screen";

interface Props {
  children?: never;
}

const UsersPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const pageTitle = t("usersPage.pageTitle");
  const { currentAccountId, userId } = useUser();
  const setNotification = useNotification((state) => state.setNotification);
  const { isModalDisplayed, setModalDisplayed } = useModal();
  const trackButtonClicked = useMixpanel((state) => state.trackButtonClicked);
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { data, loading, previousData, error, refetch } = useUsersPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId,
    variables: {
      accountId: currentAccountId ?? "none",
    },
  });

  // To use instead of loading not to have the Screen be replaced by LoadingScreen (unfocus the search field)
  const firstLoading = loading && !previousData;
  const hasInvitations = data?.me?.account?.invitations?.nodes && data?.me?.account?.invitations?.nodes?.length > 0;

  const [bulkReinviteUsersMutation] = useBulkReinviteUsersMutation({
    onCompleted: async ({ bulkReinviteUsers: result }) => {
      if (result?.successMessage || result?.warningMessage || result?.errorMessage) {
        if (result?.successMessage) {
          setNotification({ badge: true, message: result?.successMessage });
        }
        if (result?.errorMessage) {
          setNotification({ badge: true, message: result?.errorMessage, type: NotificationCategoryEnum.Error });
        }
        if (result?.warningMessage) {
          setNotification({ badge: true, message: result?.warningMessage, type: NotificationCategoryEnum.Warning });
        }
        trackButtonClicked(BUTTON_CLICKED.BULK_REINVITE_USERS, {
          ...mixpanelDynamicProps,
          account_id: currentAccountId,
          invitation_count: result?.sentReinvitationsCount,
        });
        setModalDisplayed(false);
        await refetch();
        return;
      }

      setNotification({
        badge: true,
        message: t("api.unknownError", { ns: "common" }),
        type: NotificationCategoryEnum.Error,
      });
    },
    onError: (error) => {
      setNotification({ badge: true, message: error.message, type: NotificationCategoryEnum.Error });
    },
  });

  /*
   * Props
   */

  const adminItems = useMemo(
    () =>
      data?.me?.account?.admins.nodes
        ? map(compact(data?.me?.account?.admins.nodes ?? []), (accountUser) => {
            return {
              accountUserId: accountUser.id,
              active: accountUser.active,
              admin: true,
              label: accountUser.fullname,
              me: accountUser.user.id === userId,
            };
          })
        : undefined,
    [userId, data]
  );

  const memberItems = useMemo(
    () =>
      data?.me?.account?.members.nodes
        ? map(compact(data?.me?.account?.members.nodes ?? []), (accountUser) => {
            return {
              accountUserId: accountUser.id,
              active: accountUser.active,
              admin: false,
              label: accountUser.fullname,
              me: accountUser.user.id === userId,
            };
          })
        : [],
    [userId, data]
  );

  const isLoading = useMemo(() => !adminItems && !memberItems && firstLoading, [adminItems, firstLoading, memberItems]);

  const invitationItems = useMemo(
    () =>
      data?.me?.account?.invitations
        ? map(compact(data?.me?.account?.invitations.nodes ?? []), (invitation) => {
            return {
              canReinvite: invitation.canReinvite,
              invitationId: invitation.id,
              label: invitation.fullname,
              refused: !!invitation.refusedAt,
            };
          })
        : [],
    [data?.me?.account?.invitations]
  );

  const debouncedRefetch = useMemo(() => debounce(refetch, 500, { leading: false, trailing: true }), [refetch]);
  const setSearchQueryAndSearch = useCallback(
    (query: string) => {
      setSearchQuery(query);
      debouncedRefetch({
        search: query,
      });
    },
    [setSearchQuery, debouncedRefetch]
  );

  const errorMessage = useMemo(() => error?.message ?? undefined, [error]);

  const commonProps = useMemo(() => {
    return {
      navLeft: <ResponsiveMenuAction />,
      title: pageTitle,
    };
  }, [pageTitle]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen {...commonProps} error={errorMessage} canReloadPage business />;
  }

  if (!currentAccountId) {
    return <NoAccountSelectedScreen {...commonProps} />;
  }

  if (isLoading) {
    return <LoadingScreen {...commonProps} business />;
  }

  return (
    <Screen
      isModalOpen={isModalDisplayed}
      setModalDisplayed={setModalDisplayed}
      adminItems={adminItems ?? []}
      memberItems={[...memberItems, ...invitationItems].sort(orderBy("label"))}
      navLeft={<ResponsiveMenuAction />}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQueryAndSearch}
      reinviteUsers={
        hasInvitations
          ? async () => {
              await bulkReinviteUsersMutation({
                variables: {
                  input: {
                    accountId: currentAccountId ?? "none",
                  },
                },
              });
            }
          : undefined
      }
    />
  );
};

export default UsersPage;
