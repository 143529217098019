import { AccountMissingInfos } from "operations";

const hasToRedirectUserToSelfServe = (missingInfos?: AccountMissingInfos[]) => {
  if (missingInfos && missingInfos.length > 0) {
    return true;
  }

  return false;
};

export default hasToRedirectUserToSelfServe;
