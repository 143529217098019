import { Footer } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import BusinessLogo from "../../images/business-logo.svg?react";
import pricing2x from "../../images/pricing-2x.png";
import pricing3x from "../../images/pricing-3x.png";
import pricing from "../../images/pricing.png";

import PriceSelector from "./components/PriceSelector";

interface Props {
  children?: never;
}

const PricingPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const { mixpanel, trackPageView } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  useEffect(() => {
    if (!mixpanel) return;

    trackPageView(PAGE_VIEWED.PRICING_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  return (
    <main className="md:bg-pricing-bg md:bg-pricing flex h-screen flex-col items-center overflow-y-auto">
      <div className="flex h-screen flex-col items-center gap-12 bg-white px-4 pt-8 md:px-6 lg:max-w-2xl">
        <BusinessLogo className="h-11 text-neutral-700" />
        <div className="flex flex-col items-center gap-6">
          <h1 className="mt-8 text-center text-[34px] font-light leading-10 text-neutral-900 md:mt-0">
            {t("pricingPage.pageTitle")}
          </h1>
          <img
            src={pricing}
            srcSet={`${pricing2x} 2x, ${pricing3x} 3x`}
            className="hidden w-auto md:block"
            alt="charging point"
          />
        </div>
        <div className="flex flex-col items-center gap-6 px-4">
          <p className="w-4/5 text-center text-xl font-light text-neutral-600 md:w-full">{t("pricingPage.subtitle")}</p>
          <PriceSelector />
          <span className="text-sm font-normal text-neutral-700">{t("pricingPage.price.hint")}</span>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PricingPage;
