import { LoadingScreen, ErrorScreen } from "components";
import join from "lodash/join";
import { useCreateUserDeletionIntentMutation } from "operations";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";

interface Props {
  children?: never;
}

const UserDeletionIntentNewPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageTitle = t("userDeletionIntentNewPage.pageTitle");
  const currentAccountId = useUser((state) => state.currentAccountId);

  const [error, setError] = useState<string>();

  const [createUserDeletionIntentMutation] = useCreateUserDeletionIntentMutation({
    onCompleted: ({ createUserDeletionIntent: result }) => {
      if (result?.errors && result?.errors.length > 0) {
        setError(join(result.errors, " "));
        return;
      }
      if (result?.userDeletionIntent) {
        setError(undefined);
        navigate(`/user-deletion-intents/${result.userDeletionIntent.id}`, { replace: true });
        return;
      }

      setError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  useEffect(() => {
    if (!currentAccountId) {
      return;
    }

    createUserDeletionIntentMutation({
      variables: {
        input: {
          accountId: currentAccountId,
        },
      },
    });
  }, [createUserDeletionIntentMutation, currentAccountId]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={goBack} />;
  }, [goBack]);

  if (!currentAccountId) {
    return <NoAccountSelectedScreen title={pageTitle} navLeft={navLeft} />;
  }

  if (error) {
    return <ErrorScreen title={pageTitle} error={error} navLeft={navLeft} business />;
  }

  return <LoadingScreen title={pageTitle} navLeft={navLeft} business />;
};

export default UserDeletionIntentNewPage;
