import { FunctionComponent } from "react";

type Props = {
  children?: never;
  label: string;
};

const InvitationRowLabel: FunctionComponent<Props> = ({ label }) => (
  <div className="mb-2">
    <label className="text-label-sm text-neutral-600">{label}</label>
  </div>
);

export default InvitationRowLabel;
