import { differenceInDays } from "date-fns/differenceInDays";
import { differenceInMinutes } from "date-fns/differenceInMinutes";
import { startOfDay } from "date-fns/startOfDay";
import { startOfMinute } from "date-fns/startOfMinute";

import { castDateOrString } from "./castDateOrString";

/**
 * Compute the number of minutes between two dates.
 * @param dateOrString1 first date
 * @param dateOrString2 second date (greater than first date)
 * @returns
 */
export const minutesBetween = (dateOrString1: Date | string, dateOrString2: Date | string) => {
  const date1 = startOfMinute(castDateOrString(dateOrString1));
  const date2 = startOfMinute(castDateOrString(dateOrString2));

  return differenceInMinutes(date2, date1);
};

/**
 * Compute the number of minutes from now and other date.
 * @param dateOrString date
 * @returns
 */
export const minutesFromNow = (dateOrString: Date | string) => {
  const now = startOfMinute(new Date());
  const date = startOfMinute(castDateOrString(dateOrString));

  return minutesBetween(now, date);
};

/**
 * Indicate if there is at least one entire calendar day between now and a date.
 * @param dateOrString date
 * @returns
 */
export const isAtLeastOneEntireDayFromNow = (dateOrString: Date | string) => {
  const now = startOfDay(new Date());
  const date = startOfMinute(castDateOrString(dateOrString));

  return differenceInDays(date, now) > 1;
};
