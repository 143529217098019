import { SupportAction, LoadingScreen, ErrorScreen } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import join from "lodash/join";
import { useUpdateAccountUserPageMutation, useUserInfoPageQuery } from "operations";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ResponsiveBackAction, ResponsiveMenuAction } from "../../components/molecules/BusinessAction";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";
import NotFoundScreen from "../../screens/NotFoundScreen";
import UserNamesScreen from "../../screens/UserNamesScreen";
import UserScreen from "../../screens/UserScreen";

interface Props {
  children?: never;
}

const UserInfoPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const pageTitle = t("userInfoPage.pageTitle");
  const { userId, currentAccountId, currentAccountUserId } = useUser();

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const [updateAccountUserError, setUpdateAccountUserError] = useState<string>();
  const [screen, setScreen] = useState<"USER" | "USER_NAMES">("USER");

  const { data, loading, error } = useUserInfoPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId || !userId,
    variables: {
      accountId: currentAccountId ?? "none",
      accountUserId: currentAccountUserId ?? "",
    },
  });

  const [updateAccountUserMutation] = useUpdateAccountUserPageMutation({
    onCompleted: ({ updateAccountUser: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setUpdateAccountUserError(join(result.errors, " "));
        return;
      }

      if (result?.accountUser) {
        setUpdateAccountUserError(undefined);
        setScreen("USER");
        return;
      }
      setUpdateAccountUserError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setUpdateAccountUserError(error.message);
    },
  });

  const handleClickFullname = useCallback(() => {
    setScreen("USER_NAMES");
  }, []);

  /*
   * Props
   */

  const accountUser = useMemo(() => data?.me?.account?.accountUser ?? undefined, [data]);
  const isProAccount = useMemo(() => data?.me?.account?.pro, [data]);
  const navLeft = useMemo(() => {
    if (screen === "USER_NAMES") {
      return (
        <ResponsiveBackAction
          onClick={() => {
            setScreen("USER");
          }}
        />
      );
    } else {
      return <ResponsiveMenuAction />;
    }
  }, [screen]);

  const navRight = useMemo(() => <SupportAction />, []);

  const errorMessage = useMemo(() => error?.message ?? undefined, [error]);

  //
  // Tracking plan
  //

  const trackPage = useCallback(() => {
    if (screen !== "USER" || !accountUser) return;

    trackPageView(PAGE_VIEWED.USER_INFO_PAGE, mixpanelDynamicProps);
  }, [accountUser, mixpanelDynamicProps, screen, trackPageView]);

  const userNamesScreenProps = useMemo(() => {
    if (screen !== "USER_NAMES" || !accountUser) return;

    const title = t("userInfoPage.usersName.title");
    const { firstname, lastname } = accountUser;

    return {
      actionLabel: t("userInfoPage.usersName.actionLabel"),
      error: updateAccountUserError,
      focusFirstField: false,
      hideLastname: false,
      initialValues: {
        firstname,
        lastname: lastname ?? "",
      },
      isMandatoryLastname: isProAccount,
      navLeft,
      onSubmit: async (firstname: string, lastname: string | undefined) => {
        const accountUserId = currentAccountUserId ?? "";

        await updateAccountUserMutation({
          variables: {
            input: {
              accountUserId,
              firstname,
              lastname,
            },
          },
        });
      },
      subTitle: undefined,
      title,
    };
  }, [
    screen,
    accountUser,
    t,
    updateAccountUserError,
    isProAccount,
    navLeft,
    currentAccountUserId,
    updateAccountUserMutation,
  ]);

  const userScreenProps = useMemo(() => {
    if (screen !== "USER" || !accountUser) return;

    const title = accountUser.fullname;

    return {
      accountUser,
      navLeft,
      navRight,
      onClickFullname: accountUser.canEdit ? handleClickFullname : undefined,
      showAccountDetails: isProAccount,
      showStatus: false,
      title,
      trackPage,
    };
  }, [accountUser, handleClickFullname, isProAccount, navLeft, navRight, screen, trackPage]);

  const commonProps = useMemo(() => {
    return {
      navLeft: <ResponsiveMenuAction />,
      title: pageTitle,
    };
  }, [pageTitle]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen {...commonProps} error={errorMessage} canReloadPage business />;
  }

  if (!currentAccountId) {
    return <NoAccountSelectedScreen {...commonProps} />;
  }

  if (!accountUser) {
    if (loading) return <LoadingScreen {...commonProps} business />;

    return <NotFoundScreen title={t("userInfoPage.notFound")} navLeft={navLeft} />;
  }

  if (userScreenProps) {
    return <UserScreen {...userScreenProps} />;
  }

  if (userNamesScreenProps) {
    return <UserNamesScreen {...userNamesScreenProps} />;
  }

  throw new Error("Invalid state");
};

export default UserInfoPage;
