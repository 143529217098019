import { Button } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: never;
  navLeft?: ReactNode;
  showRedirect?: boolean;
  title?: string;
}

const NotFoundScreen: FunctionComponent<Props> = ({ navLeft, title = "Non trouvé", showRedirect = true }) => {
  const { t } = useTranslation();
  const bottom = showRedirect ? (
    <Button href="/" kind="tertiary" label="Retour à la page d’accueil" size="medium" />
  ) : undefined;

  return (
    <Page navLeft={navLeft} bottom={bottom} title={title}>
      <div>
        <p className="mb-4">{t("notFoundScreen.infos.notFound", { ns: "common" })}</p>
        <p className="mb-4">{t("notFoundScreen.infos.contactUs", { ns: "common" })}</p>
      </div>
    </Page>
  );
};

export default NotFoundScreen;
