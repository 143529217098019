import pull from "lodash/pull";

export enum GeolocationState {
  /** Denied */
  Denied = "denied",
  /** Granted */
  Granted = "granted",
  /** Prompt */
  Prompt = "prompt",
  /** Unknown */
  Unknown = "unknown",
}

type EventHandler = (newGeolocationState: GeolocationState) => void;
const eventHandlers: EventHandler[] = [];

let geolocationStateAtStart = GeolocationState.Unknown;

/**
 * Indicates if the geolocation is possible on this browser.
 */
export const isGeolocationPossible = (): boolean => !!navigator?.geolocation;

/**
 * Get geolocation state from navigator.permissions ("granted", "prompt" or "denied").
 * Else "Unknown", ie on mobile browsers.
 * @returns Promise<GeolocationState>
 */
const getGeolocationState = async (): Promise<GeolocationState> => {
  if (!navigator.permissions) return GeolocationState.Unknown;

  const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
  const { state } = permissionStatus || {};
  if (state === "denied") {
    return GeolocationState.Denied;
  }
  if (state === "granted") {
    return GeolocationState.Granted;
  }
  if (state === "prompt") {
    return GeolocationState.Prompt;
  }

  return GeolocationState.Unknown;
};

/**
 * Get the geolocation state at start/load of the webapp.
 * @returns
 */
export const getGeolocationStateAtStart = (): GeolocationState => geolocationStateAtStart;

/**
 * Add an event handler to be notified when geolocation state has been changed.
 * @param eventHandler
 * @returns
 */
export const onGeolocationAuthorizationChange = (eventHandler: EventHandler): (() => void) => {
  eventHandlers.push(eventHandler);

  return () => {
    pull(eventHandlers, eventHandler);
  };
};

/**
 * Fire geolocation state change to all event handlers.
 * @param newGeolocationState
 */
export const fireGeolocationStateChange = (newGeolocationState: GeolocationState): void => {
  if (import.meta.env.DEV) {
    console.debug(
      `[Geolocation] Firing GeolocationStateChange event with newGeolocationState being ${JSON.stringify(
        newGeolocationState
      )}`
    );
  }

  eventHandlers.forEach((eventHandler) => {
    eventHandler(newGeolocationState);
  });
};

/**
 * Initialize geolocation state at start.
 */
const initGeolocation = async () => {
  if (!isGeolocationPossible()) return;

  geolocationStateAtStart = await getGeolocationState();
  fireGeolocationStateChange(geolocationStateAtStart);
};

initGeolocation();
