import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Hook to known when the component has been mounted/unmounted.
 * Use it like this:
 *    const isMounted = useMounted();
 *
 *    // Check if the component has been mounted
 *    if (isMounted.current)...
 *
 *    // Check if the component has been unmounted
 *    if (!isMounted.current)
 */
export const useMounted = (): MutableRefObject<boolean> => {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
