import { useField } from "formik";
import { FunctionComponent, useCallback } from "react";

import { RadioButtonAction } from "../../atoms/RadioButton";
import ListItem from "../ListItem";

import { CommonListItemFieldProps } from "./ListItemField";

export interface ListItemRadioButtonFieldProps
  extends CommonListItemFieldProps<string, "label" | "onClick" | "trailing" | "trailingIcon"> {
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  value: string;
}

const ListItemRadioButtonField: FunctionComponent<ListItemRadioButtonFieldProps> = ({
  disabled,
  label,
  listItemProps,
  loading = false,
  name,
  onClick,
  validate,
  value,
}) => {
  const [{ value: fieldValue }, , { setValue }] = useField<string>({ name, validate });

  const handleClick = useCallback(() => {
    setValue(value);
    onClick();
  }, [onClick, setValue, value]);

  return (
    <ListItem
      label={label}
      onClick={!disabled ? handleClick : undefined}
      trailing={
        <RadioButtonAction
          checked={value === fieldValue}
          loading={loading}
          value={value}
          disabled={disabled}
          onClick={handleClick}
        />
      }
      {...listItemProps}
    />
  );
};

export default ListItemRadioButtonField;
