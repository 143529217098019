import { Button } from "@webapps/shared/components";
import DownloadQrcode from "images/download-qrcode-without-corners.svg?react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  handleClick?: () => void;
  showDesktopTitle?: boolean;
};

const RedirectToAppBottom: FunctionComponent<Props> = ({ handleClick, showDesktopTitle = true }) => {
  const { t } = useTranslation();

  return !handleClick ? (
    <div className="flex flex-col gap-4">
      {showDesktopTitle && (
        <div className="text-center text-xl font-light text-neutral-700">{t("redirectToMobileApp.desktop.title")}</div>
      )}
      <div className="flex flex-row items-center justify-between gap-6 rounded-3xl border-[1px] border-neutral-300 bg-white px-6 py-4 md:flex">
        <div className="text-label-sm text-left font-normal text-neutral-600">
          {t("redirectToMobileApp.description")}
        </div>
        <DownloadQrcode className="h-[60px] w-[60px] flex-none" />
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4 md:max-w-xs md:self-center">
      <div className="text-base font-normal text-neutral-900">{t("redirectToMobileApp.mobile.title")}</div>
      <Button
        className="w-full"
        label={t(`redirectToMobileApp.mobile.cta`)}
        onClick={handleClick}
        type="button"
        size="large"
      />
    </div>
  );
};

export default RedirectToAppBottom;
