import { isIntercomDefined } from "@webapps/shared/libs/src/intercom";
import { ErrorScreen, LoadingScreen, Page } from "components";
import { useMounted, useTimer } from "hooks";
import { ComponentProps, FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntercomProvider, useIntercom } from "react-use-intercom";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  children?: never;
  onShow?: () => void;
  userId?: string | undefined;
  userName?: string | undefined;
  userPhoneNumber?: string | undefined;
}

const SupportChatScreen: FunctionComponent<Props> = ({ navLeft, onShow, userId, userName, userPhoneNumber }) => {
  const { t } = useTranslation();
  const isMounted = useMounted();
  const intercomIdFromEnv = import.meta.env.VITE_INTERCOM_APP_ID;

  if (!intercomIdFromEnv) {
    throw new Error("Missing APP ID for Intercom SDK");
  }

  const canHandleShow = useRef<boolean>(true);

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(!isIntercomDefined());

  const onElapsed = useCallback(() => {
    setLoading(false);
    setError(t("supportChatPage.error", { ns: "common" }));
  }, [t]);

  const { stopTimer } = useTimer({
    onElapsed,
    totalDurationInMilliseconds: 10000,
  });

  const handleShow = useCallback(() => {
    if (!isMounted.current || !canHandleShow.current) return;

    canHandleShow.current = false;

    stopTimer();
    setLoading(false);
    onShow?.();
  }, [isMounted, onShow, stopTimer]);

  useEffect(() => {
    if (isIntercomDefined()) {
      handleShow();
    }
  }, [handleShow]);

  if (error) {
    return <ErrorScreen title={t("supportChatPage.pageTitle", { ns: "common" })} error={error} navLeft={navLeft} />;
  }

  return (
    <IntercomProvider
      appId={intercomIdFromEnv}
      autoBoot
      autoBootProps={{ hideDefaultLauncher: true, name: userName, phone: userPhoneNumber, userId }}
      onShow={handleShow}
    >
      <Chat loading={loading} navLeft={navLeft} />
    </IntercomProvider>
  );
};

interface ChatProps extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  children?: never;
  loading: boolean;
}

const Chat: FunctionComponent<ChatProps> = ({ loading, navLeft }) => {
  const { show } = useIntercom();
  const { t } = useTranslation();

  useEffect(() => {
    show();
  }, [show]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <Page navLeft={navLeft} title={t("supportChatPage.pageTitle", { ns: "common" })}></Page>;
};

export default SupportChatScreen;
