import classNames from "classnames";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowDown from "../../../images/icons/ri/arrow-down-s-line.svg?react";
import FlagBe from "../../../images/icons/ri/flag-be.svg?react";
import FlagFr from "../../../images/icons/ri/flag-fr.svg?react";
import FlagIt from "../../../images/icons/ri/flag-it.svg?react";
import FlagLu from "../../../images/icons/ri/flag-lu.svg?react";

const countries = ["fr", "be", "it", "lu"] as const;
type Country = (typeof countries)[number];

const defaultCountry = "fr";

const flagComponent = { be: FlagBe, fr: FlagFr, it: FlagIt, lu: FlagLu };

const PriceSelector: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const [showPrices, setShowPrices] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>(defaultCountry);
  const SelectedFlag = flagComponent[selectedCountry];

  return (
    <div className="relative flex h-[168px] w-full items-start justify-center gap-4 rounded-[44px] border border-[#CAFCEC] bg-[#E7FEFB] px-1 pb-1 pt-2 md:h-[92px] md:w-[492px] md:items-center md:justify-end md:rounded-[100px]">
      {showPrices && (
        <div className="text-business-sm absolute bottom-[100px] left-0 flex min-h-[160px] w-full flex-col justify-center rounded-[20px] border border-[#CAFCEC] bg-[#E7FEFB] py-4 md:w-[220px]">
          {countries.map((country) => {
            const Flag = flagComponent[country];
            return (
              <button
                key={country}
                className="flex cursor-pointer items-center p-2 hover:bg-[#87F9D3]"
                onClick={() => {
                  setShowPrices(false);
                  setSelectedCountry(country);
                }}
              >
                <Flag className="ml-2 mr-2 w-7" />
                {t(`pricingPage.price.${country}.text`)}
              </button>
            );
          })}
        </div>
      )}
      <button
        onClick={() => setShowPrices(true)}
        className="text-business-sm absolute bottom-1 flex h-[84px] w-[98%] items-center justify-center rounded-[44px] border border-[#87F9D3] bg-[#87F9D3] md:inset-[3px] md:w-[220px] md:rounded-full"
      >
        <SelectedFlag className="mr-2 w-7" />
        {t(`pricingPage.price.${selectedCountry}.text`)}
        <ArrowDown
          className={classNames("w-4 translate-y-[1px] transition duration-200", {
            "rotate-180": showPrices,
          })}
        />
      </button>
      <div className="flex flex-col items-center md:mr-[50px]">
        <span className="text-xs font-normal text-neutral-700">{t("pricingPage.price.maxPrice")}</span>
        <span className="text-2xl font-normal text-neutral-700">{t(`pricingPage.price.${selectedCountry}.value`)}</span>
        <span className="text-sm font-normal text-neutral-700">{t("pricingPage.price.fast")}</span>
      </div>
    </div>
  );
};

export default PriceSelector;
