import { Button, MultilineInputField } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import { Form, Formik } from "formik";
import { ComponentProps, FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";

interface FormValues {
  comment: string | undefined;
}

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft" | "navRight"> {
  children?: never;
  error?: string | undefined;
  message: string | undefined;
  onKeepAccount: () => void;
  onSubmit: (comment: string | undefined) => Promise<void>;
  userDeletionIntentId: string;
}

const Screen: FunctionComponent<Props> = ({
  error,
  message,
  navLeft,
  navRight,
  onKeepAccount,
  onSubmit,
  userDeletionIntentId,
}) => {
  const { t } = useTranslation();
  const submit = async (values: FormValues) => {
    await onSubmit(values.comment);
  };

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  useEffect(() => {
    trackPageView(PAGE_VIEWED.USER_DELETION_INTENT_PAGE, {
      ...mixpanelDynamicProps,
      user_deletion_intent_id: userDeletionIntentId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{
        comment: undefined,
      }}
      onSubmit={submit}
      validateOnBlur={false}
    >
      {({ handleSubmit, isSubmitting, isValidating }) => {
        const bottom = (
          <>
            {error ? <div className="text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full"
              kind="tertiary"
              label={t("userDeletionIntentPage.cta.keep")}
              onClick={onKeepAccount}
              size="large"
              type="submit"
            />

            <Button
              className="w-full"
              disabled={isValidating || isSubmitting}
              label={t("userDeletionIntentPage.cta.delete")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <Page
            bottom={bottom}
            navLeft={navLeft}
            navRight={navRight}
            title={t("userDeletionIntentPage.pageTitle")}
            business
          >
            <Form className="flex flex-col gap-6">
              <div className="text-medium font-normal text-black">{message}</div>

              <MultilineInputField
                name="comment"
                label={t("userDeletionIntentPage.inputLabel")}
                inputProps={{
                  rows: 5,
                }}
              />
            </Form>
          </Page>
        );
      }}
    </Formik>
  );
};

export default Screen;
