import { ActionModal } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import binoculars from "@webapps/shared/images/binoculars.png";
import WarningIcon from "@webapps/shared/images/icons/ri/warning-triangle.svg?react";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useBackInviteModal } from "../../../hooks/useBackInviteModal";
import { useBackInviteModalType } from "../../../hooks/useBackInviteModalType";
import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import { useMobileMenu } from "../../../hooks/useMobileMenu";

const BackInviteModal: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const { isBackModalDisplayed, setBackModalDisplayed, to } = useBackInviteModal();
  const closeMenu = useMobileMenu((state) => state.closeMenu);
  const modalType = useBackInviteModalType();
  const navigate = useNavigate();
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const handleCancel = () => {
    trackButtonClicked(BUTTON_CLICKED.SECONDARY_BUTTON_ALERT_MODAL, { ...mixpanelDynamicProps, context: modalType });
    if (to) {
      navigate(to);
      closeMenu();
    } else {
      navigate("/users");
    }
  };

  const handleConfirm = () => {
    trackButtonClicked(BUTTON_CLICKED.CTA_ALERT_MODAL, {
      ...mixpanelDynamicProps,
      context: modalType,
    });
    setBackModalDisplayed(false);
  };

  const handleClose = () => {
    trackButtonClicked(BUTTON_CLICKED.CLOSE_ALERT_MODAL, {
      ...mixpanelDynamicProps,
      context: modalType,
    });
    setBackModalDisplayed(false);
  };

  // TODO : fix this, the modal is displayed when the page is loaded
  useEffect(() => {
    if (isBackModalDisplayed) {
      setBackModalDisplayed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return modalType ? (
    <ActionModal
      onClose={handleClose}
      isOpen={isBackModalDisplayed}
      title={t(`invitationsPage.invitationsScreen.${modalType}.title`)}
      subtitle={t(`invitationsPage.invitationsScreen.${modalType}.subtitle`)}
      cta={t(`invitationsPage.invitationsScreen.${modalType}.cta`)}
      cancelCta={t(`invitationsPage.invitationsScreen.${modalType}.cancelCta`)}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      icon={modalType === "savedInvites" ? WarningIcon : undefined}
      image={modalType === "noInvite" ? binoculars : undefined}
    />
  ) : null;
};

export default BackInviteModal;
