import classNames from "classnames";
import { StationPublicStatus } from "operations";
import { FunctionComponent } from "react";

import Available from "../../../images/icons/pin/map-pin-available.svg?react";
import ComingSoon from "../../../images/icons/pin/map-pin-coming-soon.svg?react";
import Selected from "../../../images/icons/pin/map-pin-selected.svg?react";
import Unavailable from "../../../images/icons/pin/map-pin-unavailable.svg?react";

export type MapPinProps = {
  className?: string;
  selected?: boolean;
  status: StationPublicStatus;
};

const getPinIconFromStatus = (status?: StationPublicStatus, selected?: boolean) =>
  selected
    ? Selected
    : status === StationPublicStatus.Available
      ? Available
      : status === StationPublicStatus.ComingSoon
        ? ComingSoon
        : Unavailable;

const MapPin: FunctionComponent<MapPinProps> = ({ selected, status }) => {
  const PinIcon = getPinIconFromStatus(status, selected);
  return (
    <div
      className={classNames("relative flex h-[59px] w-[59px] cursor-pointer flex-col items-center", {
        "duration-pin-container-in delay-pin-container ease-out": selected,
        "duration-pin-container-out ease-in": !selected,
      })}
    >
      <PinIcon
        className={classNames("h-full w-full", {
          "transition-size duration-pin-in ease-out": selected,
          "transition-size duration-pin-out ease-in": !selected,
        })}
      />

      <div className="h-2 w-2 rounded-full border border-white bg-neutral-700" />
    </div>
  );
};

export default MapPin;
