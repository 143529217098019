import classNames from "classnames";
import ArrowDownIcon from "images/icons/ri/chevron-down.svg?react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: never;
  firstRowIndex: number;
  lastRowIndex: number;
  onLeftClick: () => void;
  onRightClick: () => void;
  rowCount: number;
}

const Pagination: FunctionComponent<Props> = ({ firstRowIndex, lastRowIndex, rowCount, onRightClick, onLeftClick }) => {
  const { t } = useTranslation();
  const leftIconClassName = classNames("h-4 w-4 rotate-90", {
    "text-icon-default": firstRowIndex !== 1,
    "text-icon-disabled pointer-events-none": firstRowIndex === 1,
  });

  const rightIconClassName = classNames("h-4 w-4  -rotate-90", {
    "text-icon-default": lastRowIndex !== rowCount,
    "text-icon-disabled pointer-events-none": lastRowIndex === rowCount,
  });

  return (
    <div
      className={classNames(
        "border-primary-default flex w-fit min-w-[155px] justify-between rounded-sm border border-opacity-10 bg-white text-sm transition-all"
      )}
    >
      <div className="flex-1 p-2">
        <span className="font-bold">{`${firstRowIndex}-${lastRowIndex} `}</span>
        <span className="font-normal">{t("chargesPage.table.of", { total: rowCount })}</span>
      </div>

      <div
        className={classNames("flex w-8 items-center justify-center border border-y-0 border-x-neutral-100", {
          "cursor-default": firstRowIndex === 1,
          "cursor-pointer": firstRowIndex !== 1,
        })}
      >
        <ArrowDownIcon className={leftIconClassName} onClick={onLeftClick} />
      </div>
      <div
        className={classNames("flex w-8 items-center justify-center", {
          "cursor-default": lastRowIndex === rowCount,
          "cursor-pointer": lastRowIndex !== rowCount,
        })}
      >
        <ArrowDownIcon className={rightIconClassName} onClick={onRightClick} />
      </div>
    </div>
  );
};

export default Pagination;
