import { ErrorScreen, LoadingScreen, SupportScreen } from "@webapps/shared/components";
import filter from "lodash/filter";
import map from "lodash/map";
import { SupportMethod, useSupportPageQuery } from "operations";
import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";

interface Props {
  children?: never;
}

const SupportPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t("supportPage.pageTitle", { ns: "common" });
  const { data, loading, error } = useSupportPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  /*
   * Props
   */

  const supportSectionItems = useMemo(
    () =>
      data?.supportSections
        ? map(data?.supportSections, (supportSection) => {
            return {
              children: filter(
                supportSection.children,
                (child) => child.__typename === "SupportMethod"
              ) as SupportMethod[],
              label: supportSection.label,
            };
          })
        : undefined,
    [data]
  );

  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={goBack} />;
  }, [goBack]);

  const errorMessage = useMemo(() => error?.message ?? undefined, [error]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen title={pageTitle} navLeft={navLeft} error={errorMessage} canReloadPage business />;
  }

  if (!supportSectionItems && loading) {
    return <LoadingScreen navLeft={navLeft} business />;
  }

  return <SupportScreen supportSectionItems={supportSectionItems} navLeft={navLeft} />;
};

export default SupportPage;
