import { BottomSheet, ListItemRadioButtonField } from "components";
import { Formik } from "formik";
import { AccountUserRole } from "operations";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import UserIcon from "../../../../images/icons/ri/user-3-fill.svg?react";
import UserStarIcon from "../../../../images/icons/ri/user-star-fill.svg?react";

interface FormValues {
  role: AccountUserRole;
}

interface Props {
  onClose: () => void;
  onConfirm: (role: AccountUserRole) => Promise<boolean>;
  role: AccountUserRole;
  subTitle?: string | undefined;
  title?: string | undefined;
}

const Sheet: FunctionComponent<Props> = ({ onClose, onConfirm, role, subTitle, title }) => {
  const { t } = useTranslation();
  const submit = useCallback(
    async (values: FormValues) => {
      const result = await onConfirm(values.role);
      if (result) {
        onClose();
      }
    },
    [onClose, onConfirm]
  );

  return (
    <Formik
      initialValues={{
        role,
      }}
      onSubmit={submit}
    >
      {({ handleSubmit, isSubmitting, isValidating, values }) => {
        return (
          <BottomSheet
            onClose={onClose}
            title={title ?? t("changeRoleBottomSheet.title")}
            subTitle={subTitle}
            rawContent
          >
            <div className="py-4-safe">
              <ListItemRadioButtonField
                name="role"
                label={t("changeRoleBottomSheet.admin.label")}
                loading={(isSubmitting || isValidating) && values.role === AccountUserRole.Admin}
                onClick={handleSubmit}
                value={AccountUserRole.Admin}
                listItemProps={{
                  leadingIcon: UserStarIcon,
                  position: "none",
                  subLabel: t("changeRoleBottomSheet.admin.sublabel"),
                }}
              />

              <ListItemRadioButtonField
                name="role"
                label={t("changeRoleBottomSheet.member.label")}
                loading={(isSubmitting || isValidating) && values.role === AccountUserRole.Member}
                onClick={handleSubmit}
                value={AccountUserRole.Member}
                listItemProps={{
                  leadingIcon: UserIcon,
                  position: "none",
                  subLabel: t("changeRoleBottomSheet.member.sublabel"),
                }}
              />
            </div>
          </BottomSheet>
        );
      }}
    </Formik>
  );
};

export default Sheet;
