import { useMediaQuery } from "@react-hook/media-query";
import { Tooltip } from "@webapps/shared/components";
import { ReactNode, useRef } from "react";

import CellContent from "../CellContent";

const CellContentWithTooltip = ({
  title,
  value,
  subtitle,
  width,
}: {
  subtitle?: string;
  title: ReactNode;
  value: string;
  width: number;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentWidth = contentRef.current?.clientWidth;
  const hasTooltip = contentWidth && contentWidth < width;
  const smScreen = useMediaQuery("only screen and (min-width: 640px)");

  const content = (
    <div className="flex flex-col whitespace-pre text-xs">
      <span className="font-bold">{title}</span>
      <span className="font-normal">{value}</span>
      {!!subtitle && <span className="text-xs font-normal">{subtitle}</span>}
    </div>
  );

  return (
    <div ref={contentRef}>
      {hasTooltip && smScreen ? (
        <Tooltip content={content}>
          <CellContent value={value} subtitle={subtitle} />
        </Tooltip>
      ) : (
        <CellContent value={value} subtitle={subtitle} />
      )}
    </div>
  );
};

export default CellContentWithTooltip;
