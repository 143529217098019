const CellContent = ({ value, subtitle }: { subtitle?: string; value: string }) => {
  return (
    <div className="flex flex-col truncate">
      <div className="truncate">{value}</div>
      {!!subtitle && <span className="text-secondary-default truncate text-xs font-normal">{subtitle}</span>}
    </div>
  );
};

export default CellContent;
