import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import { Page, LoadingScreen } from "components";
import { useDashboardPageQuery } from "operations";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";

import { ResponsiveMenuAction } from "../../components/molecules/BusinessAction";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";
import NotFoundScreen from "../../screens/NotFoundScreen";

interface Props {
  children?: never;
}

const DashboardPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const currentAccountId = useUser((state) => state.currentAccountId);
  const { data, loading } = useDashboardPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId,
    variables: {
      accountId: currentAccountId ?? "none",
    },
  });
  const dashboardIframeUrl = data?.me?.account?.dashboardIframeUrl;

  const pageTitle = t("dashboardPage.pageTitle");

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  useEffect(() => {
    trackPageView(PAGE_VIEWED.DASHBOARD_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonProps = useMemo(() => {
    return {
      navLeft: <ResponsiveMenuAction />,
      title: pageTitle,
    };
  }, [pageTitle]);

  if (!currentAccountId) {
    return <NoAccountSelectedScreen {...commonProps} />;
  }

  if (!dashboardIframeUrl) {
    if (loading) return <LoadingScreen {...commonProps} business stretchContentOnLargeScreen />;

    return <NotFoundScreen navLeft={<ResponsiveMenuAction />} title={t("dashboardPage.notFound")} />;
  }
  return (
    <Page {...commonProps} rawContent stretchContentOnLargeScreen business>
      <Iframe className="md:px-6-safe" url={dashboardIframeUrl || ""} height="100%" />
    </Page>
  );
};

export default DashboardPage;
