import { BUSINESS_TOKEN_STORAGE_KEY } from "constants/storage";

import { useLocalStorage } from "hooks";
import { getCurrentLanguage } from "utils";

const useMixpanelDynamicProps = () => {
  const language = getCurrentLanguage();
  const [sessionToken] = useLocalStorage(BUSINESS_TOKEN_STORAGE_KEY);

  return {
    language,
    session_token: sessionToken ?? null,
  };
};

export default useMixpanelDynamicProps;
