import { useRef, useState } from "react";
import { Map } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";

export type MapboxGLComponent = typeof Map;

export const mapAccessToken = import.meta.env.VITE_MAPBOX_TOKEN;

export type LogoPosition = "top-left" | "top-right" | "bottom-left" | "bottom-right";

function loadMapboxGl() {
  return import(/* webpackChunkName: "react-map-gl" */ "react-map-gl").then(({ default: MapboxGL, Marker }) => {
    return { MapboxGL, Marker };
  });
}

export function useMapboxGl() {
  const importer = useRef(loadMapboxGl());
  const [imports, setImports] = useState<Awaited<ReturnType<typeof loadMapboxGl>>>();

  if (!imports) {
    importer.current.then((imports) => {
      setImports(imports);
    });

    return { loaded: false } as const;
  }

  return { ...imports, loaded: true } as const;
}

export default loadMapboxGl;
