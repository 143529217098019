import classNames from "classnames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { castDateOrString } from "utils";

import BoltIcon from "../../../images/icons/ri/bolt.svg?react";

export type MapHourPinProps = {
  className?: string;
  confirmed: boolean;
  hour?: Date | string | undefined;
};

const MapHourPin: FunctionComponent<MapHourPinProps> = ({ className, confirmed = false, hour }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("relative inline-block", className)}>
      <div className="flex flex-col items-center px-3 py-1 transition-all">
        <div className="relative flex h-12 w-[92px] items-center justify-center">
          <svg
            className={classNames("absolute inset-0", {
              "text-brand-600": confirmed,
              "text-neutral-700": !confirmed,
            })}
            viewBox="0 0 92 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_613_4590" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40H37L43.1716 46.1716C44.7337 47.7337 47.2663 47.7337 48.8284 46.1716L55 40H72C83.0457 40 92 31.0457 92 20C92 8.95431 83.0457 0 72 0H20Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40H37L43.1716 46.1716C44.7337 47.7337 47.2663 47.7337 48.8284 46.1716L55 40H72C83.0457 40 92 31.0457 92 20C92 8.95431 83.0457 0 72 0H20Z"
              fill="currentColor"
              fillOpacity="0.9"
            />
            <path
              d="M37 40L37.7071 39.2929L37.4142 39H37V40ZM43.1716 46.1716L43.8787 45.4645L43.1716 46.1716ZM48.8284 46.1716L49.5355 46.8787L48.8284 46.1716ZM55 40V39H54.5858L54.2929 39.2929L55 40ZM1 20C1 9.50659 9.50659 1 20 1V-1C8.40202 -1 -1 8.40202 -1 20H1ZM20 39C9.50659 39 1 30.4934 1 20H-1C-1 31.598 8.40202 41 20 41V39ZM37 39H20V41H37V39ZM43.8787 45.4645L37.7071 39.2929L36.2929 40.7071L42.4645 46.8787L43.8787 45.4645ZM48.1213 45.4645C46.9497 46.636 45.0503 46.636 43.8787 45.4645L42.4645 46.8787C44.4171 48.8313 47.5829 48.8313 49.5355 46.8787L48.1213 45.4645ZM54.2929 39.2929L48.1213 45.4645L49.5355 46.8787L55.7071 40.7071L54.2929 39.2929ZM72 39H55V41H72V39ZM91 20C91 30.4934 82.4934 39 72 39V41C83.598 41 93 31.598 93 20H91ZM72 1C82.4934 1 91 9.50659 91 20H93C93 8.40202 83.598 -1 72 -1V1ZM20 1H72V-1H20V1Z"
              fill="currentColor"
              mask="url(#path-1-inside-1_613_4590)"
            />
          </svg>

          <div className="z-0 flex justify-between gap-1 pb-1.5">
            <BoltIcon
              className={classNames("h-6 w-6 flex-none", {
                "text-neutral-900": confirmed,
                "text-white": !confirmed,
              })}
            />

            <span
              className={classNames("text-base font-normal", {
                "text-neutral-700": confirmed,
                "text-white": !confirmed,
              })}
            >
              {hour
                ? t("format.time", {
                    formatParams: {
                      val: { hour: "2-digit", minute: "numeric" },
                    },
                    ns: "common",
                    val: castDateOrString(hour),
                  })
                : t("unknown.questionMark", { ns: "common" })}
            </span>
          </div>
        </div>

        <div className="h-2 w-2 rounded-full border border-white bg-neutral-700" />
      </div>
    </div>
  );
};

export default MapHourPin;
