import { Action, MenuAction } from "@webapps/shared/components";
import { useNotification } from "hooks";
import clamp from "lodash/clamp";
import compact from "lodash/compact";
import find from "lodash/find";
import join from "lodash/join";
import round from "lodash/round";
import split from "lodash/split";
import { NotificationCategoryEnum, useStationsPageQuery, useStationsPageSpotSubscription } from "operations";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ResponsiveCloseAction } from "../../components/molecules/BusinessAction";
import { MapLocation, Mapbox, MapboxEaseToOptions } from "../../components/organisms/Map";
import { DEFAULT_MAP_ZOOM } from "../../components/organisms/Map/constants";
import { useCurrentLocation } from "../../hooks/useCurrentLocation";
import useGeolocation from "../../hooks/useGeolocation";
import { useMobileMenu } from "../../hooks/useMobileMenu";
import { useUser } from "../../hooks/useUser";
import RefreshIcon from "../../images/icons/ri/refresh.svg?react";
import GeolocationAuthorizeScreen from "../../screens/GeolocationAuthorizeScreen";
import GeolocationDeniedScreen from "../../screens/GeolocationDeniedScreen";
import { fireGeolocationStateChange, GeolocationState, isGeolocationPossible } from "../../services/geolocation";

import * as homePageConstClass from "./constants";
import Screen from "./screens/Screen";

interface Props {
  children?: never;
}

const HomePage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const currentAccountId = useUser((state) => state.currentAccountId);
  const openMenu = useMobileMenu((state) => state.openMenu);
  const setCurrentLocation = useCurrentLocation((state) => state.setCurrentLocation);
  const currentLocation = useCurrentLocation((state) => state.currentLocation);
  const { geolocationState, watchGeolocationPosition } = useGeolocation(setCurrentLocation);

  const [mapboxEaseToOptionsOnLoad, setMapboxEaseToOptionsOnLoad] = useState<MapboxEaseToOptions>();

  const mapRef = useRef<Mapbox | null>(null);

  const setNotification = useNotification((state) => state.setNotification);

  const [mustMoveToCurrentLocation, setMustMoveToCurrentLocation] = useState(false);
  const [askToAuthorizeGeolocation, setAskToAuthorizeGeolocation] = useState(false);
  const [displayGeolocationDeniedScreen, setDisplayGeolocationDeniedScreen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStationId = searchParams.get("station_id");
  const setSelectedStationId = useCallback(
    (stationId: string | undefined) => {
      searchParams.set("station_id", stationId ?? "");
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const mapEaseTo = useCallback(({ center, zoom }: MapboxEaseToOptions) => {
    if (!mapRef.current) {
      setMapboxEaseToOptionsOnLoad({ center, zoom });
      return;
    }
    setMapboxEaseToOptionsOnLoad(undefined);

    mapRef.current?.easeTo({
      center,
      zoom,
    });
  }, []);

  const {
    data: stationsData,
    error: stationsError,
    refetch: refetchStations,
  } = useStationsPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId: currentAccountId,
    },
  });

  const errorMessage = useMemo(() => stationsError?.message ?? undefined, [stationsError]);

  /*
   * Location
   */
  // const [locationParam, setLocationParam] = useQueryParam("loc", ["station_id"], "1.994000,47.029000,4.00");
  const [locationParam, setLocationParamRaw] = useState(homePageConstClass.DEFAULT_LOCATION_PARAM);
  const setLocationParam = useCallback(
    (value: string | undefined) => setLocationParamRaw(value ?? homePageConstClass.DEFAULT_LOCATION_PARAM),
    []
  );
  const [mapCenter, mapZoom] = useMemo(() => parseLocationParam(locationParam), [locationParam]);
  const setLocation = useCallback(
    (center: MapLocation | undefined, zoom: number | undefined) => {
      setLocationParam(dumpLocationParam(center, zoom));
    },
    [setLocationParam]
  );

  /*
   * Props
   */
  const geolocationAuthorizeNavLeft = useMemo(
    () => (
      <ResponsiveCloseAction
        onClick={() => {
          setAskToAuthorizeGeolocation(false);
        }}
      />
    ),
    []
  );
  const geolocationDeniedNavLeft = useMemo(
    () => <ResponsiveCloseAction onClick={() => setDisplayGeolocationDeniedScreen(false)} />,
    []
  );

  const stations = useMemo(() => compact(stationsData?.stations.nodes ?? []), [stationsData]);

  useStationsPageSpotSubscription({
    skip: stations.length === 0,
  });

  const onStationZoom = useCallback(
    (stationLocation: MapLocation) => {
      setLocation(stationLocation, DEFAULT_MAP_ZOOM);
    },
    [setLocation]
  );

  const onLocalize = useCallback(async () => {
    if (!isGeolocationPossible()) return;

    if (geolocationState === GeolocationState.Granted) {
      setMustMoveToCurrentLocation(true);
    } else if (geolocationState === GeolocationState.Denied) {
      setDisplayGeolocationDeniedScreen(true);
    } else {
      setAskToAuthorizeGeolocation(true);
    }
  }, [geolocationState]);

  const onAuthorizeGeolocation = useCallback(() => {
    setAskToAuthorizeGeolocation(false);
    fireGeolocationStateChange(GeolocationState.Granted);
  }, []);

  const homeScreenProps = useMemo(() => {
    return {
      mapCenter,
      mapZoom,
      navLeft: <MenuAction onClick={openMenu} size="large" className="shadow-sm" />,
      navRight: <Action icon={RefreshIcon} onClick={refetchStations} size="large" className="shadow-sm" />,
      onLocalize,
      onMapLoaded: (map: Mapbox) => {
        mapRef.current = map;
        if (mapboxEaseToOptionsOnLoad) {
          mapEaseTo(mapboxEaseToOptionsOnLoad);
        }
      },
      onMapMoveEnd: setLocation,
      onStationSelect: setSelectedStationId,
      onStationZoom,
      selectedStationId: selectedStationId ?? undefined,
      stations,
    };
  }, [
    mapCenter,
    mapZoom,
    refetchStations,
    onLocalize,
    setLocation,
    setSelectedStationId,
    onStationZoom,
    selectedStationId,
    stations,
    mapboxEaseToOptionsOnLoad,
    mapEaseTo,
    openMenu,
  ]);

  useEffect(() => {
    if (!errorMessage) return;
    setNotification({
      message: errorMessage,
      title: t("notification.error"),
      type: NotificationCategoryEnum.Error,
    });
  }, [errorMessage, setNotification, t]);

  useEffect(() => {
    if (!isGeolocationPossible()) return;

    if (geolocationState === GeolocationState.Unknown) {
      watchGeolocationPosition();
    }
  }, [geolocationState, watchGeolocationPosition]);

  useEffect(() => {
    if (currentLocation && (locationParam === homePageConstClass.DEFAULT_LOCATION_PARAM || mustMoveToCurrentLocation)) {
      setMustMoveToCurrentLocation(false);
      mapEaseTo({ center: currentLocation, zoom: clamp(mapZoom ?? 9, 9, 10) });
    }
  }, [currentLocation, locationParam, mapZoom, mapEaseTo, mustMoveToCurrentLocation, setLocation]);

  useEffect(() => {
    if (!selectedStationId) return;

    const station = find(stations, (s) => s.id === selectedStationId);
    if (!station) return;

    mapRef.current?.easeTo({
      center: [station.longitude, station.latitude],
      zoom: mapZoom,
    });
  }, [mapZoom, selectedStationId, setLocation, stations]);

  if (askToAuthorizeGeolocation) {
    return <GeolocationAuthorizeScreen navLeft={geolocationAuthorizeNavLeft} onAuthorize={onAuthorizeGeolocation} />;
  }

  if (displayGeolocationDeniedScreen) {
    return <GeolocationDeniedScreen navLeft={geolocationDeniedNavLeft} />;
  }

  return <Screen {...homeScreenProps} />;
};

export default HomePage;

const LOCATION_PARAM_SEPARATOR = ",";

const parseLocationParamMember = (raw: string | undefined, precision: number): number | undefined => {
  if (!raw) {
    return;
  }

  const value = parseFloat(raw);

  if (isNaN(value)) {
    return;
  }

  return round(value, precision);
};

const parseLocationParam = (param: string): [center: MapLocation | undefined, zoom: number | undefined] => {
  const [rawLongitude, rawLatitude, rawZoom] = split(param, LOCATION_PARAM_SEPARATOR);

  const longitude = parseLocationParamMember(rawLongitude, 6);
  const latitude = parseLocationParamMember(rawLatitude, 6);
  const zoom = parseLocationParamMember(rawZoom, 2);

  if (longitude && latitude) {
    return [[longitude, latitude], zoom];
  }

  return [undefined, undefined];
};

const dumpLocationParam = (center: MapLocation | undefined, zoom: number | undefined): string | undefined => {
  if (!center) {
    return;
  }

  const stack: string[] = [];

  stack.push(round(center[0], 5).toString());
  stack.push(round(center[1], 5).toString());

  if (zoom) {
    stack.push(round(zoom, 2).toString());
  }

  return join(stack, LOCATION_PARAM_SEPARATOR);
};
