import classNames from "classnames";
import { Dot } from "components";
import { StationPricingDetail, StationPublicStatus, StationStatus } from "operations";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency, formatDistance, formatPower } from "utils";

import BatteryIcon from "../../../images/icons/ri/bolt.svg?react";
import DistanceIcon from "../../../images/icons/ri/distance.svg?react";
import ParkingOutOfServiceIcon from "../../../images/icons/ri/parking-out-of-service.svg?react";
import ParkingIcon from "../../../images/icons/ri/parking.svg?react";
import ParkingAvailableIcon from "../../../images/icons/ri/plug.svg?react";
import ParkingComingSoon from "../../../images/icons/ri/timer.svg?react";
import WalletIcon from "../../../images/icons/ri/wallet.svg?react";
import StationStatusSummaryItem from "../../molecules/StationStatusSummaryItem";
import StationSummaryItem from "../../molecules/StationSummaryItem";

import { useParkingLabel } from "./hooks";

interface Props {
  availableSpotsCount?: number;
  className?: string | undefined;
  comingSoonLabel?: string | null;
  distance?: number | undefined;
  fastSpeedPricingDetail?: StationPricingDetail | null;
  nextClosing?: string | null | undefined;
  nextOpening?: string | null | undefined;
  onClickDirection?: () => void;
  parkingTypeLabel?: string | null;
  publicAvailablePower?: number | null;
  publicStatus?: StationPublicStatus;
  showAvailableSpots?: boolean;
  showHours?: boolean;
  status?: StationStatus;
}

const StationSummary: FunctionComponent<Props> = ({
  className,
  comingSoonLabel,
  distance,
  fastSpeedPricingDetail,
  publicAvailablePower,
  parkingTypeLabel,
  availableSpotsCount,
  onClickDirection,
  nextClosing,
  nextOpening,
  publicStatus,
  showAvailableSpots = true,
  showHours = true,
  status,
}) => {
  const { t } = useTranslation();
  const distanceLabel =
    distance === undefined || distance === null ? t("unknown.dash", { ns: "common" }) : formatDistance(distance);
  const parkingLabel = useParkingLabel(publicStatus, availableSpotsCount, status, nextOpening, comingSoonLabel);

  const parkingIcon = useMemo(() => {
    if (publicStatus === StationPublicStatus.ComingSoon) {
      return ParkingComingSoon;
    }
    if (publicStatus === StationPublicStatus.Available) {
      return ParkingAvailableIcon;
    }

    return ParkingOutOfServiceIcon;
  }, [publicStatus]);

  const stationClosed = useMemo(() => {
    return status === StationStatus.Closed || status === StationStatus.OpeningSoon;
  }, [status]);

  const stationComingSoon = useMemo(() => {
    return publicStatus === StationPublicStatus.ComingSoon;
  }, [publicStatus]);

  return (
    <div className={classNames("flex flex-col gap-4", className)}>
      <div className="flex flex-row flex-wrap items-center gap-1">
        <StationSummaryItem
          icon={ParkingIcon}
          label={parkingTypeLabel ?? t("unknown.questionMark", { ns: "common" })}
        />
        <Dot />
        <StationSummaryItem
          icon={BatteryIcon}
          label={
            publicAvailablePower !== null && publicAvailablePower !== undefined
              ? t("stationSummary.powerLabel", { power: formatPower(publicAvailablePower) })
              : t("unknown.questionMark", { ns: "common" })
          }
          className="whitespace-nowrap"
        />
        <Dot />
        <div
          className={classNames("", {
            "cursor-pointer": !!onClickDirection,
          })}
          onClick={onClickDirection}
        >
          <StationSummaryItem icon={DistanceIcon} label={distanceLabel} className="whitespace-nowrap" />
        </div>
      </div>

      {(showAvailableSpots || showHours || !!fastSpeedPricingDetail) && (
        <div className="flex flex-col gap-0.5">
          {!!fastSpeedPricingDetail && (
            <StationSummaryItem
              icon={WalletIcon}
              label={`${formatCurrency({
                currency: fastSpeedPricingDetail.currency,
                value: fastSpeedPricingDetail.priceCents,
              })} / kWh`}
            />
          )}

          {showHours && !stationComingSoon && (
            <StationStatusSummaryItem
              status={status ?? StationStatus.Opened}
              nextClosing={nextClosing}
              nextOpening={nextOpening}
            />
          )}

          {showAvailableSpots && (!stationClosed || stationComingSoon) && (
            <StationSummaryItem
              icon={parkingIcon}
              label={parkingLabel}
              status={publicStatus !== StationPublicStatus.Available ? publicStatus : undefined}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default StationSummary;
