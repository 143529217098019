export const DEFAULT_CONTAINER_STYLE = {
  height: "100%",
  width: "100%",
  zIndex: 10,
};

export const DEFAULT_FIT_BOUNDS_OPTIONS = {
  padding: { bottom: 40, left: 40, right: 40, top: 40 },
};

export const LARGE_FIT_BOUNDS_OPTIONS = {
  padding: { bottom: 60, left: 60, right: 60, top: 60 },
};

export const DEFAULT_STYLE = "mapbox://styles/mapbox/streets-v12?optimize=true";
export const DEFAULT_LOGO_POSITION = "bottom-right";
export const DEFAULT_MOVING_METHOD = "easeTo";
// const DEFAULT_STYLE = "mapbox://styles/mapbox/navigation-day-v1?optimize=true";

export const DEFAULT_MAP_ZOOM = 11;
