import { To } from "react-router-dom";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface BackInviteModalState {
  isBackModalDisplayed: boolean;
  setBackModalDisplayed: (displayed: boolean) => void;
  setTo: (to: To) => void;
  to: To;
}

export const useBackInviteModal = create<BackInviteModalState>()(
  devtools((set) => {
    return {
      isBackModalDisplayed: false,
      setBackModalDisplayed: (displayed) => set(() => ({ isBackModalDisplayed: displayed })),
      setTo: (to) => set(() => ({ to })),
      to: "",
    };
  })
);
