import { BottomSheet, Button } from "components";
import { FunctionComponent } from "react";
import { useTranslation, Trans } from "react-i18next";

interface Props {
  onClose: () => void;
}

const CGV_NUMBER = 2;
type CgvNumber = 1 | 2;

const Sheet: FunctionComponent<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const bottom = (
    <Button
      className="md:mb-15 w-full"
      label={t("registerPage.invoiceInfoScreen.bottomSheet.cta")}
      onClick={onClose}
      size="large"
      type="submit"
    />
  );

  return (
    <BottomSheet
      bottom={bottom}
      onClose={onClose}
      title={t("registerPage.invoiceInfoScreen.bottomSheet.title")}
      fontSize="light"
    >
      {Array.from(Array(CGV_NUMBER).keys()).map((value, index) => (
        <p key={`cgv-${index}`} className="text-medium my-4 font-normal text-neutral-700">
          <Trans
            i18nKey={`registerPage.invoiceInfoScreen.bottomSheet.${(value + 1) as CgvNumber}`}
            components={{
              1: <span className="font-bold" />,
            }}
          />
        </p>
      ))}
    </BottomSheet>
  );
};

export default Sheet;
