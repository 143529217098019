import { BottomSheet } from "components";
import { StationsPageStationSheet_StationFragment } from "operations";
import { FunctionComponent, useCallback, useMemo } from "react";

import { useCurrentLocation } from "../../../../hooks/useCurrentLocation";
import { calcDistance } from "../../../../utils/calc";
import StationSummary from "../../StationSummary";
import { getStationSummary } from "../../StationSummary/utils";

interface Props {
  children?: never;
  onClose?: () => void;
  onStationZoom: (stationLocation: [number, number]) => void;
  station: StationsPageStationSheet_StationFragment;
}

const StationSheet: FunctionComponent<Props> = ({ onClose, onStationZoom, station }) => {
  const { name, latitude, longitude } = station;
  const currentLocation = useCurrentLocation((state) => state.currentLocation);

  const zoom = useCallback(() => onStationZoom([longitude, latitude]), [onStationZoom, latitude, longitude]);

  const distance = useMemo(
    () => (currentLocation ? calcDistance(currentLocation, [longitude, latitude]) : undefined),
    [currentLocation, longitude, latitude]
  );

  const stationSummary = useMemo(() => getStationSummary(station), [station]);

  return (
    <BottomSheet
      title={name}
      onClose={onClose}
      titleSize="xlarge"
      fontSize="light"
      showHeaderSeparator={false}
      withContentPaddingTop={false}
      withBottomPaddingTop={false}
    >
      <div className="mb-6 mt-2">
        <StationSummary distance={distance} onClickDirection={zoom} {...stationSummary} />
      </div>
    </BottomSheet>
  );
};

export default StationSheet;
