import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface MenuState {
  closeMenu: () => void;
  openMenu: () => void;
  opened: boolean;
}

export const useMobileMenu = create<MenuState>()(
  devtools((set) => ({
    closeMenu: () => set(() => ({ opened: false })),
    openMenu: () => set(() => ({ opened: true })),
    opened: false,
  }))
);
