import { InputField } from "@webapps/shared/components";
import { RadioButtonAction } from "@webapps/shared/components/atoms/RadioButton";
import { Form, useFormikContext } from "formik";
import { useInputFocus } from "hooks";
import { BusinessCompanyIdentificationType } from "operations";
import { ChangeEvent, FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { validateCompanyIdentificationNumber } from "utils";

import { useSelfServeAvailableCountries } from "../../../hooks/useSelfServeAvailableCountries";
import { FormValues } from "../screens/CompanyScreen";

import ServiceUnavailableForm from "./ServiceUnavailableForm";

type Props = {
  handleClearIdentificationNumber: () => void;
  handleClearIdentificationType: (value: BusinessCompanyIdentificationType) => void;
  handleCompanyChange: (fieldName: string, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
};

const MAX_VAT_ID_LENGTH = 20;

const CompanyForm: FunctionComponent<Props> = ({
  handleCompanyChange,
  handleClearIdentificationType,
  handleClearIdentificationNumber,
}) => {
  const { t } = useTranslation();
  const { values, setFieldTouched } = useFormikContext<FormValues>();

  const focusInputRef = useInputFocus();
  const availableCountries = useSelfServeAvailableCountries();
  const radioItems = [
    {
      label: t(`registerPage.companyScreen.form.vat.label`),
      value: BusinessCompanyIdentificationType.Vat,
    },
    {
      label: t(`registerPage.companyScreen.form.siret.label`),
      value: BusinessCompanyIdentificationType.Siret,
    },
  ];

  return (
    <>
      <Form>
        <div className="mb-4 flex flex-col gap-2">
          {radioItems.map((item, index) => {
            return (
              <div key={`radioItemLabel-${index}`} className="flex gap-x-2">
                <RadioButtonAction
                  name="companyIdentificationType"
                  key={`itemlabel-${index}`}
                  checked={item.value === values.companyIdentificationType}
                  onClick={() => {
                    handleClearIdentificationType(item.value);
                  }}
                />
                {item.label}
              </div>
            );
          })}
        </div>
        {
          <InputField
            name="companyIdentificationNumber"
            label={t(`registerPage.companyScreen.form.${values.companyIdentificationType}.label`)}
            validate={(value) =>
              validateCompanyIdentificationNumber(value, values.companyIdentificationType, availableCountries)
            }
            ref={focusInputRef}
            onClear={handleClearIdentificationNumber}
            inputProps={{
              initialValue: "",
              maxLength: MAX_VAT_ID_LENGTH,
              // Formik bug, newValues are sometimes replaced by old ones
              // https://github.com/jaredpalmer/formik/issues/2083
              onBlur: () => setFieldTouched("companyIdentificationNumber"),
              onChange: (event) => handleCompanyChange("companyIdentificationNumber", event),
            }}
          />
        }

        {values.infosManuallyProvided && <ServiceUnavailableForm handleCompanyChange={handleCompanyChange} />}
      </Form>
      {!values.infosManuallyProvided && (
        <div className="flex items-center gap-2">
          <p className="whitespace-pre text-xs text-neutral-600 md:whitespace-normal">
            {
              <Trans
                i18nKey="registerPage.companyScreen.form.hint"
                components={{
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  1: <a href="mailto:help@go-electra.com" className="underline" />,
                }}
              />
            }
          </p>
        </div>
      )}
    </>
  );
};

export default CompanyForm;
