import Page from "@webapps/shared/components/templates/Page";
import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: never;
  navLeft: ReactNode;
  title?: string;
}

const GeolocationDeniedScreen: FunctionComponent<Props> = ({ navLeft, title }) => {
  const { t } = useTranslation();
  return (
    <Page navLeft={navLeft} title={title} business>
      <div>{t("geolocationDeniedScreen.infoOne")}</div>
      <div className="mt-4">{t("geolocationDeniedScreen.infoTwo")}</div>
    </Page>
  );
};

export default GeolocationDeniedScreen;
