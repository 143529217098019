import {
  Button,
  CloseToBackAction,
  LandingIllustration,
  LandingScreen,
  FullPage,
  CenteredNavbar,
  LoadingScreen,
} from "components";
import { useSessionlessConfirmEmailMutation } from "operations";
import { FunctionComponent, useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface Props {
  children?: never;
}

const EmailConfirmationPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const { confirmationToken } = useParams();

  const [sessionlessConfirmEmailMutation, { loading }] = useSessionlessConfirmEmailMutation({
    onCompleted: (result) => {
      if (result?.sessionlessConfirmEmail?.success) {
        setError(false);
        return;
      }
      setError(true);
    },
    onError: () => {
      setError(true);
    },
  });

  useEffect(() => {
    if (!confirmationToken) {
      return;
    }
    sessionlessConfirmEmailMutation({
      variables: {
        input: {
          confirmationToken,
        },
      },
    });
  }, [confirmationToken, sessionlessConfirmEmailMutation]);

  /*
   * Props
   */

  const navLeft = useMemo(() => <CloseToBackAction />, []);

  const landingScreenProps = useMemo(() => {
    return {
      button: (
        <Button
          label={t("emailConfirmationPage.logIn", { ns: "common" })}
          href="/sign-in"
          size="small"
          type="submit"
          kind="tertiary"
        />
      ),
      content: error ? (
        <p>
          <Trans
            i18nKey={"emailConfirmationPage.error"}
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              1: <a className="underline" href="mailto:help@go-electra.com" />,
            }}
            ns="common"
          />
        </p>
      ) : (
        <>
          <p>{t("emailConfirmationPage.confirmedEmail", { ns: "common" })}</p>
          <p>{t("emailConfirmationPage.cta", { ns: "common" })}</p>
        </>
      ),
      illustration: error ? "man" : ("both" as LandingIllustration),
      navbar: <CenteredNavbar className="mb-2" business={true} />,
      title: error
        ? t("emailConfirmationPage.errorTitle", { ns: "common" })
        : t("emailConfirmationPage.successTitle", { ns: "common" }),
    };
  }, [error, t]);

  if (loading) {
    return <LoadingScreen title={t("emailConfirmationPage.pageTitle", { ns: "common" })} navLeft={navLeft} centered />;
  }

  return (
    <FullPage withLightFooter={true} className="pt-4">
      <LandingScreen {...landingScreenProps} />
    </FullPage>
  );
};

export default EmailConfirmationPage;
