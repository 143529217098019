import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

import { validateGdpr } from "../../../utils";

import CheckboxField from "./CheckboxField";

const GdprConsentField: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <CheckboxField
      name="gdprAccepted"
      label={
        <div>
          <Trans
            i18nKey="gdprConsentField.label"
            ns="common"
            components={{
              1: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="underline"
                  href={t("gdprConsentField.cguLink", { ns: "common" })}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              2: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="underline"
                  href={t("gdprConsentField.privacyLink", { ns: "common" })}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </div>
      }
      validate={validateGdpr}
    />
  );
};

export default GdprConsentField;
