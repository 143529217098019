import { LINK_TO_DOWNLOAD_APP } from "@webapps/constants";
import { Button, InputField, CenteredPage } from "@webapps/shared/components";
import { Form, Formik } from "formik";
import { useInputFocus } from "hooks";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import { ComponentProps, FunctionComponent, ReactElement } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { required } from "utils";

import RedirectToAppBottom from "../../../components/organisms/RedirectToAppBottom";

interface FormValues {
  firstname: string;
  lastname: string;
}

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "subTitle"> {
  children?: never;
  displayRedirectToApp: boolean;
  error?: string | ReactElement | undefined;
  onSubmit: (firstname: string, lastname: string) => Promise<void>;
}

const UserNamesScreen: FunctionComponent<Props> = ({ error, onSubmit, subTitle, displayRedirectToApp }) => {
  const { t } = useTranslation();
  const focusInputRef = useInputFocus({ enabled: true });

  const submit = async (values: FormValues) => {
    await onSubmit(values.firstname, values.lastname);
  };
  const handleRedirectButton = isIOS || isAndroid ? () => (window.location.href = LINK_TO_DOWNLOAD_APP) : undefined;

  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
      }}
      onSubmit={submit}
    >
      {({ errors, handleSubmit, isSubmitting, isValidating, touched, values }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;

        const bottom = displayRedirectToApp ? (
          <RedirectToAppBottom handleClick={handleRedirectButton} />
        ) : (
          <>
            {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

            {error && !hasError ? <div className="text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full md:max-w-xs md:self-center"
              disabled={isValidating || isSubmitting || hasError || !values.firstname || !values.lastname || !!error}
              label={t("registerPage.userNamesScreen.cta")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <CenteredPage
            bottom={bottom}
            subTitle={subTitle}
            title={t("registerPage.userNamesScreen.pageTitle")}
            bottomWidthLimitation={!displayRedirectToApp}
          >
            <Form className="mt-4 grid grid-cols-1 gap-4">
              <InputField
                name="firstname"
                label={t("registerPage.userNamesScreen.firstNameLabel")}
                validate={required}
                inputProps={{
                  autoComplete: "firstname",
                  enterKeyHint: "send",
                }}
                ref={focusInputRef}
              />

              <InputField
                name="lastname"
                label={t("registerPage.userNamesScreen.lastNameLabel")}
                validate={required}
                inputProps={{ autoComplete: "lastname", enterKeyHint: "send" }}
              />
            </Form>
          </CenteredPage>
        );
      }}
    </Formik>
  );
};

export default UserNamesScreen;
