import classNames from "classnames";
import { FunctionComponent, ReactNode, SVGProps, useMemo } from "react";

import ArrowRightIcon from "../../../images/icons/ri/chevron-right.svg?react";
import LoaderIcon from "../../../images/icons/ri/loading.svg?react";
import AnchorOrDiv from "../../atoms/AnchorOrDiv";

interface Props {
  children?: ReactNode;
  className?: string;
  color: "orange" | "green" | "gray";
  disabled?: boolean;
  href?: string;
  label?: string;
  loading?: boolean;
  onClick?: () => void;
  title?: string;
  trailingArtwork?: ReactNode;
  trailingIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const Panel: FunctionComponent<Props> = ({
  children,
  className,
  color,
  disabled,
  href,
  label,
  loading,
  onClick,
  title,
  trailingArtwork,
  trailingIcon: TrailingIcon,
}) => {
  const bottomDivider = useMemo(() => {
    return (
      <div
        className={classNames("absolute bottom-0 left-0 right-0 h-1", {
          "bg-brand-500": color === "green",
          "bg-neutral-300": color === "gray",
          "bg-orange-300": color === "orange",
        })}
      />
    );
  }, [color]);

  return (
    <AnchorOrDiv
      className={classNames(
        "relative flex gap-4 overflow-hidden rounded px-4 pb-5 pt-4",
        {
          "bg-brand-100": color === "green",
          "bg-neutral-100": color === "gray",
          "bg-orange-100": color === "orange",
          "cursor-pointer select-none": !!label && (!!href || !!onClick) && !disabled,
        },
        className
      )}
      href={href}
      onClick={!disabled ? onClick : undefined}
    >
      <div className="flex flex-1 flex-col gap-2">
        {title ? <div className="text-base font-normal text-neutral-900">{title}</div> : null}

        <div className="text-sm font-normal text-neutral-600">{children}</div>

        {label && !loading ? (
          <div className="text-label-sm flex items-center gap-1 font-normal text-neutral-700">
            {label}
            <ArrowRightIcon className="h-6 w-6 flex-none text-neutral-700" />
          </div>
        ) : null}

        {loading ? (
          <div className="flex items-center">
            <LoaderIcon className="h-6 w-6 animate-spin text-neutral-700" />{" "}
          </div>
        ) : null}
      </div>
      {trailingArtwork || TrailingIcon ? (
        <div className="flex w-24 flex-none items-center justify-center">
          {trailingArtwork ? <div className="flex flex-none items-center">{trailingArtwork}</div> : null}
          {TrailingIcon && !trailingArtwork ? <TrailingIcon className="h-8 w-8 flex-none text-neutral-900" /> : null}
        </div>
      ) : null}

      {bottomDivider}
    </AnchorOrDiv>
  );
};

export default Panel;
