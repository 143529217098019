import classNames from "classnames";
import { Page, CenteredPage } from "components";
import LoadingSpinner from "images/loading-spinner.svg?react";
import { ComponentProps, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  business?: boolean;
  centered?: boolean;
  children?: never;
  message?: string;
  stretchContentOnLargeScreen?: boolean;
  title?: string;
}

const LoadingScreen: FunctionComponent<Props> = ({
  message,
  title,
  centered = false,
  business = false,
  stretchContentOnLargeScreen,
  navLeft,
}) => {
  const { t } = useTranslation();
  const PageComponent = centered ? CenteredPage : Page;
  const commonProps = {
    navLeft,
    showNavbar: business || centered,
    stretchContentOnLargeScreen,
    title,
  };

  const props = centered ? { ...commonProps, showLogo: false } : { ...commonProps, business };

  return (
    <PageComponent {...props}>
      <div
        className={classNames("flex h-full flex-col items-center justify-center gap-2", {
          "h-full": business || centered,
          "min-h-screen": !business && !centered,
        })}
      >
        <LoadingSpinner className="h-12 w-12 animate-spin" />
        <div className="text-base font-light text-neutral-600">
          {message ?? t("loadingScreen.loading", { ns: "common" })}
        </div>
      </div>
    </PageComponent>
  );
};

export default LoadingScreen;
