import { useMediaQuery } from "@react-hook/media-query";
import classNames from "classnames";

const CellLoader = ({ width }: { width: number }) => {
  const smScreen = useMediaQuery("only screen and (min-width: 640px)");
  return (
    <div
      className={classNames(
        "animate-skeleton rounded-xs z-100 h-4 bg-gradient-to-r from-[#F2F3F4] from-0% via-[#F4F5F6] via-50% to-[#E7EAEB] to-100%"
      )}
      style={{ width: smScreen ? `${width}px` : "110px" }}
    />
  );
};

export default CellLoader;
