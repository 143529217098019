import { Button, CenteredPage } from "@webapps/shared/components";
import { DocumentType } from "@webapps/shared/operations";
import { useField } from "formik";
import { ChangeEventHandler, ComponentProps, FunctionComponent, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import UploadIcon from "../../../images/icons/ri/add-circle.svg?react";
import CheckIcon from "../../../images/icons/ri/check-circle.svg?react";
import DeleteIcon from "../../../images/icons/ri/trash-delete.svg?react";

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "subTitle"> {
  children?: never;
  disabled: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleDelete: (field: DocumentType, fileName: string) => void;
  label: string;
  name: DocumentType;
  sublabel: string;
}

const DocumentField: FunctionComponent<Props> = ({ name, handleDelete, handleChange, disabled, label, sublabel }) => {
  const { t } = useTranslation();
  const [field] = useField<File[]>({ name });

  // prevent the native input behavior of not accepting the same file twice even after deletion
  const handleClick: MouseEventHandler<HTMLInputElement> = (event) => {
    (event.target as HTMLInputElement).value = "";
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-8">
        <div className="flex flex-1 flex-col">
          <label className="text-base font-normal text-black">{label}</label>
          <span className="inline-flex text-xs font-normal text-neutral-600">{sublabel}</span>
        </div>
        <Button
          className="relative md:max-w-xs md:self-center"
          disabled={disabled}
          label={t("registerPage.documentsScreen.download")}
          size="small"
          type="button"
        >
          <>
            <UploadIcon className="h-4 w-4" />
            <input
              name={name}
              accept="image/png,image/jpeg,image/jpg,.pdf,.heic"
              onChange={handleChange}
              type="file"
              className="absolute inset-0 cursor-pointer opacity-0"
              onClick={handleClick}
            />
          </>
        </Button>
      </div>
      {field.value?.map((file, index) => (
        <div key={`file-${name}-${index}`} className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <CheckIcon className="h-6 w-6 flex-none text-neutral-700" />
            <span className="inline-flex text-xs font-normal text-neutral-600">{file.name}</span>
          </div>
          <DeleteIcon
            onClick={() => handleDelete(name, file.name)}
            className="h-6 w-6 flex-none cursor-pointer text-neutral-700"
          />
        </div>
      ))}
    </div>
  );
};

export default DocumentField;
