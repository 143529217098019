import { SupportChatScreen, CloseToBackAction } from "@webapps/shared/components";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUserName } from "../../hooks/useCurrentUserName";
import { useUser } from "../../hooks/useUser";
import { useUserPhoneNumber } from "../../hooks/useUserPhoneNumber";

const SupportChatPage = () => {
  const navigate = useNavigate();
  const currentUserName = useCurrentUserName();
  const userId = useUser((state) => state.userId);
  const currentUserPhoneNumber = useUserPhoneNumber();

  /*
   * Props
   */

  const onShow = useCallback(() => {
    window.requestAnimationFrame(() => navigate(-1));
  }, [navigate]);

  const navLeft = useMemo(() => <CloseToBackAction />, []);

  //
  // Rendering
  //

  return (
    <SupportChatScreen
      navLeft={navLeft}
      onShow={onShow}
      userId={userId}
      userName={currentUserName}
      userPhoneNumber={currentUserPhoneNumber}
    />
  );
};

export default SupportChatPage;
