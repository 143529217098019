import { Button, CenteredPage } from "@webapps/shared/components";
import { Formik } from "formik";
import { useMixpanel } from "hooks";
import intersection from "lodash/intersection";
import join from "lodash/join";
import keys from "lodash/keys";
import { BusinessCompanyIdentificationType, SearchBusinessCompanyPayload } from "operations";
import { ChangeEvent, ComponentProps, FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CompanyForm from "../components/CompanyForm";
import SearchResult from "../components/SearchResult";

export interface FormValues {
  address?: string;
  city?: string;
  companyIdentificationNumber: string;
  companyIdentificationType: BusinessCompanyIdentificationType;
  countryId?: string;
  infosManuallyProvided?: boolean;
  name?: string;
  postCode?: string;
}

const initialValues = {
  address: "",
  city: "",
  companyIdentificationNumber: "",
  companyIdentificationType: BusinessCompanyIdentificationType.Vat,
  countryId: "",
  infosManuallyProvided: false,
  name: "",
  postCode: "",
};

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "subTitle"> {
  children?: never;
  company?: Omit<SearchBusinessCompanyPayload, "country"> & {
    country?: { id: string; name: string } | undefined | null;
  };
  error?: string | ReactElement | undefined;
  onSubmit: (company: FormValues) => Promise<void>;
  resetError: () => void;
  trackPage: () => void;
}

const CompanyScreen: FunctionComponent<Props> = ({ error, onSubmit, subTitle, company, resetError, trackPage }) => {
  const { t } = useTranslation();

  const mixpanel = useMixpanel((state) => state.mixpanel);
  const companyName = company?.name ?? undefined;
  const companyAddress = company?.address
    ? `${company.address}, ${company.postCode} ${company.city}, ${company.country?.name}`
    : undefined;

  const isSearch = !companyName && !companyAddress;
  const errorMessage = join(company?.errors, " ") || error;
  const errorKeys = company?.errorKeys;
  const [serviceUnavailable, setServiceUnavailable] = useState(false);

  useEffect(() => {
    if (errorKeys && errorKeys.includes("service_unavailable")) {
      setServiceUnavailable(true);
    }
  }, [errorKeys]);

  useEffect(() => {
    if (!mixpanel) return;

    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, handleSubmit, isSubmitting, isValidating, touched, values, setFieldValue, resetForm }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;
        const { infosManuallyProvided, companyIdentificationNumber, companyIdentificationType } = values;
        const isFormEmpty = infosManuallyProvided
          ? Object.values(values).some((v) => !v)
          : values.companyIdentificationNumber === "";
        const disableSubmitButton = isValidating || isSubmitting || hasError || !!errorMessage || isFormEmpty;

        const handleServiceUnavailableForm = () => {
          resetError();
          setServiceUnavailable(false);
          // After submitting, Formik set every input's touch to true.
          // Therefore, we reset the whole form and set the value of vatId to ease out UX.
          resetForm();
          setFieldValue("companyIdentificationNumber", companyIdentificationNumber);
          setFieldValue("companyIdentificationType", companyIdentificationType);
          setFieldValue("infosManuallyProvided", true);
        };

        const bottom = (
          <>
            {hasError && <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div>}

            {!!errorMessage && !hasError && <div className="text-base text-red-600">{errorMessage}</div>}

            <Button
              className="w-full md:max-w-xs md:self-center"
              disabled={disableSubmitButton}
              label={t(
                `registerPage.companyScreen.cta.${
                  infosManuallyProvided ? "validateManually" : isSearch ? "search" : "resume"
                }`
              )}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />

            {serviceUnavailable && !infosManuallyProvided && (
              <Button
                className="w-full md:max-w-xs md:self-center"
                label={t(`registerPage.companyScreen.cta.resumeManually`)}
                loading={isValidating || isSubmitting}
                onClick={handleServiceUnavailableForm}
                kind="tertiary"
                size="large"
                type="button"
              />
            )}
          </>
        );

        const handleClearIdentificationNumber = () => {
          setFieldValue("companyIdentificationNumber", "");
          // we must reset the query if the user wants to type another vatId, otherwise he won't be able to search with this new vat
          resetError();
        };

        const handleClearIdentificationType = (value: string) => {
          resetForm();
          resetError();
          if (serviceUnavailable) {
            setServiceUnavailable(false);
          }
          setFieldValue("companyIdentificationType", value);
        };

        const handleCompanyChange = (fieldName: string, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
          const { value } = event.target;
          let newValue = value;

          if (fieldName === "companyIdentificationNumber") {
            if (!isSearch || (company?.errors && company?.errors.length > 0)) {
              resetError();
            }
            if (companyIdentificationNumber.substring(0, 2) !== value.substring(0, 2)) {
              setFieldValue("countryId", "");
            }

            newValue = newValue.replace(/[^a-zA-Z0-9]/g, "");
          }

          setFieldValue(fieldName, newValue.toUpperCase());
        };

        return (
          <CenteredPage bottom={bottom} subTitle={subTitle} title={t("registerPage.companyScreen.pageTitle")}>
            <p className="text-base font-light text-neutral-700">{t("registerPage.companyScreen.description")}</p>
            <div className="mt-4 flex flex-col gap-2">
              <CompanyForm
                handleClearIdentificationNumber={handleClearIdentificationNumber}
                handleClearIdentificationType={handleClearIdentificationType}
                handleCompanyChange={handleCompanyChange}
              />
              <SearchResult name={companyName} address={companyAddress} />
            </div>
          </CenteredPage>
        );
      }}
    </Formik>
  );
};

export default CompanyScreen;
