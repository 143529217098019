import { Button, InputField, CenteredPage } from "@webapps/shared/components";
import { Form, Formik } from "formik";
import { useAsyncMemo, useInputFocus } from "hooks";
import { useLibPhoneNumber } from "libs";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import { ComponentProps, FunctionComponent, ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { validateCodePhoneChallenge } from "utils";

interface FormValues {
  code: string;
}

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "navLeft" | "title" | "subTitle"> {
  children?: never;
  error?: string | ReactElement | undefined;
  onSubmit: (code: string) => Promise<void>;
  phoneNumber: string;
  resetError: () => void;
}

const PhoneChallengeScreen: FunctionComponent<Props> = ({
  error,
  navLeft,
  phoneNumber,
  onSubmit,
  title,
  subTitle,
  resetError,
}) => {
  const { t } = useTranslation();
  const ensureLibPhoneNumber = useLibPhoneNumber();

  const focusInputRef = useInputFocus();

  const submit = async (values: FormValues) => {
    await onSubmit(values.code);
  };

  const [formattedPhoneNumber] = useAsyncMemo(async () => {
    const { parsePhoneNumber } = await ensureLibPhoneNumber();

    const phonelib = parsePhoneNumber(phoneNumber);

    return phonelib.formatNational();
  }, [phoneNumber]);

  return (
    <Formik
      initialValues={{
        code: "",
      }}
      onSubmit={submit}
      validateOnChange={false}
    >
      {({ errors, handleSubmit, isSubmitting, isValidating, touched, setFieldValue }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;

        const handleClear = () => {
          setFieldValue("code", "");
          resetError();
        };

        const bottom = (
          <>
            {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

            {error && !hasError ? <div className="text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full md:max-w-xs md:self-center"
              disabled={isValidating || isSubmitting || hasError}
              label={t("registerPage.phoneChallengeScreen.cta")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <CenteredPage bottom={bottom} navLeft={navLeft} subTitle={subTitle} title={title}>
            <Form className="mt-4 flex flex-col gap-6">
              <div className="text-base font-light text-neutral-700">
                <Trans
                  i18nKey="registerPage.phoneChallengeScreen.hint"
                  components={{
                    1: <span className="font-bold" />,
                  }}
                  values={{ phoneNumber: formattedPhoneNumber ?? phoneNumber }}
                />
              </div>

              <div className="grid grid-cols-1">
                <InputField
                  name="code"
                  label={t("registerPage.phoneChallengeScreen.codeLabel")}
                  validate={validateCodePhoneChallenge}
                  inputProps={{ autoComplete: "one-time-code", inputMode: "numeric" }}
                  ref={focusInputRef}
                  onClear={handleClear}
                />
              </div>
            </Form>
          </CenteredPage>
        );
      }}
    </Formik>
  );
};

export default PhoneChallengeScreen;
