import { CheckboxField } from "@webapps/shared/components";
import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { validateGdprRegister } from "utils";

const RegisterGdprConsent: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <CheckboxField
        checkboxProps={{ className: "border-neutral-200 rounded" }}
        name="gdprAccepted"
        label={
          <div>
            <Trans
              i18nKey="registerPage.phoneNumberScreen.consent.gdpr.label"
              components={{
                1: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    className="underline"
                    href={t("registerPage.phoneNumberScreen.consent.cgvLink")}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                2: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    className="underline"
                    href={t("gdprConsentField.privacyLink", { ns: "common" })}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </div>
        }
        validate={validateGdprRegister}
      />
    </div>
  );
};

export default RegisterGdprConsent;
