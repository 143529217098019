import { Button, FormikPersist } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { InvitationDataType } from "@webapps/shared/operations";
import { Form, useFormikContext } from "formik";
import { ComponentProps, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../../hooks/useUser";
import AddIcon from "../../../images/icons/ri/add-circle.svg?react";
import { EmptyInvitation, FormValues } from "../screens/Screen";

import InvitationRow from "./InvitationRow";

export const FORM_LOCALSTORAGE_KEY = "invitations-form";

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  hasServerError?: boolean;
  push: (invitation: InvitationDataType) => void;
  remove: (index: number) => void;
  resetServerError: () => void;
}

const InvitationsForm: FunctionComponent<Props> = ({ remove, push, resetServerError, hasServerError }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const currentAccountId = useUser((state) => state.currentAccountId);

  const handleAddInvitation = () => {
    trackButtonClicked(BUTTON_CLICKED.ADD_INVITATION_LINE, { ...mixpanelDynamicProps, accountId: currentAccountId });
    push(EmptyInvitation);
  };

  return (
    <Form className="mt-8">
      <FormikPersist name={FORM_LOCALSTORAGE_KEY} />
      {[...Array(values.invitations.length)].map((_, index) => (
        <InvitationRow
          index={index}
          handleRemove={remove}
          key={`invitation-${index}`}
          resetServerError={resetServerError}
          serverError={hasServerError}
        />
      ))}
      <Button
        className="mt-4 w-full"
        label={t("invitationsPage.invitationsScreen.add")}
        onClick={handleAddInvitation}
        size="small"
        type="button"
        kind="secondary"
        disabled={values.invitations.length > 50}
      >
        <AddIcon className="-ml-1 h-4 w-4" />
      </Button>
      <div className="mt-2 text-xs text-neutral-600">{t("form.mandatoryFields", { ns: "common" })}</div>
    </Form>
  );
};

export default InvitationsForm;
