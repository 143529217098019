import classNames from "classnames";
import { AnchorOrDiv } from "components";
import { FunctionComponent, HTMLAttributeAnchorTarget, ReactNode, SVGProps } from "react";

export interface ListItemProps {
  bgColor?: string;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  hideBorder?: boolean;
  horizontalPadding?: boolean;
  href?: string;
  isLoading?: boolean;
  label: ReactNode;
  leadingArtwork?: ReactNode;
  leadingIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  position?: "first" | "last" | "middle" | "none" | "standalone";
  selected?: boolean;
  size?: "large" | "small" | "heading";
  subLabel?: string | ReactNode;
  target?: HTMLAttributeAnchorTarget;
  trailing?: ReactNode;
  trailingDotHint?: boolean;
  trailingHint?: string;
  trailingIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const ListItem: FunctionComponent<ListItemProps> = ({
  bgColor = "bg-white",
  className,
  dataTestId,
  disabled = false,
  hideBorder = false,
  isLoading = false,
  label,
  leadingArtwork,
  leadingIcon: LeadingIcon,
  trailingDotHint = false,
  trailingHint,
  trailingIcon: TrailingIcon,
  trailing,
  onClick,
  href,
  horizontalPadding = true,
  position = "standalone",
  size = "large",
  subLabel,
  target = "_self",
  selected = false,
}) => {
  const leadingComponent = (
    <div className="flex items-center">
      {leadingArtwork && <div className="flex h-8 w-8 flex-none items-center">{leadingArtwork}</div>}
      {LeadingIcon && !leadingArtwork && (
        <LeadingIcon
          className={classNames("h-8 w-8", {
            "text-neutral-700": !disabled,
            "text-neutral-700 opacity-30": disabled,
          })}
        />
      )}
    </div>
  );

  const labelComponent = (
    <div className="flex flex-1 flex-col justify-center">
      <div
        className={classNames({
          "text-base font-normal": size !== "heading",
          "text-neutral-900": !disabled,
          "text-neutral-900 opacity-50": disabled,
          "text-xl-light font-light": size === "heading",
        })}
      >
        {label}
      </div>
      {subLabel && (
        <div
          className={classNames("text-sm font-normal", {
            "text-neutral-600": !disabled,
            "text-neutral-600 opacity-50": disabled,
          })}
        >
          {subLabel}
        </div>
      )}
    </div>
  );

  const trailingComponent = (
    <div className="flex items-center gap-6">
      {trailingDotHint && <div className="h-2 w-2 flex-none rounded-full bg-orange-500" />}

      {trailingHint && (
        <div
          className={classNames("text-sm font-normal text-neutral-600", {
            "text-neutral-600": !disabled,
            "text-neutral-600 opacity-50": disabled,
          })}
        >
          {trailingHint}
        </div>
      )}

      {trailing}

      {TrailingIcon && !trailing && (
        <TrailingIcon
          className={classNames("h-6 w-6 flex-none", {
            "animate-spin": isLoading,
            "text-neutral-700": !disabled,
            "text-neutral-700 opacity-50": disabled,
          })}
        />
      )}
    </div>
  );

  return (
    <AnchorOrDiv
      className={classNames(
        "flex w-full items-stretch",
        {
          "bg-brand-50 border-brand-500": selected,
          [bgColor]: !selected,
          "border-b": !hideBorder,
          "border-neutral-100": !selected,
          "border-t": position === "first" || position === "standalone" || selected,
          "border-x": position !== "none",
          "cursor-not-allowed": disabled,
          "h-12": size === "small",
          "h-16": size === "heading",
          "h-[4.625rem]": size === "large",
          "px-4": horizontalPadding,
          "py-2": size === "small",
          "py-4": size === "large" || size === "heading",
          "rounded-b": position === "last" || position === "standalone",
          "rounded-t": position === "first" || position === "standalone",
          "select-none": (!!href || !!onClick) && !disabled,
        },
        className
      )}
      href={href}
      target={target}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <div className="flex flex-grow gap-3">
        {(leadingArtwork || LeadingIcon) && leadingComponent}
        {labelComponent}
        {trailingComponent}
      </div>
    </AnchorOrDiv>
  );
};

export default ListItem;
