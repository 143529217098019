import { AccountValidationStatus, OnboardingStatusFragment, ProAccountType } from "operations";

const redirectUserToMobile = (onboardingStatus: OnboardingStatusFragment | null | undefined) => {
  if (
    onboardingStatus?.proType === ProAccountType.Independant &&
    onboardingStatus?.status !== AccountValidationStatus.Rejected
  ) {
    return true;
  }

  return false;
};

export default redirectUserToMobile;
