import { useApolloClient } from "@apollo/client";
import { SupportAction, ErrorScreen } from "@webapps/shared/components";
import { BUTTON_CLICKED, PAGE_VIEWED } from "@webapps/shared/libs";
import { LoadingScreen } from "components";
import { useMixpanel, useNotification } from "hooks";
import join from "lodash/join";
import {
  AccountUserRole,
  useReinviteUserMutation,
  UserPageScreen_AccountUserFragment,
  useInvitationPageQuery,
  NotificationCategoryEnum,
  useDeleteInvitationMutation,
  LayoutDocument,
} from "operations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";
import NotFoundScreen from "../../screens/NotFoundScreen";
import UserScreen from "../../screens/UserScreen";

const InvitationPage = () => {
  const { t } = useTranslation();
  const { invitationId } = useParams();
  const client = useApolloClient();
  const pageTitle = t("invitationPage.pageTitle");
  const navigate = useNavigate();
  const currentAccountId = useUser((state) => state.currentAccountId);
  const setNotification = useNotification((state) => state.setNotification);

  const { trackButtonClicked, trackPageView } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const [reinviteError, setReinviteUserError] = useState<string>();

  const { data, loading, error, refetch } = useInvitationPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId || !invitationId,
    variables: {
      accountId: currentAccountId ?? "none",
      invitationId: invitationId ?? "none",
    },
  });

  const [createInvitationMutation, { loading: reinviting }] = useReinviteUserMutation({
    onCompleted: async ({ reinviteUser: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setReinviteUserError(join(result.errors, " "));
        return;
      }

      if (result?.successMessage && result?.successTitle) {
        setNotification({ badge: true, message: result?.successMessage, title: result?.successTitle });
        trackButtonClicked(BUTTON_CLICKED.REINVITE_USER, { ...mixpanelDynamicProps, invitation_id: invitationId });
        await refetch();
        return;
      }
      setReinviteUserError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setReinviteUserError(error.message);
    },
  });

  const [deleteInvitationMutation, { loading: deleting }] = useDeleteInvitationMutation({
    onCompleted: async ({ deleteInvitation: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setNotification({ badge: true, message: join(result.errors, " "), type: NotificationCategoryEnum.Error });
        return;
      }

      if (result?.successMessage) {
        setNotification({ badge: true, message: result?.successMessage });
        trackButtonClicked(BUTTON_CLICKED.DELETE_INVITATION, mixpanelDynamicProps);
        await client.refetchQueries({
          include: ["UsersPage"],
        });
        return;
      }
      setNotification({
        badge: true,
        message: t("api.unknownError", { ns: "common" }),
        type: NotificationCategoryEnum.Error,
      });
    },
    onError: (error) => {
      setNotification({ badge: true, message: error.message, type: NotificationCategoryEnum.Error });
    },
    refetchQueries: [{ fetchPolicy: "network-only", query: LayoutDocument }],
  });

  const onReinvite = useCallback(async () => {
    setReinviteUserError(undefined);

    await createInvitationMutation({
      variables: {
        input: {
          invitationId: invitationId ?? "none",
        },
      },
    });
  }, [createInvitationMutation, invitationId]);

  const onDelete = useCallback(async () => {
    await deleteInvitationMutation({
      variables: {
        input: {
          invitationId: invitationId ?? "none",
        },
      },
    });
  }, [deleteInvitationMutation, invitationId]);

  /*
   * Props
   */

  const invitation = useMemo(() => data?.me?.account?.invitation ?? undefined, [data]);
  const accountUser: UserPageScreen_AccountUserFragment | undefined = useMemo(() => {
    if (!invitation) return undefined;

    const { confirmedAt, createdBy: invitedBy, firstname, fullname, id, lastname, phoneNumber } = invitation;

    const user = {
      id: "temporaryUser",
      phoneNumber,
    };

    return {
      active: false,
      canEdit: false,
      canEditRole: false,
      firstname,
      fullname,
      id,
      invitedBy,
      joinedAt: confirmedAt,
      lastname,
      role: AccountUserRole.Member,
      user,
    };
  }, [invitation]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={goBack} />;
  }, [goBack]);

  const navRight = useMemo(() => <SupportAction />, []);

  const errorMessage = useMemo(() => error?.message ?? undefined, [error]);

  useEffect(() => {
    if (!reinviteError) return;
    setNotification({
      message: t("notification.invitation.error.message"),
      title: t("notification.invitation.error.title"),
      type: NotificationCategoryEnum.Error,
    });
  }, [reinviteError, setNotification, t]);

  //
  // Tracking plan
  //

  const trackPage = useCallback(() => {
    trackPageView(PAGE_VIEWED.INVITATION_PAGE, {
      ...mixpanelDynamicProps,
      invitation_id: invitationId ?? "none",
    });
  }, [invitationId, mixpanelDynamicProps, trackPageView]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen title={pageTitle} navLeft={navLeft} error={errorMessage} canReloadPage business />;
  }

  if (!currentAccountId) {
    return <NoAccountSelectedScreen title={pageTitle} navLeft={navLeft} />;
  }

  if (!invitation || !accountUser) {
    if (loading) return <LoadingScreen title={pageTitle} navLeft={navLeft} business />;

    return <NotFoundScreen title={t("invitationPage.notFound")} navLeft={navLeft} />;
  }

  return (
    <UserScreen
      accountUser={accountUser}
      invitationStatus={invitation.status}
      navLeft={navLeft}
      navRight={navRight}
      onReinvite={invitation.canReinvite ? onReinvite : undefined}
      reinviting={invitation.canReinvite ? reinviting : false}
      showStatus={false}
      title={accountUser.fullname}
      trackPage={trackPage}
      reinvitationErrorMessage={invitation.reinvitationErrorMessage ?? undefined}
      deleting={deleting}
      onDelete={onDelete}
    />
  );
};

export default InvitationPage;
