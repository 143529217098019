import { To, useLocation } from "react-router-dom";

import { usePreviousLocation } from "./usePreviousLocation";

export type DrawerLinkRoute = {
  globalRoutes?: string[];
  isRootPath?: boolean;
  subRoutes?: string[];
  to: To;
};

export function useRoutedSelected(route: DrawerLinkRoute) {
  const { pathname } = useLocation();
  const previousLocation = usePreviousLocation();
  const { pathname: previousPathname } = previousLocation || {};
  const { globalRoutes = [], isRootPath, subRoutes = [], to } = route;

  if (pathname.startsWith(to.toString())) {
    return true;
  }

  if (subRoutes.find((route) => pathname.startsWith(route))) {
    return true;
  }

  if (pathname === "/" && isRootPath) {
    return true;
  }

  if (previousPathname && globalRoutes.length > 0 && globalRoutes.find((route) => pathname.startsWith(route))) {
    if (previousPathname.startsWith(to.toString()) || subRoutes.find((route) => previousPathname.startsWith(route))) {
      return true;
    }
  }

  return false;
}
