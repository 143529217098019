import classNames from "classnames";
import { FunctionComponent } from "react";

import { TableData } from "../../utils";
import ChargeCard from "../ChargeCard";

interface Props {
  data: TableData[] | {}[];
}

const ChargeCards: FunctionComponent<Props> = ({ data }) => {
  return (
    <div className={classNames("flex h-full flex-col gap-2 px-1")}>
      {data.map((tableData, index) => (
        <ChargeCard key={`${tableData}-${index}`} tableData={tableData} />
      ))}
    </div>
  );
};

export default ChargeCards;
