import { SupportAction, LoadingScreen, ErrorScreen } from "@webapps/shared/components";
import { useMixpanel, useNotification } from "hooks";
import { PAGE_VIEWED } from "libs";
import join from "lodash/join";
import {
  AccountUserRole,
  NotificationCategoryEnum,
  useUpdateAccountUserPageMutation,
  useUserPageQuery,
} from "operations";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";
import NotFoundScreen from "../../screens/NotFoundScreen";
import UserNamesScreen from "../../screens/UserNamesScreen";
import UserScreen from "../../screens/UserScreen";

const UserPage = () => {
  const { t } = useTranslation();
  const { accountUserId } = useParams();
  const navigate = useNavigate();
  const pageTitle = t("userPage.pageTitle");
  const currentAccountId = useUser((state) => state.currentAccountId);
  const setNotification = useNotification((state) => state.setNotification);

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const [updateAccountUserError, setUpdateAccountUserError] = useState<string>();
  const [mustShowNotificationOnError, setMustShowNotificationOnError] = useState<boolean>(false);
  const [screen, setScreen] = useState<"USER" | "USER_NAMES">("USER");

  const { data, loading, error } = useUserPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId || !accountUserId,
    variables: {
      accountId: currentAccountId ?? "none",
      accountUserId: accountUserId ?? "none",
    },
  });

  const [updateAccountUserMutation] = useUpdateAccountUserPageMutation({
    onCompleted: ({ updateAccountUser: result }) => {
      let errorMessage: string;
      if (result?.errors && result.errors.length > 0) {
        errorMessage = join(result.errors, " ");
        setUpdateAccountUserError(errorMessage);

        if (mustShowNotificationOnError) {
          setNotification({
            message: errorMessage,
            title: t("notification.error"),
            type: NotificationCategoryEnum.Error,
          });
        }
        return;
      }

      if (result?.accountUser) {
        setUpdateAccountUserError(undefined);
        setScreen("USER");
        return;
      }

      errorMessage = t("api.unknownError", { ns: "common" });
      setUpdateAccountUserError(errorMessage);
      if (mustShowNotificationOnError) {
        setNotification({
          message: errorMessage,
          title: t("notification.error"),
          type: NotificationCategoryEnum.Error,
        });
      }
    },
    onError: (error) => {
      const errorMessage = error.message;
      setUpdateAccountUserError(errorMessage);

      if (mustShowNotificationOnError) {
        setNotification({
          message: errorMessage,
          title: t("notification.error"),
          type: NotificationCategoryEnum.Error,
        });
      }
    },
  });

  const handleClickFullname = useCallback(() => {
    setScreen("USER_NAMES");
  }, []);

  const handleChangeRole = useCallback(
    async (role: AccountUserRole) => {
      setMustShowNotificationOnError(true);

      const result = await updateAccountUserMutation({
        refetchQueries: ["Layout"],
        variables: {
          input: {
            accountUserId: accountUserId ?? "none",
            role,
          },
        },
      });

      const hasErrors =
        (result?.errors && result.errors.length > 0) ||
        (result?.data?.updateAccountUser?.errors && result.data.updateAccountUser.errors.length > 0);

      setMustShowNotificationOnError(false);

      return !hasErrors;
    },
    [accountUserId, updateAccountUserMutation]
  );

  const handleChangeStatus = useCallback(
    async (active: boolean) => {
      setMustShowNotificationOnError(true);

      const result = await updateAccountUserMutation({
        variables: {
          input: {
            accountUserId: accountUserId ?? "none",
            active,
          },
        },
      });

      const hasErrors =
        (result?.errors && result.errors.length > 0) ||
        (result?.data?.updateAccountUser?.errors && result.data.updateAccountUser.errors.length > 0);

      setMustShowNotificationOnError(false);

      return !hasErrors;
    },
    [accountUserId, updateAccountUserMutation]
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  /*
   * Props
   */

  const accountUser = useMemo(() => data?.me?.account?.accountUser ?? undefined, [data]);

  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={goBack} />;
  }, [goBack]);

  const navRight = useMemo(() => <SupportAction />, []);

  const errorMessage = useMemo(() => error?.message ?? undefined, [error]);

  //
  // Tracking plan
  //

  const trackPage = useCallback(() => {
    if (screen !== "USER" || !accountUser) return;

    trackPageView(PAGE_VIEWED.USER_PAGE, {
      ...mixpanelDynamicProps,
      account_user_id: accountUser.id,
    });
  }, [accountUser, mixpanelDynamicProps, screen, trackPageView]);

  const userNamesScreenProps = useMemo(() => {
    if (screen !== "USER_NAMES" || !accountUser) return;

    const title = t("userPage.title");
    const { firstname, lastname } = accountUser;

    return {
      actionLabel: t("userPage.actionLabel"),
      error: updateAccountUserError,
      focusFirstField: false,
      hideLastname: false,
      initialValues: {
        firstname,
        lastname: lastname ?? "",
      },
      navLeft: (
        <ResponsiveBackAction
          onClick={() => {
            setScreen("USER");
          }}
        />
      ),
      onSubmit: async (firstname: string, lastname: string | undefined) => {
        await updateAccountUserMutation({
          variables: {
            input: {
              accountUserId: accountUserId ?? "none",
              firstname,
              lastname,
            },
          },
        });
      },
      showMandatoryFieldIndication: true,
      subTitle: undefined,
      title,
    };
  }, [screen, accountUser, t, updateAccountUserError, updateAccountUserMutation, accountUserId]);

  const userScreenProps = useMemo(() => {
    if (screen !== "USER" || !accountUser) return;

    const title = accountUser.fullname;

    return {
      accountUser,
      navLeft,
      navRight,
      onChangeRole: accountUser.canEditRole ? handleChangeRole : undefined,
      onChangeStatus: accountUser.canEdit ? handleChangeStatus : undefined,
      onClickFullname: accountUser.canEdit ? handleClickFullname : undefined,
      title,
      trackPage,
    };
  }, [accountUser, handleChangeRole, handleChangeStatus, handleClickFullname, navLeft, navRight, screen, trackPage]);

  //
  // Rendering
  //

  if (errorMessage) {
    return <ErrorScreen title={pageTitle} navLeft={navLeft} error={errorMessage} canReloadPage business />;
  }

  if (!currentAccountId) {
    return <NoAccountSelectedScreen title={pageTitle} navLeft={navLeft} />;
  }

  if (!accountUser) {
    if (loading) return <LoadingScreen title={pageTitle} navLeft={navLeft} business />;

    return <NotFoundScreen title={t("userPage.notFound")} navLeft={navLeft} />;
  }

  if (userScreenProps) {
    return <UserScreen {...userScreenProps} />;
  }

  if (userNamesScreenProps) {
    return <UserNamesScreen {...userNamesScreenProps} />;
  }

  throw new Error("Invalid state");
};

export default UserPage;
