import { BUSINESS_TOKEN_STORAGE_KEY } from "constants/storage";

import { useApolloClient } from "@apollo/client";
import { useLocalStorage } from "hooks";
import { useCallback } from "react";

import { useUser } from "./useUser";

const useSignOut = () => {
  const client = useApolloClient();
  const [, setSessionToken] = useLocalStorage(BUSINESS_TOKEN_STORAGE_KEY);
  const resetUser = useUser((state) => state.resetUser);
  const signOut = useCallback(() => {
    setSessionToken(undefined);
    resetUser();
    client.clearStore();
  }, [setSessionToken, client, resetUser]);
  return signOut;
};

export default useSignOut;
