import { useMediaQuery } from "@react-hook/media-query";
import { BUTTON_CLICKED, FormType, PAGE_VIEWED } from "@webapps/shared/libs";
import { LoadingScreen, ErrorScreen } from "components";
import { useMixpanel, useNotification } from "hooks";
import join from "lodash/join";
import {
  useCreateMultipleInvitationMutation,
  useCreateInvitationMutation,
  usePhoneNumberScreenQuery,
  NotificationCategoryEnum,
  InvitationDataType,
  LayoutDocument,
} from "operations";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import { useBackInviteModal } from "../../hooks/useBackInviteModal";
import { useBackInviteModalType } from "../../hooks/useBackInviteModalType";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";
import NotFoundScreen from "../../screens/NotFoundScreen";

import { FORM_LOCALSTORAGE_KEY } from "./components/InvitationsForm";
import Screen from "./screens/Screen";
import SmallScreen from "./screens/SmallScreen";

const UsersInvitePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentAccountId = useUser((state) => state.currentAccountId);

  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");

  const [phoneNumberError, setPhoneNumberError] = useState<string>();
  const setNotification = useNotification((state) => state.setNotification);
  const modalType = useBackInviteModalType();
  const { setBackModalDisplayed } = useBackInviteModal();

  const { trackButtonClicked, trackPageView } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  const trackPage = useCallback(() => {
    trackPageView(PAGE_VIEWED.INVITATION_CREATION_PAGE, {
      ...mixpanelDynamicProps,
      form_type: (mdScreen ? "multiple" : "single") as FormType,
    });
  }, [mixpanelDynamicProps, trackPageView, mdScreen]);

  const trackDownloadButtonClicked = useCallback(() => {
    trackButtonClicked(BUTTON_CLICKED.DOWNLOAD_BULK_INVITATIONS_FILE, {
      ...mixpanelDynamicProps,
      account_id: currentAccountId,
    });
  }, [currentAccountId, mixpanelDynamicProps, trackButtonClicked]);

  const { loading, error: countryError } = usePhoneNumberScreenQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [createInvitationMutation] = useCreateInvitationMutation({
    onCompleted: ({ createInvitation: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setPhoneNumberError(join(result.errors, " "));
        return;
      }

      if (result?.successMessage && result?.successTitle) {
        setNotification({ badge: true, message: result.successMessage, title: result.successTitle });
        trackButtonClicked(BUTTON_CLICKED.INVITE_USER, {
          ...mixpanelDynamicProps,
          account_id: currentAccountId,
        });
        navigate("/users");
        return;
      }
      setPhoneNumberError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setPhoneNumberError(error.message);
    },
  });

  const [createMultipleInvitationMutation] = useCreateMultipleInvitationMutation({
    onCompleted: ({ createMultipleInvitations: result }) => {
      if (result?.successMessage || result?.errorMessage) {
        trackButtonClicked(BUTTON_CLICKED.INVITE_USERS, {
          ...mixpanelDynamicProps,
          account_id: result.account?.id,
          invitation_count: result?.sentInvitationsCount,
        });
        window.localStorage.removeItem(FORM_LOCALSTORAGE_KEY);
      }

      if (result?.successMessage) {
        navigate("/users");
        setNotification({ badge: true, message: result.successMessage });
        return;
      }

      if (result?.errorMessage) {
        setNotification({ badge: true, message: result.errorMessage, type: NotificationCategoryEnum.Error });
        if (result.errors && result.errors.length > 0) {
          setPhoneNumberError(join(result.errors, `${"\n"}`));
        }
        return;
      }

      setPhoneNumberError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setPhoneNumberError(error.message);
    },
    refetchQueries: [{ fetchPolicy: "network-only", query: LayoutDocument }],
  });

  const handleNavLeftClick = useCallback(() => {
    if (modalType) {
      setBackModalDisplayed(true);
      trackPageView(PAGE_VIEWED.ALERT_MODAL, {
        ...mixpanelDynamicProps,
        context: modalType === "noInvite" ? "no_invite" : "saved_invites",
      });
    } else {
      navigate("/users");
    }
  }, [modalType, setBackModalDisplayed, trackPageView, mixpanelDynamicProps, navigate]);

  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={handleNavLeftClick} />;
  }, [handleNavLeftClick]);

  //
  // Props
  //

  const smallScreenProps = useMemo(() => {
    if (mdScreen) return;
    return {
      error: phoneNumberError,
      navLeft,
      onSubmit: async (invitation: InvitationDataType) => {
        await createInvitationMutation({
          variables: {
            input: {
              accountId: currentAccountId ?? "none",
              ...invitation,
            },
          },
        });
      },
      resetError: () => {
        setPhoneNumberError(undefined);
      },
      trackPage,
    };
  }, [phoneNumberError, createInvitationMutation, currentAccountId, trackPage, mdScreen, navLeft]);

  const screenProps = useMemo(() => {
    if (!mdScreen) return;
    return {
      error: phoneNumberError,
      navLeft,
      onSubmit: async (invitations: InvitationDataType[]) => {
        await createMultipleInvitationMutation({
          variables: {
            input: {
              accountId: currentAccountId ?? "none",
              invitations,
            },
          },
        });
      },
      resetError: () => {
        setPhoneNumberError(undefined);
      },
      trackButtonClicked: trackDownloadButtonClicked,
      trackPage,
    };
  }, [
    phoneNumberError,
    createMultipleInvitationMutation,
    currentAccountId,
    trackPage,
    mdScreen,
    trackDownloadButtonClicked,
    navLeft,
  ]);

  if (!currentAccountId) {
    return <NoAccountSelectedScreen title={t("invitationsPage.invitationsScreen.pageTitle")} navLeft={navLeft} />;
  }

  if (countryError)
    return (
      <ErrorScreen
        title={t("phoneNumberScreen.errorPage.title")}
        navLeft={navLeft}
        canReloadPage
        error={t("phoneNumberScreen.errorPage.error")}
        business
      />
    );

  if (loading)
    return <LoadingScreen title={t("invitationsPage.invitationsScreen.pageTitle")} navLeft={navLeft} business />;

  if (!currentAccountId) return <NotFoundScreen title={t("invitationsPage.notFound")} navLeft={navLeft} />;

  if (screenProps) {
    return <Screen {...screenProps} />;
  }

  if (smallScreenProps) {
    return <SmallScreen {...smallScreenProps} />;
  }

  throw new Error("Invalid state");
};

export default UsersInvitePage;
