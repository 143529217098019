import { Button, PhoneNumberField, GdprConsentField, CenteredPage } from "@webapps/shared/components";
import { Country } from "@webapps/shared/operations";
import { Form, Formik } from "formik";
import { useInputFocus } from "hooks";
import type { CountryCode } from "libphonenumber-js";
import { useLibPhoneNumber } from "libs";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import { ChangeEvent, ComponentProps, FunctionComponent, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CountryFormObject, getCallingCodeFromCountryCode, required } from "utils";

import PhoneIcon from "../../images/icons/ri/phone-home.svg?react";

const INITIAL_VALUES = {
  countryCode: "FR" as Country,
  phoneNumber: "",
};

interface FormValues {
  countryCode: Country;
  gdprAccepted?: boolean;
  phoneNumber: string;
}

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "navLeft"> {
  actionLabel?: string | undefined;
  actionNext?: boolean | undefined;
  children?: never;
  countries: CountryFormObject[];
  error?: string | undefined;
  gdprCheck?: boolean;
  isSignInPage?: boolean;
  onSubmit: (phoneNumber: string) => Promise<void>;
  resetError?: () => void;
  subTitle?: string | undefined;
  title?: string | undefined;
  trackPage?: () => void;
}

const PhoneNumberScreen: FunctionComponent<Props> = ({
  actionLabel,
  actionNext = false,
  countries,
  error,
  navLeft,
  onSubmit,
  subTitle,
  gdprCheck = false,
  title,
  resetError,
  isSignInPage,
  trackPage,
}) => {
  const { t } = useTranslation();
  const ensureLibPhoneNumber = useLibPhoneNumber();

  const focusInputRef = useInputFocus();

  const initialValues = gdprCheck ? { ...INITIAL_VALUES, gdprAccepted: false } : INITIAL_VALUES;

  const submit = async (values: FormValues) => {
    const { parsePhoneNumber } = await ensureLibPhoneNumber();

    const phonelib = parsePhoneNumber(values.phoneNumber, values.countryCode as CountryCode);
    await onSubmit(phonelib.formatInternational());
  };

  useEffect(() => {
    trackPage?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      {({ errors, handleSubmit, isSubmitting, isValidating, touched, values, setFieldValue, setFieldTouched }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;

        const selectedCallingCode = getCallingCodeFromCountryCode(values.countryCode, countries);

        const handleClear = () => {
          setFieldValue("phoneNumber", "");
          resetError?.();
        };

        const handleChangeNumber = async (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;

          if (value && error && resetError) {
            resetError();
          }

          if (!values.countryCode) {
            setFieldValue("phoneNumber", value);
          }

          const { AsYouType } = await ensureLibPhoneNumber();

          const asYouType = new AsYouType(values.countryCode as CountryCode);
          const newPhoneNumber = asYouType.input(value);
          setFieldValue("phoneNumber", newPhoneNumber);
        };

        const bottom = (
          <>
            {(hasError || !!error) && (
              <div className="text-base text-red-600">{hasError ? t("form.fix", { ns: "common" }) : error}</div>
            )}

            <Button
              className="w-full"
              disabled={isValidating || isSubmitting || hasError || !values.phoneNumber}
              label={actionLabel ?? t("phoneNumberScreen.cta")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              next={actionNext}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <CenteredPage
            bottom={bottom}
            navLeft={navLeft}
            subTitle={subTitle ?? t("phoneNumberScreen.subtitle")}
            title={title ?? t("phoneNumberScreen.pageTitle")}
          >
            <Form className="mt-2 grid grid-cols-1 gap-8">
              <PhoneNumberField
                name="phoneNumber"
                prefixName="countryCode"
                label={t("registerPage.phoneNumberScreen.phoneNumberLabel")}
                validate={required}
                inputProps={{
                  autoComplete: "tel",
                  icon: PhoneIcon,
                  inputMode: "tel",
                  // Formik bug, newValues are sometimes replaced by old ones
                  // https://github.com/jaredpalmer/formik/issues/2083
                  onBlur: () => setFieldTouched,
                  onChange: handleChangeNumber,
                  placeholder: "06 11 12 13 14",
                  type: "tel",
                }}
                items={countries}
                selectProps={{ autoComplete: "tel-country-code" }}
                ref={focusInputRef}
                onClear={handleClear}
                callingCode={selectedCallingCode}
              />

              {gdprCheck && <GdprConsentField />}
            </Form>
            {isSignInPage && (
              <p className="mt-6 text-sm text-neutral-600">
                <Trans
                  i18nKey="phoneNumberScreen.register"
                  components={{
                    1: <Link className="underline" to="/register?account_type=independant" />,
                    2: <Link className="underline" to="/register?account_type=fleet" />,
                  }}
                />
              </p>
            )}
          </CenteredPage>
        );
      }}
    </Formik>
  );
};

export default PhoneNumberScreen;
