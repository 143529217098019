import { BUSINESS_TOKEN_STORAGE_KEY } from "constants/storage";

import { useLocalStorage } from "hooks";
import join from "lodash/join";
import { useSignOutPageMutation } from "operations";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import useSignOut from "../../hooks/useSignOut";

import Screen from "./screens/Screen";

const SignOutPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [sessionToken] = useLocalStorage(BUSINESS_TOKEN_STORAGE_KEY);

  const [signOutError, setSignOutError] = useState<string>();
  const [signOutMutation, { loading: signOutLoading }] = useSignOutPageMutation({
    onCompleted: ({ signOut: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setSignOutError(join(result.errors, " "));
      }

      if (result?.signedOut) {
        signOut();
        navigate("/sign-in", { replace: true });
        return;
      }

      setSignOutError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setSignOutError(error.message);
    },
  });

  const signOutAndRedirect = useCallback(async () => {
    if (!sessionToken) {
      signOut();
      navigate("/", { replace: true });
      return;
    }

    await signOutMutation({
      variables: {
        input: {
          sessionToken,
        },
      },
    });
  }, [navigate, signOutMutation, sessionToken, signOut]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  /*
   * Props
   */
  const navLeft = useMemo(() => {
    return <ResponsiveBackAction onClick={goBack} />;
  }, [goBack]);

  const screenProps = useMemo(() => {
    return {
      navLeft,
      onSignOutClick: signOutAndRedirect,
      signOutError,
      signOutLoading,
    };
  }, [navLeft, signOutAndRedirect, signOutLoading, signOutError]);

  //
  // Rendering
  //

  if (screenProps) {
    return <Screen {...screenProps} />;
  }

  throw new Error("Invalid state");
};

export default SignOutPage;
