import { FunctionComponent, ReactNode, useState } from "react";
import { noop } from "utils";

import ListItemRadioButtonField, {
  ListItemRadioButtonFieldProps,
} from "../../molecules/FormFields/ListItemRadioButtonField";
import ListItem, { ListItemProps } from "../../molecules/ListItem";

interface Props {
  baseKey?: string;
  listItems: Array<ListItemProps | ListItemRadioButtonFieldProps>;
  sectionHeading?: string;
  sectionTrailing?: ReactNode;
  size?: "large" | "small" | "heading";
  type?: "default" | "radio" | "checkbox";
}

const List: FunctionComponent<Props> = ({
  baseKey,
  listItems,
  sectionHeading,
  size = "large",
  type = "default",
  sectionTrailing,
}) => {
  const [selectedListItemId, setSelectedListItemId] = useState<number | undefined>(undefined);

  const computeHideBorder = (index: number) => selectedListItemId === index + 1;

  const prependSectionHeading = (items: Array<ListItemProps | ListItemRadioButtonFieldProps>) =>
    sectionHeading
      ? [
          {
            hideBorder: true,
            label: sectionHeading,
            onClick: undefined,
            selected: false,
            size: "heading" as const,
            trailing: sectionTrailing,
          },
          ...items,
        ]
      : items;

  const computePosition = (index: number, totalItems: number) => {
    if (totalItems === 1) return "standalone";
    if (index === 0) return "first";
    if (index === totalItems - 1) return "last";
    return "middle";
  };

  const renderListItems = (items: Array<ListItemProps>) => {
    return items.map((item, index) => {
      const position = computePosition(index, items.length);
      const hideBorder = computeHideBorder(index);
      const onItemClick =
        !item.href && !item.disabled && item.onClick
          ? () => {
              setSelectedListItemId(index);
              item.onClick && item.onClick();
            }
          : undefined;

      return (
        <ListItem
          hideBorder={hideBorder}
          key={baseKey ? `${baseKey}_${index}` : `item_${index}`}
          position={position}
          size={size}
          selected={selectedListItemId === index}
          {...item}
          onClick={onItemClick}
        />
      );
    });
  };
  const updatedListItems = prependSectionHeading(listItems);

  const renderRadioButtonListItems = (items: Array<ListItemRadioButtonFieldProps>) => {
    return items.map((item, index) => {
      const position = computePosition(index, items.length);
      const hideBorder = computeHideBorder(index);

      return (
        <ListItemRadioButtonField
          key={baseKey ? `${baseKey}_${index}` : `item_${index}`}
          name={item.name}
          label={item.label}
          value={item.value}
          onClick={noop}
          listItemProps={{
            hideBorder,
            position,
            size: "small",
          }}
        />
      );
    });
  };

  return (
    <div className="w-full overflow-hidden bg-white">
      {type === "radio"
        ? renderRadioButtonListItems(listItems as ListItemRadioButtonFieldProps[])
        : renderListItems(updatedListItems)}
    </div>
  );
};

export default List;
