import { useApolloClient } from "@apollo/client";
import { AccountFragmentDoc } from "@webapps/shared/operations";

import useSignedIn from "./useSignedIn";
import { useUser } from "./useUser";

export const useCurrentAccount = () => {
  const { currentAccountId } = useUser();
  const signedIn = useSignedIn();
  const client = useApolloClient();
  if (!signedIn) return undefined;
  const account = client.readFragment({
    fragment: AccountFragmentDoc,
    fragmentName: "AccountFragment",
    id: `Account:${currentAccountId}`,
  });
  return account;
};
