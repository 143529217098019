import { BUSINESS_PERSIST_STORAGE_KEY } from "@webapps/shared/constants/storage";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type User = {
  currentAccountId: string;
  currentAccountUserId: string;
  userId: string;
};

interface UserState {
  currentAccountId: string | undefined;
  currentAccountUserId: string | undefined;
  resetUser: () => void;
  setUser(user: User): void;
  userId: string | undefined;
}

export const useUser = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        currentAccountId: undefined,
        currentAccountUserId: undefined,
        resetUser: () =>
          set(() => ({ currentAccountId: undefined, currentAccountUserId: undefined, userId: undefined })),
        setUser: ({ currentAccountId, currentAccountUserId, userId }) =>
          set(() => ({
            currentAccountId,
            currentAccountUserId,
            userId,
          })),
        userId: undefined,
      }),
      {
        name: BUSINESS_PERSIST_STORAGE_KEY,
      }
    )
  )
);
