import { useCallback, useEffect, useState } from "react";

import {
  getGeolocationStateAtStart,
  GeolocationState,
  onGeolocationAuthorizationChange,
  isGeolocationPossible,
  fireGeolocationStateChange,
} from "../services/geolocation";

interface GeolocationResult {
  geolocationState: GeolocationState;
  watchGeolocationPosition: () => void;
}
/**
 * Hook used to get the current geolocation state.
 * Watch the position change if you give "onWatchPositionChange" callback.
 * Possible geolocation state:
 *    "granted": user allowed geolocation
 *    "prompt": user did not allow/disallow yet geolocation
 *    "denied": user disallowed geolocation
 *    "unknown": we can not determinate if user allowed/disallowed geolocation
 * @param onWatchPositionChange
 * @returns GeolocationResult
 */
const useGeolocation = (onWatchPositionChange?: (position: GeolocationPosition) => void): GeolocationResult => {
  const [geolocationState, setGeolocationState] = useState<GeolocationState>(getGeolocationStateAtStart());

  const onWatchPositionSuccess = useCallback(
    (position: GeolocationPosition) => {
      if (onWatchPositionChange) {
        onWatchPositionChange(position);
      }
      fireGeolocationStateChange(GeolocationState.Granted);
    },
    [onWatchPositionChange]
  );

  const onWatchPositionError = useCallback((positionError: GeolocationPositionError) => {
    const { code } = positionError;
    if (code === positionError.PERMISSION_DENIED) {
      fireGeolocationStateChange(GeolocationState.Denied);
    }
  }, []);

  const watchGeolocationPosition = useCallback((): number => {
    return navigator.geolocation.watchPosition(onWatchPositionSuccess, onWatchPositionError);
  }, [onWatchPositionSuccess, onWatchPositionError]);

  useEffect(() => {
    return onGeolocationAuthorizationChange((newGeolocationState) => setGeolocationState(newGeolocationState));
  }, []);

  useEffect(() => {
    if (!onWatchPositionChange || !isGeolocationPossible()) return;

    let watcherId = -1;
    if (geolocationState === GeolocationState.Granted) {
      watcherId = watchGeolocationPosition();
    }

    return () => {
      if (watcherId !== -1) {
        navigator.geolocation.clearWatch(watcherId);
      }
    };
  }, [geolocationState, onWatchPositionChange, watchGeolocationPosition]);

  return {
    geolocationState,
    watchGeolocationPosition,
  };
};

export default useGeolocation;
