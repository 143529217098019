import { AccountValidationStatus, OnboardingStatusFragment, ProAccountType } from "operations";

const canUserUseApp = (onboardingStatus: OnboardingStatusFragment) => {
  const { status, proType } = onboardingStatus;

  if (
    (proType === ProAccountType.Smb || proType === ProAccountType.Ka) &&
    (status === AccountValidationStatus.Validated ||
      status === AccountValidationStatus.NotRequired ||
      status === AccountValidationStatus.PendingManualValidation)
  ) {
    return true;
  }

  return false;
};

export default canUserUseApp;
