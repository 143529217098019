import { useUser } from "@webapps/apps/business/src/hooks/useUser";
import { BottomSheet, ListItemRadioButtonField } from "components";
import { Formik } from "formik";
import { AccountSelectorBottomSheet_AccountFragment } from "operations";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import SuitcaseIcon from "../../../../images/icons/ri/suitcase-fill.svg?react";
import UserIcon from "../../../../images/icons/ri/user-line.svg?react";

interface FormValues {
  accountId: string;
}

interface Props {
  accounts: AccountSelectorBottomSheet_AccountFragment[];
  onClose: () => void;
  onConfirm: (accountId: string) => Promise<boolean>;
  subTitle?: string | undefined;
  title?: string | undefined;
}

const Sheet: FunctionComponent<Props> = ({ accounts, onClose, onConfirm, subTitle, title }) => {
  const { t } = useTranslation();
  const { currentAccountId } = useUser();
  const submit = useCallback(
    async (values: FormValues) => {
      const result = await onConfirm(values.accountId);
      if (result) {
        onClose();
      }
    },
    [onClose, onConfirm]
  );
  if (!currentAccountId) {
    return null;
  }
  return (
    <Formik
      initialValues={{
        accountId: currentAccountId,
      }}
      onSubmit={submit}
    >
      {({ handleSubmit, isSubmitting, isValidating, values }) => {
        return (
          <BottomSheet
            onClose={onClose}
            title={title ?? t("accountSelectorBottomSheet.title")}
            subTitle={subTitle}
            rawContent
          >
            <div className="py-4-safe">
              {accounts.map(({ id, label, pro }) => {
                const loading = (isSubmitting || isValidating) && id === values.accountId;
                return (
                  <ListItemRadioButtonField
                    key={`account_${id}`}
                    name="accountId"
                    label={label}
                    loading={loading}
                    onClick={handleSubmit}
                    value={id}
                    listItemProps={{
                      leadingIcon: pro ? SuitcaseIcon : UserIcon,
                      position: "none",
                    }}
                  />
                );
              })}
            </div>
          </BottomSheet>
        );
      }}
    </Formik>
  );
};

export default Sheet;
