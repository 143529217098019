import { Button, InfoModal, InputField, CgvConsentField, CenteredPage } from "@webapps/shared/components";
import ClockIcon from "@webapps/shared/images/icons/ri/clock.svg?react";
import { Form, Formik } from "formik";
import { useInputFocus, useMixpanel } from "hooks";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import { ComponentProps, FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "utils";

import CgvConsentBottomSheet from "../../../components/organisms/BottomSheets/CgvConsentBottomSheet";

interface FormValues {
  cgvAccepted: boolean;
  email: string;
}

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "subTitle"> {
  children?: never;
  closeModal: () => void;
  error?: string | ReactElement | undefined;
  isModalOpen: boolean;
  onSubmit: (email: string) => Promise<void>;
  trackPage: () => void;
}

const InvoiceInfoScreen: FunctionComponent<Props> = ({
  error,
  onSubmit,
  subTitle,
  closeModal,
  isModalOpen,
  trackPage,
}) => {
  const { t } = useTranslation();
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const mixpanel = useMixpanel((state) => state.mixpanel);

  const focusInputRef = useInputFocus();

  const submit = async (values: FormValues) => {
    await onSubmit(values.email);
  };

  useEffect(() => {
    if (!mixpanel) return;

    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  return (
    <Formik
      initialValues={{
        cgvAccepted: false,
        email: "",
      }}
      onSubmit={submit}
    >
      {({ errors, handleSubmit, isSubmitting, isValidating, touched, setFieldValue, values }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;

        const bottom = (
          <>
            {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

            {error && !hasError ? <div className="text-base text-red-600">{error}</div> : null}

            <Button
              className="w-full md:max-w-xs md:self-center"
              disabled={isValidating || isSubmitting || hasError || !values.cgvAccepted}
              label={t("registerPage.invoiceInfoScreen.cta")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        const onCloseBottomSheet = () => {
          setBottomSheetVisible(false);
          setFieldValue("cgvAccepted", true);
        };

        return (
          <>
            <InfoModal
              closeModal={closeModal}
              isOpen={isModalOpen}
              showCloseButton={false}
              buttonClassName="mt-2"
              icon={ClockIcon}
              title={t("registerPage.infoModal.title")}
              subtitle={t("registerPage.infoModal.subtitle")}
              cta={t("registerPage.infoModal.cta")}
            />
            <CenteredPage
              bottom={bottom}
              subTitle={subTitle}
              title={t("registerPage.invoiceInfoScreen.pageTitle")}
              bottomSheet={bottomSheetVisible ? <CgvConsentBottomSheet onClose={onCloseBottomSheet} /> : null}
            >
              <p className="text-base font-light text-neutral-700">{t("registerPage.invoiceInfoScreen.description")}</p>
              <Form className="mt-4 flex flex-col gap-6">
                <div>
                  <InputField
                    name="email"
                    label={t("registerPage.invoiceInfoScreen.email.label")}
                    validate={validateEmail}
                    ref={focusInputRef}
                  />
                  <span className="text-sm text-neutral-600">{t("registerPage.invoiceInfoScreen.email.hint")}</span>
                </div>
                <CgvConsentField onClick={() => setBottomSheetVisible(true)} />
              </Form>
            </CenteredPage>
          </>
        );
      }}
    </Formik>
  );
};

export default InvoiceInfoScreen;
