import { useMediaQuery } from "@react-hook/media-query";
import { Banner, Button, CenteredPage } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import classNames from "classnames";
import { BackAction, CheckedList, StepItem } from "components";
import ChevronRightIcon from "images/icons/ri/chevron-right.svg?react";
import { ProAccountLabelType, ProAccountType } from "operations";
import { ComponentProps, FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation, Trans } from "react-i18next";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import { useProType } from "../../../hooks/useProType";
import { useSteps, useNoProTypeInfos } from "../hooks";

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "navRight" | "title"> {
  children?: never;
  handleClick: () => void;
  trackPage: () => void;
}

const OnboardingScreen: FunctionComponent<Props> = ({ handleClick, trackPage }) => {
  const { t } = useTranslation();
  const { proType, proTypeLabel } = useProType();
  const [searchParams, setSearchParams] = useSearchParams();
  const goBackToWebsite = searchParams.get("website");
  const steps = useSteps(proTypeLabel);
  const noProTypeInfos = useNoProTypeInfos();
  const mixpanel = useMixpanel((state) => state.mixpanel);
  const navigate = useNavigate();
  const minHeightScreen = useMediaQuery("only screen and (min-height: 740px)");

  let title: React.ReactElement | undefined = undefined;

  const handleRedirectToOtherProType = useCallback(() => {
    const newProTypeLabel =
      proTypeLabel === ProAccountLabelType.Independant ? ProAccountLabelType.Fleet : ProAccountLabelType.Independant;
    searchParams.set("account_type", newProTypeLabel);
    setSearchParams(searchParams);
  }, [proTypeLabel, searchParams, setSearchParams]);

  const navRight = useMemo(() => {
    if (!proTypeLabel) return undefined;

    return (
      <Button
        className="hidden md:flex"
        label={t(`registerPage.onboardingScreen.${proTypeLabel}.otherType`)}
        kind="secondary"
        size="small"
        onClick={handleRedirectToOtherProType}
        trailingIcon={ChevronRightIcon}
      />
    );
  }, [handleRedirectToOtherProType, proTypeLabel, t]);

  const navLeft = goBackToWebsite ? (
    <BackAction href={t("registerPage.onboardingScreen.back.href")} className="lg:hidden" target="_blank" />
  ) : undefined;

  useEffect(() => {
    if (!mixpanel) return;

    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel, proType]);

  if (!proType) {
    title = (
      <Trans
        i18nKey={`registerPage.onboardingScreen.noCompanyType.title`}
        components={{
          1: <span />,
          2: <span />,
        }}
      />
    );
    return (
      <CenteredPage title={title} navLeft={navLeft} size="large" navRight={navRight}>
        <div className="flex flex-col gap-4 sm:gap-6">
          {noProTypeInfos.reverse().map((noProTypeInfo, index) => {
            const registerChoicesContent = (
              <div className="flex flex-col items-start gap-4 sm:relative">
                <span className="text-xl font-light text-neutral-700">{noProTypeInfo.title}</span>

                <CheckedList list={noProTypeInfo.perks} />

                <Button
                  className={classNames("w-full sm:w-[200px]", {
                    "sm:absolute sm:bottom-0 sm:right-0": !minHeightScreen,
                  })}
                  fullWidth={false}
                  label={noProTypeInfo.callToAction}
                  onClick={() => {
                    navigate(`/register?account_type=${noProTypeInfo.proTypeLabel}`);
                  }}
                  size="medium"
                  type="button"
                  kind="primary"
                />

                <div
                  className={classNames("h-[1px] w-full bg-neutral-100 sm:hidden", {
                    hidden: index === noProTypeInfos.length - 1,
                  })}
                />
              </div>
            );

            return (
              <div key={`${noProTypeInfo.proTypeLabel}-${index}`}>
                <Banner
                  className="hidden flex-col gap-4 px-6 py-4 sm:flex"
                  bgColor="bg-neutral-50"
                  borderRadius="medium"
                >
                  {registerChoicesContent}
                </Banner>
                <div className="flex flex-col gap-4 sm:hidden">{registerChoicesContent}</div>
              </div>
            );
          })}
        </div>
      </CenteredPage>
    );
  }

  title = (
    <Trans
      i18nKey={`registerPage.onboardingScreen.${proTypeLabel}.title`}
      components={{
        1: <span />,
      }}
    />
  );

  const loginComponent =
    proType === ProAccountType.Smb ? (
      <Link to="/sign-in" className="underline" />
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a className="underline" href="https://app.go-electra.com/sign-in" />
    );

  const login =
    !isMobile && proType === ProAccountType.Independant ? (
      t("registerPage.onboardingScreen.independant.loginDesktop")
    ) : (
      <Trans
        i18nKey={`registerPage.onboardingScreen.${proTypeLabel}.login`}
        components={{
          1: loginComponent,
        }}
      />
    );

  const bottom = (
    <>
      <Button
        className="w-full md:max-w-xs md:self-center"
        label={t(`registerPage.onboardingScreen.${proTypeLabel}.cta`)}
        onClick={handleClick}
        size="large"
        type="submit"
      />
      <div className="mb-4 text-center text-sm text-neutral-600">{login}</div>
    </>
  );

  return (
    <CenteredPage title={title} navLeft={navLeft} size="large" navRight={navRight} bottom={bottom}>
      <div className="flex flex-col gap-6">
        <Banner className="mt-5 px-6" bgColor="bg-neutral-50" borderRadius="normal">
          <div className="flex flex-col gap-4">
            {steps.map((step, index) => (
              <StepItem {...step} index={index + 1} key={`step-${index}`} />
            ))}
          </div>
        </Banner>
      </div>
    </CenteredPage>
  );
};

export default OnboardingScreen;
