import classNames from "classnames";
import ArrowDownIcon from "images/icons/ri/chevron-down.svg?react";
import { AccountSelector_AccountFragment } from "operations";
import { FunctionComponent, useCallback, useState } from "react";
import { isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";

interface Props {
  account: AccountSelector_AccountFragment;
  children?: never;
  className?: string;
  hasCars?: boolean;
  onClick: () => void;
}

const AccountSelector: FunctionComponent<Props> = ({ account, className, onClick }) => {
  const { t } = useTranslation();
  const [touching, setTouching] = useState(false);

  const { label } = account;

  const onTouchStart = useCallback(() => setTouching(true), []);
  const onTouchEnd = useCallback(() => setTouching(false), []);

  return (
    <div
      className={classNames(
        "flex cursor-pointer flex-col rounded-sm border border-white border-opacity-10 bg-white bg-opacity-20 p-4 transition-all",
        className,
        {
          "hover:opacity-80": !isIOS || (isIOS && touching),
        }
      )}
      onClick={onClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <div className="text-base font-bold text-white">{t("accountSelector.account")}</div>

      <div className="flex items-center gap-2 text-white">
        <div className="flex-1 text-sm font-normal">{label}</div>
        <ArrowDownIcon className="h-6 w-6 flex-none" />
      </div>
    </div>
  );
};

export default AccountSelector;
