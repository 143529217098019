import classNames from "classnames";
import { FunctionComponent, ReactNode, SVGProps } from "react";

import InfoIcon from "../../../images/icons/ri/information.svg?react";
import Alert from "../Alert";
import Button from "../Button";

export type ActionModalProps = {
  buttonClassName?: string;
  cancelCta: string;
  children?: ReactNode;
  cta: string;
  disabled?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  image?: string;
  isOpen: boolean;
  loading?: boolean;
  onCancel: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
  showCloseButton?: boolean;
  subtitle?: string;
  title: string;
};

const ActionModal: FunctionComponent<ActionModalProps> = ({
  buttonClassName,
  title,
  subtitle,
  cta,
  isOpen,
  onCancel,
  onConfirm,
  cancelCta,
  showCloseButton = true,
  icon: IconComponent,
  image,
  onClose,
  children,
  loading = false,
  disabled = false,
}) => {
  return isOpen ? (
    <Alert showCloseButton={showCloseButton} onClose={onClose} centered>
      <div className="flex flex-col gap-4 px-1">
        <div
          className={classNames("self-center", {
            "rounded-full bg-neutral-50 p-2": !image,
            "w-[120px]": image,
          })}
        >
          {image && <img src={image} alt={title} />}
          {IconComponent && <IconComponent className="h-6 w-6 flex-none text-neutral-700" />}
          {!image && !IconComponent && <InfoIcon className="h-6 w-6 flex-none text-neutral-700" />}
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="text-center text-xl font-light text-neutral-900">{title}</div>
            {!!subtitle && (
              <div className="text-medium min-h-12 text-center font-normal text-neutral-600">{subtitle}</div>
            )}
          </div>
          {children}
          <div className="flex justify-end gap-2">
            <Button label={cancelCta} onClick={onCancel} size="small" type="button" kind="tertiary" loading={loading} />
            <Button
              className={classNames("", buttonClassName)}
              label={cta}
              onClick={onConfirm}
              type="button"
              kind="primary"
              size="small"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </Alert>
  ) : null;
};

export default ActionModal;
