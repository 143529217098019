import { MapStyleDataEvent, Map as Mapbox, PaddingOptions } from "mapbox-gl";
import { StationPublicStatus } from "operations";
import { ComponentProps, FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { mapAccessToken, useMapboxGl, MapboxGLComponent, LogoPosition } from "../../../lib/react-map-gl";
import PingIcon from "../../atoms/PingIcon";
import PinPoint, { PinPointProps } from "../../molecules/PinPoint";
import StationPoints, { StationPointsProps } from "../StationPoints";

import {
  DEFAULT_CONTAINER_STYLE,
  DEFAULT_FIT_BOUNDS_OPTIONS,
  DEFAULT_STYLE,
  DEFAULT_LOGO_POSITION,
  DEFAULT_MAP_ZOOM,
} from "./constants";
import { customizeMap } from "./utils";

export type { Map as Mapbox, EaseToOptions as MapboxEaseToOptions } from "mapbox-gl";

export type MapLocation = [longitude: number, latitude: number];

export type MapProps = Partial<Omit<ComponentProps<MapboxGLComponent>, "zoom">> &
  Partial<StationPointsProps> &
  Partial<PinPointProps> & {
    location?: MapLocation | undefined;
    logoPosition?: LogoPosition;
    onMapClick?: () => void;
    zoom?: number;
  };

const Map: FunctionComponent<MapProps> = ({
  style = DEFAULT_CONTAINER_STYLE,
  location,
  logoPosition = DEFAULT_LOGO_POSITION,
  mapStyle = DEFAULT_STYLE,
  locations,
  onMarkerClick,
  onMapClick,
  selectedId,
  hour,
  confirmed,
  selected,
  status,
  coordinates,
  key,
  isHour,
  zoom = DEFAULT_MAP_ZOOM,
  ...props
}) => {
  const { t } = useTranslation();
  const imports = useMapboxGl();

  const pinPointProps = isHour
    ? { confirmed: !!confirmed, hour, isHour }
    : { selected, status: status ?? StationPublicStatus.Unknown };

  const handleStyleLoad = useCallback((event: MapStyleDataEvent) => {
    const map = event.target as Mapbox;
    const paddingOptions: PaddingOptions = DEFAULT_FIT_BOUNDS_OPTIONS.padding as PaddingOptions;
    customizeMap(map, paddingOptions);
  }, []);

  if (!imports.loaded) {
    return (
      <div className="flex items-center justify-center gap-2 text-neutral-600" style={style}>
        {t("map.loading")}
      </div>
    );
  }

  const { MapboxGL, Marker } = imports;

  if (!MapboxGL || !Marker) {
    return (
      <div className="flex items-center justify-center bg-red-200 text-red-500" style={style}>
        {t("map.notInitialized")}
      </div>
    );
  }

  return (
    <MapboxGL
      attributionControl={false}
      initialViewState={{
        fitBoundsOptions: DEFAULT_FIT_BOUNDS_OPTIONS,
        zoom,
      }}
      logoPosition={logoPosition}
      mapboxAccessToken={mapAccessToken}
      minZoom={4}
      maxZoom={16}
      pitchWithRotate={false}
      onClick={onMapClick}
      style={style}
      onStyleData={handleStyleLoad}
      mapStyle={mapStyle}
      {...props}
    >
      <>
        {location && (
          <Marker key="location" latitude={location[1]} longitude={location[0]} anchor="center" style={{ zIndex: 2 }}>
            <PingIcon />
          </Marker>
        )}

        {!!locations?.length && locations?.length > 0 && (
          <StationPoints
            locations={locations}
            selectedId={selectedId}
            onMarkerClick={(id) => onMarkerClick?.(id)}
            Marker={Marker}
          />
        )}
        {coordinates && <PinPoint coordinates={coordinates} key={key as string} Marker={Marker} {...pinPointProps} />}
      </>
    </MapboxGL>
  );
};
export default Map;
