import { FunctionComponent } from "react";

interface Props {
  value: number;
}

const StepNumber: FunctionComponent<Props> = ({ value }) => (
  <div className="text-medium flex h-10 w-10 items-center justify-center rounded-full bg-neutral-700 font-normal text-white opacity-90">
    {value.toString()}
  </div>
);

export default StepNumber;
