import { Button } from "@webapps/shared/components";
import AddIcon from "images/icons/ri/add.svg?react";
import BinocularIcon from "images/icons/ri/binoculars.svg?react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const EmptyCharges = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="px-4-safe md:px-8-safe py-4-safe flex h-full flex-col items-center justify-center gap-7">
      <BinocularIcon className="w-20" />
      <div className="text-base font-light">{t("chargesPage.empty.title")}</div>
      <Button
        size="small"
        label={t("chargesPage.empty.cta")}
        trailingIcon={AddIcon}
        onClick={() => navigate("/users/invite")}
      />
    </div>
  );
};

export default EmptyCharges;
